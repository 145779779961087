import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap'
import Cookies from 'universal-cookie';
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Card from "./Card";
import img1 from "../../Assets/images/where/img1.png"
import img2 from "../../Assets/images/where/img2.png"
import img3 from "../../Assets/images/where/img3.png"
import img16 from "../../Assets/images/where/img16.png"
import img14 from "../../Assets/images/where/img14.png"
import { Link } from "react-router-dom";
import { getwishlist, getwishlistarray, addwishlist } from '../../Hooks/filterHook';
import { getUserDatas } from "../../Hooks/useUserHook";

const cookies = new Cookies();

const Wishlist = () => {
    const [wishlist, setWishlist] = useState([]);
    const [userDatas, setUserDatas] = useState({});
    const [wishedlist, setWishedlist] = useState([]);
    useEffect(() => {
        async function fetchdata() {
            let email = await cookies.get('email');
            let wishlist = await getwishlist(email);
            console.log("wishlist return data", wishlist.data.record);

            setUserDatas(getUserDatas());
            setWishlist(wishlist.data.record);
            // setWishedlist(wishlist.data.record[0].wishList);
            // console.log("card data",randomdata);
        }
        fetchdata();
    }, [])

    const wishlistarray = async (id, mail) => {
        
            let payload = {
                roomid: id,
                emailid: mail
            }
            await addwishlist(payload)
                .then(async (data) => {
                    console.log("wishlist r eturn: ", data);
                    cookies.set('User-Datas',data.data.record, { path: '/'},{expires: new Date(Date.now()+600000) })

                    let wishlist = await getwishlist(mail);
                    console.log("wishlist return data", wishlist.data.record);

                    setUserDatas(getUserDatas());
                    setWishlist(wishlist.data.record);
                    // const wishlistarray = await getwishlistarray(mail);
                    
                    // setWishedlist(wishlistarray);

                })
                .catch((e) => console.log("wishlist return error", e))
       

    }
   
    return (
        <>
            <Header />

            <div className="wishlist detail_ful mh-100vh-header">
                <div className="container container_custom">
                    <h3 className="mb-2">Wishlists</h3>
                    <div className="row justify-content-md-between justify-content-center">
                        {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-7 mb-4"> */}
                        {/* <Link to="/expdetail" className="nounder text-start text-dark"> */}
                        {/* <div className="wishlistcard"> */}
                        <div className=''>
                            <div className='d-grid card-5'>
                                {

                                    wishlist.map((i) => 
                                   {
                                    if(!i.isDeactivate  && i.isListed && !i.isRejected){
                                        return(<Card data={i} userData={userDatas} wishlistarray={wishlistarray} />);
                                    }
                                    })
                                }
                                {/* <div className="imgs"> */}
                                {/* <img src={img1} className="img-1"/>
                                <div><img src={img2} className="img-2" />
                                    <img src={img3} className="img-3"/>
                                    </div>  */}
                                {/* </div> */}
                                {/* <h5 className="mt-3">Wishlist 1</h5> */}

                            </div>
                            {/* </Link> */}
                            {/* </div> */}
                        </div>
                        {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-7 mb-4">
                        <Link to="/expdetail" className="nounder text-start text-dark">
                            <div className="wishlistcard">
                                <div className="imgs">
                                    <img src={img2} className="img-1"/>
                                <div><img src={img1} className="img-2" />
                                    <img src={img3} className="img-3"/>
                                    </div> 
                                </div>
                                <h5 className="mt-3">Wishlist 2</h5>
                            </div>
                        </Link>
                    </div> */}
                        {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-7 mb-4">
                        <Link to="/expdetail" className="nounder text-start text-dark">
                            <div className="wishlistcard">
                                <div className="imgs">
                                    <img src={img3} className="img-1"/>
                                <div><img src={img2} className="img-2" />
                                    <img src={img1} className="img-3"/>
                                    </div> 
                                </div>
                                <h5 className="mt-3">Wishlist 3</h5>
                            </div>
                        </Link>
                    </div> */}
                        {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-7 mb-4">
                        <Link to="/expdetail" className="nounder text-start text-dark">
                            <div className="wishlistcard">
                                <div className="imgs">
                                    <img src={img16} className="img-1"/>
                                <div><img src={img2} className="img-2" />
                                    <img src={img3} className="img-3"/>
                                    </div> 
                                </div>
                                <h5 className="mt-3">Wishlist 4</h5>
                            </div>
                        </Link>
                    </div> */}
                        {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-7 mb-4">
                        <Link to="/expdetail" className="nounder text-start text-dark">
                            <div className="wishlistcard">
                                <div className="imgs">
                                    <img src={img14} className="img-1"/>
                                    <div>
                                        <img src={img2} className="img-2" />
                                        <img src={img3} className="img-3"/>
                                    </div> 
                                </div>
                                <h5 className="mt-3">Wishlist 5</h5>
                            </div>
                        </Link>
                    </div> */}

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )

    // return(
    //     <div>
    //         <Header  />
    //             <div className='container container_custom'>
    //                 <div className='d-grid card-5'>
    //                { 
    //                     wishlist.map((i)=>  <Card data = {i}/>)
    //                 } 
    //                 </div>
    //             </div>
    //         <Footer />

    //     </div>
    //     )

}

export default Wishlist