import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import Header from "../Components/Common/Header.js";
import Cookies from "universal-cookie";

// import profimg from "../Assets/images/landingPage/profilenew.png";
import profimg from "../../src/Assets/images/profilenew.png";
import upload from "../../src/Assets/images/upload.png";
import place1 from "../Assets/images/place1.webp";
import AddLang from "./Common/Modals/AddLang.js";
import Footer from "./Common/Footer.js";
import { AiOutlineCheckSquare } from "react-icons/ai";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import user from "../../../../../Assets/images/newLogo.png";
import user from "../Assets/images/newLogo.png";
// import eye from "../Assets/images/visibleNew.png";
import editFill from "../Assets/images/edit-fill.png";
import languages from "../config/languages.json";
import { AiFillEdit } from "react-icons/ai";
import {
  getUserDatas,
  getUserEmail,
  updateUserProfile,
  usersdata,
} from "../Hooks/useUserHook.js";
import { getUserDetails } from "../Hooks/useUserHostingHook.js";
import { API_URL } from "../config/env.js";
import { getUserHostings } from "../Hooks/useUserHostingHook.js";

const Profile = () => {
  const [language, setLanguage] = useState(false);
  const [choosen, setChoosen] = useState([]);
  console.log("choosen", choosen);
  const [editprof, setEditProf] = useState(false);
  console.log("editprof", editprof);
  const [editprofimg, setEditProfimg] = useState(user);
  const [imgUpd, setImgUpd] = useState(false);
  console.log("tureeeeeeeeeeeee", imgUpd);
  const [bio, setBio] = useState("");
  console.log("biodatataatata", bio);
  const [address, setAddress] = useState("");
  const [work, setWork] = useState("");
  console.log("workdatasssssssssss", work);
  const [selectedFile, setSelectedFile] = useState({});
  console.log("selectedFiles", selectedFile);
  const [userData, setUserData] = useState({});
  console.log("userDatasfdasdfasd", userData);
  const [getbiodata, setBiodata] = useState("");
  console.log("getbiodatasgal", getbiodata);
  console.log("userDatassss----------------------", userData, typeof userData);
  const [userHostings, setUserHostings] = useState([]);
  const [lang, setLang] = useState();
  const [profilePicturePath, setprofilePicturePath] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [useremail , setUseremail] = useState(getUserEmail());
  const cookies = new Cookies();
  const [viewimg , setViewimg] = useState("");
  console.log("errrorMessage", errorMessage);

  const choosenlang = (data, ind) => {
    console.log("dadta,in dex", data, choosen, data.length);
    var mergedata = [];
    console.log("mergedata", mergedata.length - 1);
    if (data) {
      // console.log("choosn..length", choosen, choosen.length, choosen.length > 0)
      if(data){
        let er = errors;
        er.choosen = "";
        setErrors(er);
      }
      if (choosen && choosen.length > 0) {
        choosen.map((item, index) => {
          console.log("data,item", index, data, item, data.length - 1);
          if (!mergedata.includes(item)) {
            mergedata.push(item);
          }
        });
        data.map((item, index) => {
          console.log("data,item", index, data, item, data.length - 1);
          if (!mergedata.includes(item)) {
            mergedata.push(item);
          }
        });

        setChoosen(mergedata);
        console.log("elseeee" , mergedata);
      } else {
        console.log("elseeeeeeee", data);
        setChoosen(data);
      }
    } else {
      setChoosen(data);
    }
  };

  const removechoose = (e) => {
    let val = e?.target?.previousSibling?.parentNode?.children[0]?.innerHTML;
    console.log("valuesss", val);
    setChoosen(choosen.filter((a) => a != val));
  };
  
  const getimage = (e) => {
    //  console.log(e.target.files[0])
    //     setEditProfimg(e.target.files[0].name)
    const files = e.target.files[0];
    console.log("filesdetails", files);
    setSelectedFile(files);
    if (files) {
      setViewimg(window.URL.createObjectURL(e.target.files[0]));
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.addEventListener("load", function() {
        console.log("this", this, "this.result", this.result);
        setEditProfimg(this.result);
        setImgUpd(true);
      });
    }
  };

  const profileValidation = async () => {
    let errors = {};
    var imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;
    console.log(
      "workdddddddddddddd",
      work,
      work == "" || work == "undefined" || work == undefined
    );

    if (bio == "" || bio == "undefined" || bio == undefined) {
      errors.bio = "About field is required";
    }

    if (address == "" || address == "undefined" || address == undefined) {
      errors.address = "Location field is required";
    }
    console.log("asdfbjasfsd", choosen);
    if (
      (choosen && choosen.length == 0) ||
      choosen == "undefined" ||
      choosen == undefined
    ) {
      errors.choosen = "Language field is required";
    }

    if (work == "" || work == "undefined" || work == undefined) {
      errors.work = "Work field is required";
    }

    if (selectedFile && selectedFile?.name) {
      if (selectedFile && selectedFile.size > 9437184) {
        errors.image = "Too_large";
      } else if (!imageFormat.test(selectedFile.name)) {
        errors.image = "Invalid Image";
      }
    } else {
      console.log("datzaaaaaaaaa", profilePicturePath);
      if (!profilePicturePath) {
        errors.image = "Choose Image";
      }
    }
    setErrors(errors);
    return errors;
  };

  const updateProfile = async () => {
    window.scrollTo(0,0);
    let validation = await profileValidation();
    console.log("errrrrrrrrr", validation);
    let Objlen = Object.keys(validation).length;

    const data = {
      email: userData.email,
      bio: bio,
      work: work,
      address: address,
      profilePic: selectedFile,
      languagesKnown: JSON.stringify(choosen),
    };
    console.log("datasgal", data, "hihi", JSON.stringify(choosen));
    console.log("datassssssssssssss", data);

    if (Objlen == 0) {
      await updateUserProfile(data).then((result) => {
        console.log("userprofiledata", result.userdate.record);
        console.log(
          result,
          result.userdate.record.languagesKnown,
          "USER DATA UPDATE",
          "alldatssss",
          result
        );
        console.log("result.userdate.record.bio", result.userdate.record.bio);
        // cookies.set("User-Datas", result.userdate.record);
      });
      setEditProf(false);
    }

    setErrorMessage(false);
  };
  const  fetchdata = async() => {
    await getUserDetails(getUserDatas().email).then((result) => {
      console.log("result data", result[0]);
      setChoosen(result[0]?.languagesKnown);
      setWork(result[0]?.work);
      setBio(result[0]?.bio);
      setAddress(result[0]?.address);
      setprofilePicturePath(result[0]?.profilePicturePath);
      //setBiodata(userData?.bio)
    });
  }
  useEffect(() => {
    console.log("enter this", getbiodata);
    setUserData(getUserDatas());
    getUserHostings(getUserDatas().email).then((data) => {
      setUserHostings(data);
    });
    console.log(userData.email);
    usersdata(userData?.email).then((val) => {
      console.log("the value", val);
      // setChoosen(val.data.languagesKnown);
    });
   
    fetchdata();
    // currendata()
  }, []);
  const [responsive1] = React.useState({
    0: {
      items: 1,
    },
    425: {
      items: 2,
    },
    991: {
      item: 3,
    },
    1024: {
      items: 3,
    },
  });
  return (
    <div className="prof-sec">
      {language && (
        <AddLang
        chosen = {choosen}
          choose={(e) => choosenlang(e)}
          onDismiss={() => setLanguage(false)}
        />
      )}
      <Header />
      <div className="profile profpagetop mb-4">
        <div className="container container_custom">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="bordbox p-4 br-20">
                <div className="text-center mb-3">
                  <div className="position-relative">
                    <div className="d-flex justify-content-center">
                      <img
                        src={
                          // !imgUpd ? editprofimg : API_URL + profilePicturePath
                          !imgUpd ? profilePicturePath ? API_URL + profilePicturePath : editprofimg
                          :viewimg
                        }
                        className="profilemg"
                      />
{console.log("imgUpd" , imgUpd)}
                      <span className="editimg">
                        {" "}
                        {editprof &&<Button
                          variant="link"
                          className="text-dark img-upload-custom-btn"
                        >
                           <input type="file" onChange={(e) => {setImgUpd(true); getimage(e)}} />
                          <img src={upload} alt="" />
                        </Button>}
                      </span>
                    </div>
                  </div>
                  {/* {(errorMessage && !profilePicturePath) ? <p className="text-danger"> Choose any Photos</p> : <></>}*/}
                  {errors?.image ? (
                    <p className="text-danger"> {errors.image}</p>
                  ) : (
                    <p className="mt-3"> Max 9MB in jpg/jpeg/png</p>
                  )}
                </div>

                <h6 className="">Identity verification</h6>
                <p className="f-14">
                  Show others you’re really you with the identity verification
                  badge.
                </p>

                {/* <button className="btn btn-outline-secondary my-4">Get the badge</button> */}
                <hr className="themehr" />
                <div className="d-flex flex-column justify-content-between align-items-center">
                  <h5 className="account-holder-name p-0 mt-0 mx-0 mb-3">
                    {userData && userData.firstName}&nbsp;
                    {userData && userData.lastName}
                  </h5>
                  <p className="account-holder-email text-muted f-14">
                    {useremail}
                    <AiOutlineCheckSquare
                      style={{ color: "green" }}
                      className="ms-2"
                    />
                    {/* <span className="fa fa-check" /> */}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-6 offset-xl-1 col-lg-7 col-md-6 col-sm-12 mt-sm-5 mt-5 mt-md-0 mt-lg-0 mt-xl-0">
              <div className="profile-account-details pt-xl-5 pt-lg-5 pt-md-5">
                <h2>
                  Hi, I’m {userData && userData.firstName}&nbsp;
                  {userData && userData.lastName}
                  <Button
                    variant="link"
                    className={
                      editprof
                        ? "text-dark ps-2 opacity editprofbtn"
                        : "text-dark ps-2 editprofbtn"
                    }
                    onClick={() => setEditProf(true)}
                  >
                    <AiFillEdit />
                  </Button>
                </h2>

                <p className="f-14 ">
                  Joined in{" "}
                  {userData &&
                    userData.createdAt &&
                    new Date(userData.createdAt).getFullYear()}
                </p>

                <div className={editprof ? "" : "d-none"}>
                  {/* <p className="f-14 mt-4">Some info has been automatically translated. <a href="#" className="text-dark">Show original language</a></p> */}

                  <label className="mt-4">About</label>
                  <textarea
                    rows="4"
                    placeholder={userData && userData.bio && userData.bio}
                    value={bio}
                    className="form-control mb-2"
                    onChange={(e) => {
                      if(e?.target?.value){
                        let er = errors;
                        er.bio = "";
                        setErrors(er);
                      }
                      setBio(e.target.value);
                    }}
                  />
                  {/* {(errorMessage && !bio) ? <p className='text-danger'>About field is required</p> : <></>} */}
                  <p className="text-danger mb-2">{errors?.bio}</p>
                  <label className="mt-2"> Location</label>
                  {/* {userData?.address ? userData?.address?.street+" "+userData?.address?.flat+" "+userData?.address?.city+" "+userData?.address?.stateprovince+" "+userData?.address?.postcode+" "+userData?.address?.country : userData?.address} */}
                  <input
                    type="text"
                    placeholder={
                      userData && userData?.address ? userData?.address : ""
                    }
                    value={address}
                    className="form-control mb-2 input_big_ne"
                    onChange={(e) => {
                      if(e?.target?.value){
                        let er = errors;
                        er.address = "";
                        setErrors(er);
                      }
                      setAddress(e.target.value);
                    }}
                  />
                  {/* {(errorMessage && !address) ? <p className='text-danger'>Location field is required</p> : <></>} */}
                  <p className="text-danger mb-2">{errors?.address}</p>
                  <div className="spokenlang mb-4 mt-4">
                    <p>Languages I speak</p>
                    <div id="langs" className="">
                      {/* {choosen} */}
                      {/* {console.log('choosen.length', choosen.length, choosen)} */}
                      {choosen &&
                        choosen?.length > 0 &&
                        choosen.map((a) => {
                          {
                            console.log("choosen.length", a, choosen.length);
                          }
                          return (
                            <div className="bordbox badge text-dark px-2 py-2 br-10 me-2 mt-2 mb-2">
                              <span>{a.length > 0 ? a : ""}</span>
                              <Button
                                variant="link"
                                className="p-0 ms-1 fa fa-times nounder text-dark f-14"
                                onClick={(e) => removechoose(e)}
                              />
                            </div>
                          );
                        })}
                    </div>
                    <Button
                      variant="link"
                      className="p-0 orsnge_y f-14 "
                      onClick={() => setLanguage(true)}
                    >
                      Add more
                    </Button>
                    {/* {console.log('!choosen.length == 0', choosen.length == 0)} */}
                    {/* {(errorMessage && choosen.length == 0) ? <p className='text-danger'>Language field is required</p> : <></>} */}
                    <p className="text-danger">{errors.choosen}</p>
                  </div>

                  <label>Work</label>
                  {console.log("fjhaswitfyuastyuf", errorMessage)}
                  <input
                    type="text"
                    placeholder={userData && userData.work && userData.work}
                    value={work}
                    onChange={(e) => {
                      if(e?.target?.value){
                        let er = errors;
                        er.work = "";
                        setErrors(er);
                      }
                      setWork(e.target.value);
                    }}
                    className="form-control mb-2 input_big_ne"
                  />
                  {/* {(errorMessage && !work) ? <p className='text-danger'>Work field is required</p> : <></>} */}
                  <p className="text-danger mb-2">{errors.work}</p>
                  <div className="d-flex jc-between">
                    <Button
                      variant="link"
                      className="orsnge_y ps-0"
                      onClick={() => {
                        setEditProf(false);
                        setErrors({});
                        fetchdata();
                      }}
                    >
                      Cancel
                    </Button>
                    <button
                      className="btn btn-theme"
                      onClick={() => {
                        updateProfile();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
                {/* <p className="my-4"><span className="fa fa-language me-1"></span> Some info has been automatically translated.<a href="#" className="text-dark">Show original language</a></p> */}
               {!editprof &&<div>
                <h5 className="mb-3 mt-3 mt-lg-5 mt-xl-5 mt-md-5 account-name-listing">
                  {userData && userData.firstName}’s Listings
                </h5>
                <ul className="inlineblock ps-0 list_new_car">
                  {userData && userData.isHost ? (
                    <OwlCarousel
                      responsive={responsive1}
                      className="owl-theme pb-5"
                      dots={false}
                      margin={10}
                      nav={true}
                      id="big"
                      slideBy={3}
                    >
                      {userHostings &&
                        userHostings.map((val) => (
                          <div className="item">
                            <li>
                              <a href="#" className="text-muted f-14 under">
                                <img
                                  src={
                                    API_URL +
                                    val.photosPath +
                                    "/" +
                                    val.photos[0].name
                                  }
                                  className="listingimg"
                                />
                                <div
                                  className="mt-3"
                                  onClick={() => {
                                    window.location.href =
                                      window.location.origin +
                                      "/room/" +
                                      val._id;
                                  }}
                                >
                                  <span className="title_star_listi">
                                    <i className="fa fa-star me-2 themeclr" />{" "}
                                    {val.title}
                                  </span>
                                  <p className="mb-0">
                                    <span>{val.privacyType}</span> .{" "}
                                    <span>{val.propertyTypeGroup.type}</span>
                                  </p>
                                  <p>{val.location.countrycode}</p>
                                </div>
                              </a>
                            </li>
                          </div>
                        ))}
                    </OwlCarousel>
                  ) : (
                    <></>
                  )}

                  {/* <li>
                                    <a href="#" className="text-muted f-14 under"> 
                                        <img src={place1} className="listingimg" />
                                        <div className="mt-3">  
                                            <span><i className="fa fa-star me-2 themeclr"></i> New</span>
                                            <p className="mb-0"><span>Private room</span> . <span>Guest suite</span></p>
                                            <p>two</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-muted f-14 under"> 
                                        <img src={place1} className="listingimg" />
                                        <div className="mt-3">  
                                            <span><i className="fa fa-star me-2 themeclr"></i> New</span>
                                            <p className="mb-0"><span>Private room</span> . <span>Guest suite</span></p>
                                            <p>two</p>
                                        </div>
                                    </a>
                                </li> */}
                </ul>
                </div>}
                

                {/* <hr className="themehr"/> */}
                {/* <p className="my-4"><span className="fa fa-star"></span> 1 Review</p> */}
                {/* <div className="profreviews">
                               <div className="reviews mt-4">
                                  <label className="f-14 text-muted">April 2022</label>
                                  <p>It was lovely hosting sahib/ Pranav and team. they were very well behaved and maintained the property and rooms very clean . it wasn't 
                                    a hassle hosting them. I would definitely host them again.</p>
                                    <div className="d-flex mt-2 align-items-center">
                                        <img src={profimg} />
                                        <div>
                                            <p>Vishal, Chickmagaluru, India</p>
                                            <p className="pb-0 f-14 text-muted">Joined in 2012</p>
                                        </div>
                                    </div>
                                    <div className="reply ms-5 mt-3">
                                    <label className="f-14 text-muted">April 2022</label>
                                    <p>Thank you</p>
                                    <div className="d-flex mt-2 align-items-center">
                                        <img src={profimg} />
                                        <div>
                                            <p>Response from AswinKumar</p>
                                            <p className="pb-0 f-14 text-muted">Joined in 2012</p>
                                        </div>
                                    </div>
                                    </div>
                               </div>
                               <div className="reviews mt-4">
                                  <label className="f-14 text-muted">April 2022</label>
                                  <p>It was lovely hosting sahib/ Pranav and team. they were very well behaved and maintained the property and rooms very clean . it wasn't 
                                    a hassle hosting them. I would definitely host them again.</p>
                                    <div className="d-flex mt-2 align-items-center">
                                        <img src={profimg} />
                                        <div>
                                            <p>Vishal, Chickmagaluru, India</p>
                                            <p className="pb-0 f-14 text-muted">Joined in 2012</p>
                                        </div>
                                    </div>
                               </div>
                               <div className="reviews mt-4">
                                  <label className="f-14 text-muted">April 2022</label>
                                  <p>It was lovely hosting sahib/ Pranav and team. they were very well behaved and maintained the property and rooms very clean . it wasn't 
                                    a hassle hosting them. I would definitely host them again.</p>
                                    <div className="d-flex mt-2 align-items-center">
                                        <img src={profimg} />
                                        <div>
                                            <p>Vishal, Chickmagaluru, India</p>
                                            <p className="pb-0 f-14 text-muted">Joined in 2012</p>
                                        </div>
                                    </div>
                               </div>
                               <div className="reviews mt-4">
                                  <label className="f-14 text-muted">April 2022</label>
                                  <p>It was lovely hosting sahib/ Pranav and team. they were very well behaved and maintained the property and rooms very clean . it wasn't 
                                    a hassle hosting them. I would definitely host them again.</p>
                                    <div className="d-flex mt-2 align-items-center">
                                        <img src={profimg} />
                                        <div>
                                            <p>Vishal, Chickmagaluru, India</p>
                                            <p className="pb-0 f-14 text-muted">Joined in 2012</p>
                                        </div>
                                    </div>
                               </div>
                               <Button variant="outline-dark" className="mt-4">See all 12 view</Button>
                           </div> */}
                {/* <hr className="themehr"/>
                           <Button variant="link">Reviews by you</Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
