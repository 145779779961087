import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import Header from "../Components/Common/Header.js";
import Cookies from "universal-cookie";

// import profimg from "../Assets/images/landingPage/profilenew.png";
import profimg from "../../src/Assets/images/profilenew.png";
import upload from "../../src/Assets/images/upload.png";
import place1 from "../Assets/images/place1.webp";
import AddLang from "./Common/Modals/AddLang.js";
import Footer from "./Common/Footer.js";
import { AiOutlineCheckSquare } from "react-icons/ai";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import eye from "../Assets/images/visibleNew.png";
import editFill from "../Assets/images/edit-fill.png";
import languages from "../config/languages.json";
import { AiFillEdit } from "react-icons/ai";
import {
  getUserDatas,
  getUserEmail,
  getusersdatahooks,
  updatebankdetailhooks,
  updateUserProfile,
  usersdata,
} from "../Hooks/useUserHook.js";
import { getUserDetails } from "../Hooks/useUserHostingHook.js";
import { API_URL } from "../config/env.js";
import { getUserHostings } from "../Hooks/useUserHostingHook.js";
import { isEmpty } from "lodash";
import { toast } from "react-hot-toast";

const AddBank = () => {
  const [language, setLanguage] = useState(false);
  const [choosen, setChoosen] = useState([]);
  console.log("choosen", choosen);
  const [editprof, setEditProf] = useState(false);
  console.log("editprof", editprof);
  const [editprofimg, setEditProfimg] = useState(profimg);
  const [imgUpd, setImgUpd] = useState(false);
  console.log("tureeeeeeeeeeeee", imgUpd);
  const [bio, setBio] = useState("");
  console.log("biodatataatata", bio);
  const [address, setAddress] = useState("");
  const [work, setWork] = useState("");
  console.log("workdatasssssssssss", work);
  const [selectedFile, setSelectedFile] = useState({});
  console.log("selectedFiles", selectedFile);
  const [userData, setUserData] = useState({});
  console.log("userDatasfdasdfasd", userData);
  const [getbiodata, setBiodata] = useState("");
  console.log("getbiodatasgal", getbiodata);
  console.log("userDatassss----------------------", userData, typeof userData);
  const [userHostings, setUserHostings] = useState([]);
  const [lang, setLang] = useState();
  const [profilePicturePath, setprofilePicturePath] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [useremail , setUseremail] = useState(getUserEmail());
  const cookies = new Cookies();
  const [viewimg , setViewimg] = useState("");
  console.log("errrorMessage", errorMessage);

  const [holdername , setHoldername] = useState("");
  const [accountno , setAccountno] = useState("");
  const [bankname , setBankname] = useState("");
  const [branchname , setBranchname] = useState("");
  const [ifsccode , setIfsccode] = useState("");


  const fetchdata = () => {
    getusersdatahooks(getUserEmail()).then((data) => {
      console.log("fetchdata" , data);
      setAccountno(data?.accountno ? data?.accountno : "");
      setHoldername(data?.holdername ? data?.holdername : "");
      setBankname(data?.bankname ? data?.bankname : "");
      setBranchname(data?.branchname ? data?.branchname : "");
      setIfsccode(data?.ifsccode ? data?.ifsccode : "")
    })
  }
  useEffect(() => {
    fetchdata()
  } , [])


  const handlesave = async() => {
    console.log("handle save" , holdername , accountno , bankname , branchname , ifsccode);
    let errors = {};
    if (holdername == "" || holdername == "undefined" || holdername == undefined) {
      errors.holdername = "Account Holder Name field is required";
    }
    if (bankname == "" || bankname == "undefined" || bankname == undefined) {
      errors.bankname = "Bank Name field is required";
    }
    if (branchname == "" || branchname == "undefined" || branchname == undefined) {
      errors.branchname = "Branch Name field is required";
    }
    if (ifsccode == "" || ifsccode == "undefined" || ifsccode == undefined) {
      errors.ifsccode = "IFSC Code field is required";
    }
    if(!accountno){
      errors.accountno = "Account Number field is required"
    }
    else if(isNaN(parseFloat(accountno))){
      errors.accountno = "Invalid Account Number"
    }
    setErrors(errors);
    if(isEmpty(errors)){
      // let update = awa
      let payload = {
        accountno : accountno,
        holdername : holdername,
        bankname : bankname,
        branchname : branchname,
        ifsccode : ifsccode,
        email : getUserEmail()
      }
      let update = await updatebankdetailhooks(payload);
      toast.success("Bank Detail Updated successfully!.")

    }
  }

  const choosenlang = (data, ind) => {
    console.log("dadta,in dex", data, choosen, data.length);
    var mergedata = [];
    console.log("mergedata", mergedata.length - 1);
    if (data) {
      // console.log("choosn..length", choosen, choosen.length, choosen.length > 0)

      if (choosen && choosen.length > 0) {
        choosen.map((item, index) => {
          console.log("data,item", index, data, item, data.length - 1);
          if (!mergedata.includes(item)) {
            mergedata.push(item);
          }
        });
        data.map((item, index) => {
          console.log("data,item", index, data, item, data.length - 1);
          if (!mergedata.includes(item)) {
            mergedata.push(item);
          }
        });

        setChoosen(mergedata);
      } else {
        console.log("elseeeeeeee", data);
        setChoosen(data);
      }
    } else {
      setChoosen(data);
    }
  };

  const removechoose = (e) => {
    let val = e?.target?.previousSibling?.parentNode?.children[0]?.innerHTML;
    console.log("valuesss", val);
    setChoosen(choosen.filter((a) => a != val));
  };
  const getimage = (e) => {
    //  console.log(e.target.files[0])
    //     setEditProfimg(e.target.files[0].name)
    const files = e.target.files[0];
    console.log("filesdetails", files);
    setSelectedFile(files);
    if (files) {
      setViewimg(window.URL.createObjectURL(e.target.files[0]));
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.addEventListener("load", function() {
        console.log("this", this, "this.result", this.result);
        setEditProfimg(this.result);
        setImgUpd(true);
      });
    }
  };

  const profileValidation = async () => {
    let errors = {};
    var imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;
    console.log(
      "workdddddddddddddd",
      work,
      work == "" || work == "undefined" || work == undefined
    );

    if (bio == "" || bio == "undefined" || bio == undefined) {
      errors.bio = "About field is required";
    }

    if (address == "" || address == "undefined" || address == undefined) {
      errors.address = "Location field is required";
    }
    console.log("asdfbjasfsd", choosen);
    if (
      (choosen && choosen.length == 0) ||
      choosen == "undefined" ||
      choosen == undefined
    ) {
      errors.choosen = "Language field is required";
    }

    if (work == "" || work == "undefined" || work == undefined) {
      errors.work = "Work field is required";
    }

    if (selectedFile && selectedFile?.name) {
      if (selectedFile && selectedFile.size > 1000000) {
        errors.image = "Too_large";
      } else if (!imageFormat.test(selectedFile.name)) {
        errors.image = "Invalid Image";
      }
    } else {
      console.log("datzaaaaaaaaa", profilePicturePath);
      if (!profilePicturePath) {
        errors.image = "Choose any photos";
      }
    }
    setErrors(errors);
    return errors;
  };

  const updateProfile = async () => {
    window.scrollTo(0,0);
    let validation = await profileValidation();
    console.log("errrrrrrrrr", validation);
    let Objlen = Object.keys(validation).length;

    const data = {
      email: userData.email,
      bio: bio,
      work: work,
      address: address,
      profilePic: selectedFile,
      languagesKnown: JSON.stringify(choosen),
    };
    console.log("datasgal", data, "hihi", JSON.stringify(choosen));
    console.log("datassssssssssssss", data);

    if (Objlen == 0) {
      await updateUserProfile(data).then((result) => {
        console.log("userprofiledata", result.userdate.record);
        console.log(
          result,
          result.userdate.record.languagesKnown,
          "USER DATA UPDATE",
          "alldatssss",
          result
        );
        console.log("result.userdate.record.bio", result.userdate.record.bio);
        // cookies.set("User-Datas", result.userdate.record);
      });
      setEditProf(false);
    }

    setErrorMessage(false);
  };

  useEffect(() => {
    console.log("enter this", getbiodata);
    setUserData(getUserDatas());
    getUserHostings(getUserDatas().email).then((data) => {
      setUserHostings(data);
    });
    console.log(userData.email);
    usersdata(userData?.email).then((val) => {
      console.log("the value", val);
      // setChoosen(val.data.languagesKnown);
    });
    async function fetchdata() {
      await getUserDetails(getUserDatas().email).then((result) => {
        console.log("result data", result[0]);
        setChoosen(result[0]?.languagesKnown);
        setWork(result[0]?.work);
        setBio(result[0]?.bio);
        setAddress(result[0]?.address);
        setprofilePicturePath(result[0]?.profilePicturePath);
        // setBiodata(userData?.bio)
      });
    }
    fetchdata();
    // currendata()
  }, []);
  const [responsive1] = React.useState({
    0: {
      items: 1,
    },
    425: {
      items: 2,
    },
    991: {
      item: 3,
    },
    1024: {
      items: 3,
    },
  });
  return (
    <div className="prof-sec">
      {language && (
        <AddLang
          choose={(e) => choosenlang(e)}
          onDismiss={() => setLanguage(false)}
        />
      )}
      <Header />
      <div className="profile profpagetop mb-4">
        <div className="container container_custom">
        <div className="row">
            <div className="col-12 col-md-10 col-lg-9 mx-auto">
        <h3 className="mt-lg-5 account mb-5">Add Bank Details</h3>

        <label> Account Number</label>
                  <input
                    type="text"
                    value={accountno}
                    onChange={(e) => setAccountno(e?.target?.value)}
                    className="form-control mb-2 input_big_ne"
                  />
{(errors?.accountno) ? <p className='text-danger mb-2'>{errors?.accountno}</p> : <></>}
                  <label className="mt-2"> Account Holder Name</label>
                  <input
                    type="text"
                    value={holdername}
                    onChange={(e) => setHoldername(e?.target?.value)}
                    className="form-control mb-2 input_big_ne"
                  />
{(errors?.holdername) ? <p className='text-danger mb-2'>{errors?.holdername}</p> : <></>}
<label className="mt-2">Bank Name</label>
                  <input
                    type="text"
                    value={bankname}
                    onChange={(e) => setBankname(e?.target?.value)}
                    className="form-control mb-2 input_big_ne"
                 
                  />

{(errors?.bankname) ? <p className='text-danger mb-2'>{errors?.bankname}</p> : <></>}  
<label className="mt-2">Branch Name</label>
                  <input
                    type="text"
                    value={branchname}
                    onChange={(e) => setBranchname(e?.target?.value)}
                    className="form-control mb-2 input_big_ne"
                 
                  />
{(errors?.branchname) ? <p className='text-danger mb-2'>{errors?.branchname}</p> : <></>} 
<label className="mt-2">IFSC Code</label>
                  <input
                    type="text"
                    value={ifsccode}
                    onChange={(e) => setIfsccode(e?.target?.value)}
                    className="form-control mb-2 input_big_ne"
                 
                  />
      {(errors?.ifsccode) ? <p className='text-danger mb-2'>{errors?.ifsccode}</p> : <></>} 
                  {/* {(errorMessage && !address) ? <p className='text-danger'>Location field is required</p> : <></>} */}
               

              
                  {/* {(errorMessage && !work) ? <p className='text-danger'>Work field is required</p> : <></>} */}
                  <div className="d-flex jc-between pb-5 mt-4">
                    
                    <button
                      className="btn btn-theme"
                      onClick={() => handlesave()}
                    >
                      Save
                    </button>
                  </div>
                  </div>
                </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddBank;
