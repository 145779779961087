import React, {  useEffect, useState } from 'react';
import {Modal} from 'react-bootstrap'

import ConfirmAvailcont from '../ConfirmAvailcont';


const ConfirmAvail = (props) =>{
    const[confirmnew , setConfirmnew] = useState(true);
    const [button , setButton] = useState(true);

    useEffect(() => {
      setButton(props?.butn);
    } , [props?.butn])
    return(
        <Modal show={confirmnew} centered scrollable size="md" className='modal_style'>
        <Modal.Header className='border-0 pb-0'>
          <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      </Modal.Header>
          <Modal.Body>
            <ConfirmAvailcont selectedref = {props?.selectedref} butn = {button} from={props?.from} to={props?.to} onSetPriceDate={(price) => { props.onSetPriceDate(price) }}/>
               
          </Modal.Body>
      </Modal>
    )
}

export default ConfirmAvail