import React, { Component, useState, useEffect } from 'react';
import { Form, Button, InputGroup, Modal } from 'react-bootstrap';

import countrycode from '../../../config/countrycode.json'
import PlacesAutocomplete, {geocodeByAddress,getLatLng} from 'react-places-autocomplete';

const AddressModal = (props) => {
    const [addressmodal, setAddressModal] = useState(true)
    const [showadd, setShowAdd] = useState(false);
    const [userAddress, setUserAddress] = useState({});
    console.log('userAddress', userAddress)
    const [errorMessage, setErrorMessage] = useState(false);
    console.log('errorMessgal', errorMessage)
    const [addresss , setAddress] = useState('')
    console.log('adresdataaaaaaaaaa',addresss)


    const address = () => {
        setErrorMessage(false);
        if (userAddress.street && userAddress.area &&
            userAddress.state && userAddress.postcode &&
            userAddress.city && userAddress.countrycode) {
            console.log("userAddress >>>>", userAddress);
            props.onGetUserAddress(userAddress);
            props.onDismiss()
        } else {
            console.log('error enter this')
            setErrorMessage(true);
        }
    }

    const handleSelect = async (value) => {
        console.log('valuesssssss', value)
        const result = await geocodeByAddress(value)
        console.log('resultsssss', result);
        const li = await getLatLng(result[0])
        console.log('liiiiiiii',li,li.lat,li.lng) 
        let latitude = li.lat;
        let longitude = li.lng;
        props.onGetLatLng(latitude,longitude)
        
        setAddress(value)
        console.log("value in addressmodal" , value);
            // const emailid =  window.location.pathname.split("/")[3]
            var data = value.split(",");
            console.log('dataaaaaaaaaaaaaaaaaaaaaaaaa',data,data.length,data[data.length-1])
            userAddress.countrycode = data[data.length-1];
            userAddress.state = data[data.length-2];
            userAddress.city = data[data.length-3];
            userAddress.area = data[data.length-4];
            userAddress.lat = latitude;
            userAddress.lng = longitude;
            }



    const onChange = (e) => {
        console.log('enter this')
        var regex = /^[0-9]+$/
        var alphabets = /^[A-Za-z]+$/
        var whitespace = /\s/

        if (e.target.id == "state") {
            console.log('e.target', e.target.value, alphabets.test(e.target.value), whitespace.test(e.target.value))
            if (alphabets.test(e.target.value) || e.target.value == "" || whitespace.test(e.target.value)) {
                setUserAddress({ ...userAddress, ...{ 'state': e.target.value } })
            }
            else {
                document.getElementById("state").value = userAddress.state;
                document.getElementById("state_err").classList.remove("d-none");
            }
        }
        else if (e.target.id == 'postcode') {
            console.log('regex.test(e.target.value)', regex.test(e.target.value))
            if (e.target.value === "" || regex.test(e.target.value)) {
                console.log('enter this two')
                setUserAddress({ ...userAddress, ...{ 'postcode': e.target.value } })
            }
            else {
                document.getElementById("postcode").value = userAddress.postcode;
                document.getElementById("postcode_err").classList.remove("d-none");
            }
        }

        else if (e.target.id == "city") {
            console.log('e.target---', e.target.value, 'alphabets', alphabets.test(e.target.value))
            if (alphabets.test(e.target.value) || e.target.value == "" || whitespace.test(e.target.value)) {
                setUserAddress({ ...userAddress, ...{ 'city': e.target.value } })
            }
            else {
                document.getElementById("city").value = userAddress.city;
                document.getElementById("city_err").classList.remove("d-none")
            }
        }
        else if (e.target.id == "area") {
            if (e.target.value || e.target.value == "") {
                setUserAddress({ ...userAddress, ...{ 'area': e.target.value } })
            }

        }
        else if (e.target.id == "street") {
            if (e.target.value || e.target.value == "") {
                setUserAddress({ ...userAddress, ...{ "street": e.target.value } })
            }
        }
    }

    useEffect(() => {
        console.log("localStorage.getItem", localStorage.getItem("address"))
        setUserAddress((localStorage.getItem("address")) ? JSON.parse(localStorage.getItem("address")) : userAddress);
        (localStorage.getItem("address")) ? props.onGetUserAddress(JSON.parse(localStorage.getItem("address"))) : localStorage.removeItem("address")
    }, []);

    
    return (
        <Modal show={addressmodal} centered scrollable className='modal_style'>
            <Modal.Header className='border-0 pb-0'>
                <button type="button" className="btn close close_modal" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
            </Modal.Header>
            <Modal.Body>
                {/* <div>
                <PlacesAutocomplete
                    value={addresss}
                    onChange={setAddress}
                    onSelect={handleSelect}
                >

                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <input
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const clauserAddressssName = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}


                </PlacesAutocomplete>
                </div> */}
                
                <Form.Group className="inputs" controlId="formBasicEmail">
                    <PlacesAutocomplete
                    value={addresss}
                    onChange={setAddress}
                    onSelect={handleSelect}
                >

                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <Form.Control type="text" id="search"
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}


                </PlacesAutocomplete>
                    </Form.Group>

                <div className='bg-white mt-4' data-aos="fade-up">
                    {console.log('sdjhfghgfjhsdgs', userAddress.street)}
                    <Form className='gropform'>

                        {/* Autofill  */}
                      


                        <Form.Group className="inputs withlab" controlId="formBasicEmail">
                        <Form.Label>Flat & Street</Form.Label>
                            <Form.Control type="text" id="street" value={userAddress?.street} onChange={(e) => onChange(e)} placeholder={(userAddress.street) ? userAddress.street : "Flat, Suit and Street etc"} />
                        </Form.Group>
                        {(errorMessage && !userAddress.street) ? <p className='text-danger mb-3'>This is a mandatory Field</p> : <></>}


                        <Form.Group className="inputs withlab" controlId="formBasicPassword">
                        <Form.Label>Area</Form.Label>
                            <Form.Control type="text" id="area" value={userAddress.area} onChange={(e) => { onChange(e) }} placeholder={(userAddress.area) ? userAddress.area : "Area"} />
                        </Form.Group>
                        {(errorMessage && !userAddress.area) ? <p className='text-danger mb-3'>This is a mandatory Field</p> : <></>}
                        <Form.Group className="inputs withlab" controlId="formBasicEmail">
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" id="state" value={userAddress?.state} onChange={(e) => { onChange(e) }} placeholder={(userAddress.state) ? userAddress.state : "Enter state"} />
                        </Form.Group>
                        {console.log('userAddress.stategal', userAddress.state)}
                        {(errorMessage && !userAddress.state) ? <p className='text-danger mb-3'>This is a mandatory Field</p> : <></>}
                        <p className='text-danger d-none' id="state_err"></p>
                        <Form.Group className="inputs withlab" controlId="formBasicEmail">
                            <Form.Label>Postcode </Form.Label>
                            <Form.Control type="text" id="postcode" value={userAddress.postcode} onChange={(e) => onChange(e)} placeholder={(userAddress.postcode) ? userAddress.postcode : "postcode"} />
                        </Form.Group>
                        {(errorMessage && !userAddress.postcode) ? <p className='text-danger mb-3'>This is a mandatory Field</p> : <></>}
                        <p className='text-danger d-none' id="state_err">Type only Numbers</p>
                        <Form.Group className="inputs withlab" controlId="formBasicEmail">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" id="city" value={userAddress.city} onChange={(e) => onChange(e)} placeholder={(userAddress.city) ? userAddress.city : "city"} />
                        </Form.Group>
                        {(errorMessage && !userAddress.city) ? <p className='text-danger mb-3'>This is a mandatory Field</p> : <></>}
                        <p className='text-danger d-none' id="city_err"></p>
                        <div className='inputs withlab lastchild'>
                        {/* currentTarget userAddress.countrycode = e.target.value */}
                            <p>Country/Region</p>
                            <Form.Select aria-label="Default select example" value={userAddress.countrycode} onChange={(e) => { setUserAddress({ ...userAddress, ...{ "countrycode": e.target.value } }); console.log("e" , e?.currentTarget?.value); }}>
                                {countrycode.map((result) => <><option>{`${result.name}`}</option>{console.log('resuldtddadsa',userAddress.country,result.name,'India' == result.name)}</>)
                                }
                            </Form.Select>
                            {(errorMessage && !userAddress.countrycode) ? <p className='text-danger mb-3'>This is a mandatory Field</p> : <></>}
                        </div>
                        <hr />
                        <div className='text-right'>
                        <Button variant="primary" type="button" className='btn-theme' onClick={address}>

                            Looks good
                        </Button>
                        </div>
                    </Form>
                    <div className='loca mt-4'>
                        <h6> Show your specific location</h6>
                        <p className='f-14'>Make it clearer to guests where your place is located.</p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddressModal