import React, { Component, useState, useEffect } from "react";

import { propTypes } from "react-bootstrap/esm/Image";
import Scrollbars from "react-custom-scrollbars";

const Space = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState("");
  const setData = (val, index) => {
    {
      console.log("val,index", props,val, index);
    }
    setSelectedIndex(index);
    setSelectedValue(val);
    localStorage.setItem("spaceIndex", index);
    props.onGetSpace(val);
  };

  useEffect(() => {
    setSelectedIndex(
      localStorage.getItem("spaceIndex")
        ? localStorage.getItem("spaceIndex")
        : selectedIndex
    );
    localStorage.getItem("privacyType")
      ? props.onGetSpace(localStorage.getItem("privacyType"))
      : localStorage.removeItem("spaceIndex");
  }, []);

  return (
    <div className="col-md-12">
      <div className="placetype rightcont mb-5 describeplace">
        <Scrollbars style={{ height:"calc(100vh - 320px)" }} className="scroll">
          <div
            className="row  px-3 py-2 align-items-center height-100 mb-5"
            data-aos="fade-up"
          >
            <ul>
              {props.data &&
                props.data.content.map((val, index) => (
                  // val?.s
                  val?.status != "Inactive" && 
                  <li>
                    <button
                      onClick={() => {
                        setData(val.type, index);
                      }}
                      className={
                        index == selectedIndex
                          ? "placecards active p-4"
                          : "placecards p-4"
                      }
                    >
                      <h6 className="mb-0">{val.type}</h6>
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default Space;
