import React, { Component, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {Modal, Button, Form} from 'react-bootstrap'




const NightModal = (props) => {

    const [infoNightModal,setInfoNightModal] = useState(true);


  
    return(
    
        <Modal show={infoNightModal} centered scrollable className='modal_style'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </Modal.Header>
            <Modal.Body>
                <h6>Booking Night(s)</h6>
              
<div className='mt-4'>
    {props?.spl?.length > 0 ? props?.spl?.map((dat) => 
    <div className='shadowbox p-3 mb-3'>
    <h6 className='mt-2'>Check-in Date</h6>
    <p className='f-14 mb-2'>{new Date(dat?.startDate)?.toLocaleDateString()}
    </p>

    <h6 className='mt-2'>Check-out Date</h6>
    <p className='f-14 mb-2'>{new Date(dat?.endDate)?.toLocaleDateString()}
    </p>
    <h6 className='mt-2'>Price</h6>
    <p className='f-14 mb-2'>{dat?.price}
    </p>
    </div>) : <p className='text-danger'><b>Special price not found for selected dates!</b></p>}
        

    {/* <div className='shadowbox p-3  mb-3'>
                
                <h6 className='mt-2'>Check-in Date</h6>
                <p className='f-14 mb-2'>22-Oct-2023
                </p>

                <h6 className='mt-2'>Check-out Date</h6>
                <p className='f-14 mb-2'>22-Oct-2023
                </p>
                <h6 className='mt-2'>Price</h6>
                <p className='f-14 mb-2'>22-Oct-2023
                </p>

        </div>


                <div className='shadowbox p-3 mb-3'>

                <h6 className='mt-2'>Check-in Date</h6>
                <p className='f-14 mb-2'>22-Oct-2023
                </p>

                <h6 className='mt-2'>Check-out Date</h6>
                <p className='f-14 mb-2'>22-Oct-2023
                </p>
                <h6 className='mt-2'>Price</h6>
                <p className='f-14 mb-2'>22-Oct-2023
                </p>

        </div> */}
                </div>

                            <div className='text-end'>
                    <Button className='btn-theme' onClick={() =>props.onDismiss()}>Done</Button>
                </div>
                
            </Modal.Body>
        </Modal>
     
    )
}

export default NightModal