import React, { Component, useEffect, useState, useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Form, Button, InputGroup } from "react-bootstrap";

import MapContainer from "./MapContainer";
import Map from "./Map";
import { GoogleComponent } from "react-google-location";
import GoogleMaps from "../../Maps/GMaps";
import AddressModal from "../../Common/Modals/AddressModal";
import { getCurrentLocationFromNav } from "../../../Hooks/useUserHostingHook";
import { propTypes } from "react-bootstrap/esm/Image";
import { GOOGLE_MAP_API_KEY } from "../../../config/env";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const apiKey = "AIzaSyDwQOCtNC-XQRRYsZIg5lXexgr-CrOEXrw";
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

const PlaceLocation = (props) => {
  const [addressmodal, setAddressModal] = useState(false);
  const [showadd, setShowAdd] = useState(true);
  const [address, setAddress] = useState({});
  console.log("addressaddress", address);
  const [position, setPosition] = useState({});
  console.log("setPosition>>>>>>>>>>>>>><", position);
  const [addresss, setAddresss] = useState("");
  const [latitude, setLatitude] = useState("");
  console.log("latitiude jeje", latitude);
  const [longitude, setLongitude] = useState("");
  console.log("longitude jeje", longitude);
  const [coordsLoaded, setCoordsLoaded] = useState(false);
  console.log("coordsLoaded", coordsLoaded);
  const [location, setLocation] = useState({});
  console.log("location eiei", location);
  const [userAddress, setUserAddress] = useState({});

  const getAddressFromGmap = async (data) => {
    console.log("data>>>>>>>>>>>>>....", data);
    // setShowAdd(false);
    console.log("getAddressFromGmap", data);
    const pos = { lat: data.latitude, lng: data.longitude };
    setPosition(pos);
    const value = await reverseGeocode(data);
    value.lat = pos.lat;
    value.lng = pos.lng;
    setLocation(value)
    console.log('getAddressFromGmap eiei',value)
    props.onGetPlaceLocation(value)
  }

  const getCurrentLocation = async () => {
    // setShowAdd(false);
    navigator.geolocation.getCurrentPosition(async (location) => {
      console.log("current locatio.mmmn :", location);
      const pos = { lat: location.coords.latitude, lng: location.coords.longitude }
      setPosition(pos);
      const value = await reverseGeocode(location.coords);
      value.lat = pos.lat;
      value.lng = pos.lng;
      setLocation(value);
      console.log('getCurrentLocation eiei',value)

      props.onGetPlaceLocation(value);
    });
  };

  const extractAddress = (place) => {
    const address = {
      street: "",
      area: "",
      city: "",
      state: "",
      postcode: "",
      countrycode: "",
      plain() {
        const street = this.street ? this.street + ", " : "";
        const area = this.area ? this.area + ", " : "";
        const city = this.city ? this.city + ", " : "";
        const postcode = this.postcode ? this.postcode + ", " : "";
        const state = this.state ? this.state + ", " : "";
        return street + area + city + postcode + state + this.countrycode;
      },
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }

    place.address_components.forEach((component) => {
      const types = component.types;
      console.log("component.types vjj", component.types);
      const value = component.long_name;
      console.log("component.long_name vjj", component.long_name);

      if (types.includes("street_number")) {
        address.street = value;
      }

      if (types.includes("locality")) {
        address.area = value;
      }

      if (types.includes("administrative_area_level_3")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.postcode = value;
      }
      if (types.includes("country")) {
        address.countrycode = value;
      }
      if(types.includes("plus_code")){
        address.street = value;
      }


    });
    console.log("addressinside" , address);
    return address;
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((location) => {
      console.log(
        "current location :",
        location.coords.latitude,
        location.coords.longitude
      );
      const pos = {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      };
      setPosition(pos);
      setCoordsLoaded(true);
    });
  }, [location.area]);

  //  useEffect(()=>
  //    {

  //    },[])
  const reverseGeocode = async ({ latitude: lat, longitude: lng }) => {
    console.log("lat,lng", lat, lng);
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    // searchInput.current.value = "Getting your location...";
    let addr = {};
    await fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        console.log("placessssssssssss", place , location);

        let _address = extractAddress(place);
        delete _address.plain;
        setAddress(_address);
        //   console.log("_address.plain()",_address.plain());
        //   searchInput.current.value = _address.plain();
        console.log("address object:", _address);
        setCoordsLoaded(true);
        addr = _address;
      });

    return addr;
  };

  const getUserAddress = (data) => {
    setAddress(data);
    props.onGetPlaceLocation(data);
  };

  useEffect(() => {
    localStorage.getItem("address")
      ? props.onGetPlaceLocation(JSON.parse(localStorage.getItem("address")))
      : setAddress(address);
    localStorage.getItem("address")
      ? setAddress(JSON.parse(localStorage.getItem("address")))
      : setAddress(address);
  }, []);

  const getLat = async (lat, lng) => {
    let data = {
      latitude: lat,
      longitude: lng,
    };
    let pos = {
      lat: lat,
      lng: lng,
    };
    console.log("positionsssssssss", pos);
    setCoordsLoaded(true);
    setPosition(pos);
    const value = await reverseGeocode(data);
    setLocation(value);
    console.log("getLat eiei", value);
  };



  const handleSelect = async (value) => {
    console.log('valuesssssss', value)
    const result = await geocodeByAddress(value)
    console.log('resultsssss', result);
    const li = await getLatLng(result[0])
    console.log('liiiiiiii',li,li.lat,li.lng) 
    let latitude = li.lat;
    let longitude = li.lng;
    getLat(latitude,longitude)
    
    setAddress(value)
    console.log("value in addressmodal" , value);
        // const emailid =  window.location.pathname.split("/")[3]
        var data = value.split(",");
        console.log('dataaaaaaaaaaaaaaaaaaaaaaaaa',data,data.length,data[data.length-1])
        userAddress.countrycode = data[data.length-1];
        userAddress.state = data[data.length-2];
        userAddress.city = data[data.length-3];
        userAddress.area = data[data.length-4];
        userAddress.lat = latitude;
        userAddress.lng = longitude;
        getUserAddress(userAddress)
        }





  return (
    <>
      <div className='mapComponent map_height_loc_map'>   
             {position && coordsLoaded && <GoogleMaps position={position} onGetAddress={getAddressFromGmap} />}
      </div>
      {console.log('ursgthsdkfsd',position,coordsLoaded)}
      <div className='col-lg-5 col-xl-5 col-md-5 col-sm-8 position-absolute map-custom-position'>
        {addressmodal && <AddressModal onGetUserAddress={getUserAddress} onGetLatLng={getLat} onDismiss={() => setAddressModal(false)} />}
        <div className='placetype  mb-5 describeplace mt-5 placetype_hei_new'>
          <div className='row  px-3 align-items-center height-100 mb-90'>
            <div>
     </div>

            <div className=''>
              <InputGroup className="input_with_ma">
                <InputGroup.Text id="basic-addon1"><span className='fa fa-map-marker'></span></InputGroup.Text>
                <Form.Control
                  placeholder={(address.street || address.area || address.city) ? (address.street + "," + address.area + "," + address.city) : "Your Hosting Place"}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onClick ={() => setAddressModal(true)}
                  readOnly = "true"
                />

              </InputGroup>


{/* <Form.Group className="inputs" controlId="formBasicEmail">
                    <PlacesAutocomplete
                    value={addresss}
                    onChange={setAddress}
                    // onSelect={handleSelect}
                >

                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <Form.Control type="text" id="search"
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                })}
                                onFocus={() => setShowAdd(true)}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}


                </PlacesAutocomplete>
                    </Form.Group> */}



             

              <div className={showadd ? 'address ps-0 addrss_div_j' : 'address d-none'}>

                <button className='currentloc ps-0' onClick={getCurrentLocation}><span className='fa fa-location-arrow'></span>Use My Current Location</button>

                <Button variant='link' className='manualadd orsnge_y' onClick={() => setAddressModal(true)}>Enter address manually</Button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaceLocation;
