import React from "react";
import {Form,Accordion} from 'react-bootstrap'
import Counter from "./counter";
import { useNavigate } from "react-router-dom";

import { API_URL } from "../../config/env";



const PriceDetails = (props) => {
    const navigate = useNavigate();
    const navigatehosthome = () =>{
        navigate("/confirmandpay")
    }
    return(
        <>
        <div className='personal-info-right-side-sec pricepernight jc-between gap-3 mb-4 align-items-center'>
            <div className="bord p-4">
            <div className="d-flex pricepernight gap-3 mb-4">
                

                {(props.roomDetails.photos)?<img src={`${API_URL}${props.roomDetails.photosPath}/${props.roomDetails.photos[0].name}`}/>:<img src={props.img}/>}
            <div>
                    <p>Room Title : {props?.roomDetails?.title}</p>
                    <p className='mb-0'> <span ><b>{props.bookingDetails.nights} &nbsp;</b></span><span>nights</span></p>
                    <p className="f-12">{props.txt}</p>
                </div>
            </div>
                          
                 <hr className="themehr" />
                 <h5 className="mb-4">Price Details</h5>
                        <div className=''>
                            <p className='d-flex jc-between f-14 mb-2'>

                                <span>${props.roomDetails.price} X {props.bookingDetails.nights} Nights</span>
                                <span>${parseInt(props.roomDetails.price)*parseInt(props.bookingDetails.nights)}</span>
                            </p>
                            <p className='d-flex jc-between f-14'>
                                <span>Service fee</span>
                                <span>${props.bookingDetails.serviceFeeAmount}</span>

                            </p>
                        </div>
                        <hr/>
                        <p className='d-flex jc-between f-14 pb-4 fw-500'>
                                <span>Total fee</span>

                                <span>${props.bookingDetails.totalAmount}</span>

                            </p>
                            </div>
                         </div>
                         </>
    )
}

export default PriceDetails