import React, { Suspense, useEffect, useState } from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { BrowserRouter, Routes, Route , Navigate} from "react-router-dom";
import "./Assets/css/styles.css";
import "./newcss.css";
// import './../newcss.css'
import Aos from "aos";
// import {loadStripe} from '@stripe/stripe-js';
import "aos/dist/aos.css";

import Userroute from "./UserRoute";
import PublicRoutes from "./publicroute";
import Experience from "./Components/Landing/Experience";
import OnlineExp from "./Components/Landing/OnlineExp";
import PropertyType from "../src/Components/BecomeHost/PropertyType";

import Search from "../src/Components/Landing/Search.js";
import HostAccount from "./Components/BecomeHost/HostAccount";
import HostSubscribe from "./Components/BecomeHost/HostSubscribe";
import GuestSubscribe from "./Components/BecomeHost/GuestSubscribe";

import Online from "../src/Components/Landing/online.js";
import Notification from "../src/Components/Notification";
// Modal pages
import SetRules from "./Components/BecomeHost/AfterHost/ModalPages/SetRules";
import AreaForGuest from "./Components/BecomeHost/AfterHost/ModalPages/AreaForGuest";
import PlaceType from "./Components/BecomeHost/Propertytype/PlaceType";
import DoubleCheck from "./Components/BecomeHost/AfterHost/ModalPages/DoubleCheck";
import OrganizePhotos from "./Components/BecomeHost/AfterHost/ModalPages/OrganizePhotos";
import ConfirmAvail from "./Components/BecomeHost/AfterHost/ModalPages/ConfirmAvail";
import PromotList from "./Components/BecomeHost/AfterHost/ModalPages/PromotListing";
import CleaningFee from "./Components/BecomeHost/AfterHost/ModalPages/CleaningFee";
import PersonalInfo from "./Components/BecomeHost/AfterHost/Account/PersonalInfo";
import Insights from "./Components/BecomeHost/AfterHost/Insights/Inights";
import LoginSecurity from "./Components/BecomeHost/AfterHost/Account/LoginSecurity";
import Payment from "./Components/BecomeHost/AfterHost/Account/Payment";
import AccNotification from "./Components/BecomeHost/AfterHost/Account/Notification/AccNotification";
import Experiencedetail from "../src/Components/Landing/Experiencedetail";
import Roomedit from "../src/Components/Landing/Roomedit";
import ContactHost from "./Components/Landing/ContactHost";

import AccPrivacyShar from "./Components/BecomeHost/AfterHost/Account/AccPrivacyShar";
import AccGlobalPerform from "./Components/BecomeHost/AfterHost/Account/AccGlobalPerform";
import AccTravelForWork from "./Components/BecomeHost/AfterHost/Account/AccTravelForWork";
import AccHostongTools from "./Components/BecomeHost/AfterHost/Account/AccHostingTools";
import AccGuestRefferal from "./Components/BecomeHost/AfterHost/Account/AccGuestRefferal";

import Inbox from "./Components/BecomeHost/AfterHost/Inbox";
import Wishlist from "./Components/Landing/Wishlist";
import Calendar from "./Components/BecomeHost/AfterHost/Calendar";
import StartHosting from "./Components/BecomeHost/AfterHost/StartHosting";
import PendingOrders from "./Components/Landing/PendingOrders";
import ConfirmandPay from "./Components/Landing/ConfirmandPay";
import AccGovtId from "./Components/BecomeHost/AfterHost/Account/AccGovtId";
import AccAddId from "./Components/BecomeHost/AfterHost/Account/AccAddId";

import Maps from "./Components/Maps";
import PaymentSuccess from "./Components/Landing/PaymentSuccess";

import Listings from "./Components/BecomeHost/AfterHost/Menu/Listings/Listings";
import ManageSpace from "./Components/BecomeHost/AfterHost/Menu/Listings/ManageSpace";
import Photos from "./Components/BecomeHost/AfterHost/Menu/Listings/Photos";
import Amenities from "./Components/BecomeHost/AfterHost/Menu/Listings/Amenities";
import RoomsandSpace from "./Components/BecomeHost/AfterHost/Menu/Listings/RoomsandSpaces";
import Profile from "./Components/Profile";
import AddBank from "./Components/AddBank";
import ExperianceLand from "./Components/BecomeHost/HostExp/ExperianceLand";
import ExperianceBase from "./Components/BecomeHost/HostExp/CreateExp/ExperianceBase";
import BookExp from "./Components/BookExperience/BookExperience";
import Experiencehome from "./Components/BookExperience/Experiencehome";
import Experienceedit from "./Components/BookExperience/Experienceedit";

import Dispute from "./Components/BecomeHost/AfterHost/Menu/Dispute/dispute";
import TripsStay from "./Components/BecomeHost/AfterHost/Menu/Trips/TripsStay";
import WithdrawHistory from "./Components/BecomeHost/AfterHost/Menu/Trips/WithdrawHistory";

import TripsExp from "./Components/BecomeHost/AfterHost/Menu/Trips/TripsExp";
import Cancellation from "./Components/BecomeHost/AfterHost/Menu/Trips/Cancellation";
import TripPayment from "./Components/BecomeHost/AfterHost/Menu/Trips/TripPayments";
import ExperiencePaymentSuccess from "./Components/Landing/ExperiencePaymentSuccess";
import FilterMap from "./Components/Landing/FilterMap";
import Contactus from "./Components/Common/Contactus";
import FAQ from "./Components/Common/FAQ";
import Privacypolicy from "./Components/Common/Privacypolicy";
import Termsofservice from "./Components/Common/Termsofservice";
import Aboutus from "./Components/Common/Aboutus";
import { getUserEmail } from "./Hooks/useUserHook";
const stripePromise =  loadStripe("pk_test_51O7WG7SFP6kkyXcp6a2KEJuv4oQbJlDGBFwmxwLRdWcr6zGpTpt0TWkoQNXjLijHkZvsF2CG09aNEWn1ajDQGWVz00Xmerf8jZ");

const Land = React.lazy(() => import("./Components/Landing/Land"));

const Allroute = [
  // { path: '/login', component: Adminlogin },
          {path : "/host-experience/:id" , component : ExperianceLand},
          {path : "/manage-experience/:id" , component : ExperianceBase},
          {path : "/" , component  : Land , name: '/public'},
          {path : "/experience" , component :Experience , name: '/public'},
          {path : "/onlineexp" , component : OnlineExp , name: '/public'},
          {path : "/host/:id" , component : PropertyType , name: '/private'},
          {path : "/room/:id" , component : Experiencedetail , name: '/public'}, 
          {path : "/roomedit/:id" , component : Roomedit , name: '/private'}, 
          {path : "/confirm-and-pay/:id" , component : ConfirmandPay , name: '/private'}, 
          {path : "/host-dashboard/:id" , component : StartHosting , name: '/private'},
          {path : "/host-dashboard/calendar/:id" , component : Calendar , name: '/private'},
          {path : "/hosting/insights" , component : Insights , name: '/private'},
          {path : "/host-dashboard/inbox/:id/:id" , component : Inbox , name: '/private'},
          {path : "/host-dashboard/inbox/:id" , component : Inbox , name: '/private'},
          {path : "/hosting/calendar" , component : Calendar , name: '/private'},
          {path : "/host-dashboard/listings/:id" , component : Listings , name: '/private'},
          {path : "/host-dashboard/listings/:id/:id" , component : Listings , name: '/private'},
          {path : "/hosting/managespace" , component : ManageSpace , name: '/private'},
          {path : "/hosting/managespace/photos" , component : Photos , name: '/private'},
          {path : "/hosting/managespace/amenities" , component : Amenities , name: '/private'},
          {path : "/account" , component : HostAccount , name: '/private'},
          {path : "/hostsubscribe" , component : HostSubscribe , name: '/private'},
          {path : "/guestsubscribe" , component : GuestSubscribe , name: '/private'},
          {path : "/hosting/managespace/rooms-and-spaces" , component : RoomsandSpace , name: '/private'},
          {path : "/host-dashboard/dispute/:id" , component : Dispute , name: '/private'},
          {path : "/host-dashboard/trips/stay/:id" , component : TripsStay , name: '/private'},
          {path : "/host-dashboard/trips/experience/:id" , component : TripsExp , name: '/private'},
          {path : "/host-dashboard/trips/cancellation/:id" , component : Cancellation , name: '/private'},
          {path : "/host-dashboard/trips/trippayment/:id" , component : TripPayment , name: '/private'},
          {path : "/Online" , component : Online , name: '/private'},
          {path : "/search" , component : Search , name: '/private'},
          {path : "/withdrawhistory" , component : WithdrawHistory , name: '/private'},
          {path : "/account/govtid" , component : AccGovtId , name: '/private'},
          {path : "/account/addid" , component : AccAddId , name: '/private'},
          {path : "/account/profile" , component : Profile , name: '/private'},
          {path : "/account/addbank" , component : AddBank , name: '/private'},
          {path : "/account/personal-info" , component : PersonalInfo , name: '/private'},
          {path : "/account/login-security" , component : LoginSecurity , name: '/private'},
          {path : "/account/payment" , component : Payment , name: '/private'},
          {path : "/account/privacyandsharing" , component : AccPrivacyShar , name: '/private'},
          {path : "account/globalperform" , component : AccGlobalPerform , name: '/private'},
          {path : "account/travelforwork" , component : AccTravelForWork , name: '/private'},
          {path : "account/hostingtools" , component : AccHostongTools , name: '/private'},
          {path : "account/guestrefferal" , component : AccGuestRefferal , name: '/private'},
          {path : "/pending-orders" , component : PendingOrders , name: '/private'},
          {path : "/wishlist" , component : Wishlist , name: '/private'},
          {path : "/host/property-type/placetype" , component : PlaceType , name: '/private'},
          {path : "/hosting/setrules" , component : SetRules , name: '/private'},
          {path : "/hosting/areaforguest" , component : AreaForGuest , name: '/private'},
          {path : "/hosting/areaforguest/doublecheck" , component : DoubleCheck , name: '/private'},
          {path : "/hosting/areaforguest/organizephotos" , component : OrganizePhotos , name: '/private'},
          {path : "/hosting/areaforguest/confirmavail" , component : ConfirmAvail , name: '/private'},
          {path : "/hosting/areaforguest/promotlist" , component : PromotList , name: '/private'},
          {path : "/hosting/areaforguest/cleaningfee" , component : CleaningFee , name: '/private'},
          {path : "/book-experience/:id" , component : BookExp , name: '/private'},
          {path : "/experience-details/:id" , component : Experiencehome , name: '/public'},
          {path : "/experience-edit/:id" , component : Experienceedit , name: '/private'},
          {path : "/notification" , component : Notification , name: '/private'},
          {path : "/expdetail" , component : Experiencedetail , name: '/public'},
          {path : "/contacthost/:id" , component : ContactHost , name: '/private'},
          {path : "/contacthost" , component : ContactHost , name: '/private'},
          {path : "/confirmandpay" , component : ConfirmandPay , name: '/private'},
          {path : "/wishlist" , component : Wishlist , name: '/private'},
          {path : "/payment-success/:id" , component : PaymentSuccess , name: '/private'},
          {path : "/experience-payment-success/:id" , component : ExperiencePaymentSuccess , name: '/private'},
          {path : "/onlineexp" , component : OnlineExp , name: '/private'},
          {path : "/maps" , component : Maps , name: '/private'},
          {path : "/landing-map" , component : FilterMap , name: '/public' , },
          {path : "/landing-map/filter/:id" , component : FilterMap , name: '/public'},
          {path : "contact-us" , component : Contactus ,name: '/public'},
          {path : "faq" , component : FAQ , name: '/public'},
          {path : "privacy-policy" , component : Privacypolicy , name: '/public'},
          {path : "terms-of-service" , component : Termsofservice , name: '/public'},
          {path : "about-us" , component : Aboutus , name: '/public'},
]

const commonRoutes =  Allroute.map(({path, component,name}, key) => name == '/public' && <Route exact path={path} component={component} key={key} name = {name} />)
const privateRoutes =  Allroute.map(({path, component,name}, key) => name == '/private' && <Route exact path={path} component={component} key={key} name = {name} />)
// .map(({path, component,name}, key) => <Route exact path={path}  component={component} key={key} name = {name} />)



function App() {

  const [email , setEmail] = useState(getUserEmail());
  
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
    Aos.refresh();
  }, []);

  useEffect(() => {
    setEmail(getUserEmail())
  },[])
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense
          fallback={
            <div class="spinner">
              <div />
              <div />
              <div />
              <div />
            </div>
          }
        >
          <Routes>
            {/* <Route path="*" render={() => <Navigate replace to={"/"} />} /> */}
            <Route path="*" element={<Navigate replace to={"/"} />} />
            <Route path="/host-experience/:id" element={email ?<ExperianceLand /> : <Navigate replace to={"/"} />} />
            <Route path="/manage-experience/:id" element={<ExperianceBase />} />
            
            <Route path="/" element={<Land />} />
            <Route index element={<Land />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/onlineexp" element={<OnlineExp />} />

            <Route path="/host/:id" element={email ? <PropertyType /> : <Navigate replace to={"/"} />} />
            <Route path="/room/:id" element={<Experiencedetail />} />
            <Route path="/roomedit/:id" element={email ? <Roomedit /> : <Navigate replace to={"/"} />} />


            
            <Route path="/confirm-and-pay/:id" element={email ? <ConfirmandPay stripe = {stripePromise}/> : <Navigate replace to={"/"} />} />

            <Route path="/host-dashboard/:id" element={email ? <StartHosting /> : <Navigate replace to={"/"} />} />
            <Route path="/host-dashboard/calendar/:id" element={email ? <Calendar /> : <Navigate replace to={"/"} />} />
            <Route path="/hosting/insights" element={email ? <Insights /> : <Navigate replace to={"/"} />} />
            <Route path="/host-dashboard/inbox/:id/:id" element={email ? <Inbox /> : <Navigate replace to={"/"} />} />
            <Route path="/host-dashboard/inbox/:id" element={<Inbox />} />
            <Route path="/hosting/calendar" element={email ? <Calendar /> : <Navigate replace to={"/"} />}  />

            <Route path="/host-dashboard/listings/:id" element={email ? <Listings /> : <Navigate replace to={"/"} />}  />
            <Route
              path="/host-dashboard/listings/:id/:id"
              element={<Listings />}
            />
            <Route path="/hosting/managespace" element={<ManageSpace />} />
            <Route path="/hosting/managespace/photos" element={<Photos />} />
            <Route
              path="/hosting/managespace/amenities"
              element={<Amenities />}
            />
            <Route path="/account" element={email ? <HostAccount /> : <Navigate replace to={"/"} />} />
            <Route path="/hostsubscribe" element={email ? <HostSubscribe stripe = {stripePromise}/> : <Navigate replace to={"/"} />} />
            <Route path="/guestsubscribe" element={email ? <GuestSubscribe stripe = {stripePromise}/> : <Navigate replace to={"/"} />} />
            <Route
              path="/hosting/managespace/rooms-and-spaces"
              element={<RoomsandSpace />}
            />
            <Route path="/host-dashboard/dispute/:id" element={email ? <Dispute /> : <Navigate replace to={"/"} />} />
            <Route
              path="/host-dashboard/trips/stay/:id"
              element={email ? <TripsStay /> : <Navigate replace to={"/"} />}
            />
            <Route
              path="/host-dashboard/trips/experience/:id"
              element={email ? <TripsExp stripe = {stripePromise}/> : <Navigate replace to={"/"} />}
            />
            <Route
              path="/host-dashboard/trips/cancellation/:id"
              element={email ? <Cancellation /> : <Navigate replace to={"/"} />}
            />
            <Route
              path="/host-dashboard/trips/trippayment/:id"
              element={email ? <TripPayment /> : <Navigate replace to={"/"} />}
            />

            {/* <Route path="/profile" element={<Profile />} /> */}

            {/* srya */}
            <Route path="/Online" element={<Online />} />
            <Route path="/search" element={<Search />} />
            <Route path="/withdrawhistory" element={email ? <WithdrawHistory /> : <Navigate replace to={"/"} />} />

            

            <Route path="/account/govtid" element={email ? <AccGovtId /> : <Navigate replace to={"/"} />} />
            <Route path="/account/addid" element={email ? <AccAddId /> : <Navigate replace to={"/"} />} />
            <Route path="/account/profile" element={email ? <Profile /> : <Navigate replace to={"/"} />} />
            <Route path="/account/addbank" element={email ? <AddBank /> : <Navigate replace to={"/"} />} />

            

            <Route path="/account/personal-info" element={email ? <PersonalInfo /> : <Navigate replace to={"/"} />} />
            <Route path="/account/login-security" element={<LoginSecurity />} />
            <Route path="/account/payment" element={email ? <Payment /> : <Navigate replace to={"/"} />} />
            {/* <Route path="/account/notification" element={<AccNotification />} /> */}
            <Route
              path="/account/privacyandsharing"
              element={<AccPrivacyShar />}
            />
            <Route
              path="account/globalperform"
              element={<AccGlobalPerform />}
            />
            <Route
              path="account/travelforwork"
              element={<AccTravelForWork />}
            />
            <Route path="account/hostingtools" element={<AccHostongTools />} />
            <Route
              path="account/guestrefferal"
              element={<AccGuestRefferal />}
            />
            <Route path="/pending-orders" element={email ? <PendingOrders /> : <Navigate replace to={"/"} />} />
            <Route path="/wishlist" element={email ? <Wishlist /> : <Navigate replace to={"/"} />} />
            {/* hosting steps */}
            <Route
              path="/host/property-type/placetype"
              element={email ? <PlaceType /> : <Navigate replace to={"/"} />}
            />

            {/* modal pagrs */}
            <Route path="/hosting/setrules" element={<SetRules />} />
            <Route path="/hosting/areaforguest" element={<AreaForGuest />} />
            <Route
              path="/hosting/areaforguest/doublecheck"
              element={<DoubleCheck />}
            />
            <Route
              path="/hosting/areaforguest/organizephotos"
              element={<OrganizePhotos />}
            />
            <Route
              path="/hosting/areaforguest/confirmavail"
              element={email ? <ConfirmAvail /> : <Navigate replace to={"/"} />}
            />
            <Route
              path="/hosting/areaforguest/promotlist"
              element={email ? <PromotList /> : <Navigate replace to={"/"} />}
            />
            <Route
              path="/hosting/areaforguest/cleaningfee"
              element={<CleaningFee />}
            />

            {/* book exp */}
            <Route path="/book-experience/:id" element={email ? <BookExp /> : <Navigate replace to={"/"} />} />
            <Route
              path="/experience-details/:id"
              element={<Experiencehome />}
            />

<Route
              path="/experience-edit/:id"
              element={email ? <Experienceedit /> : <Navigate replace to={"/"} />}
            />



            <Route path='/notification' element={email ? <Notification /> : <Navigate replace to={"/"} />} />
            <Route path="/expdetail" element={<Experiencedetail />} />
            <Route path="/contacthost/:id" element={email ? <ContactHost /> : <Navigate replace to={"/"} />} />
            <Route path="/contacthost" element={email ? <ContactHost /> : <Navigate replace to={"/"} />} />
            <Route path="/confirmandpay" element={email ? <ConfirmandPay /> : <Navigate replace to={"/"} />} />
            <Route path="/wishlist" element={email ? <Wishlist /> : <Navigate replace to={"/"} />} />

            <Route path="/payment-success/:id" element={<PaymentSuccess />} />
            <Route
              path="/experience-payment-success/:id"
              element={<ExperiencePaymentSuccess />}
            />

            {/* srya */}
            <Route path="/onlineexp" element={<OnlineExp />} />

            {/* krishna */}
            <Route path="/maps" element={<Maps />} />
            <Route path="/landing-map" element={<FilterMap />} />
            <Route path="/landing-map/filter/:id" element={<FilterMap />} />

            {/* Hariharan */}

            <Route path="contact-us" element={<Contactus />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="privacy-policy" element={<Privacypolicy />} />
            <Route path="terms-of-service" element={<Termsofservice />} />
            <Route path="about-us" element={<Aboutus />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
