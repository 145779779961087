import React, { useEffect, useState, useRef } from "react";
import GoogleMaps from "../Maps/GMaps.js";
import RoomMap from "../Maps/RoomMap";
import { ProgressBar, Button, Form, InputGroup } from "react-bootstrap";
import FilterExp from "./FilterExp.js";
import { FaPlay } from "react-icons/fa6";
import { BsFillPauseFill } from "react-icons/bs";
import { Freeze } from "react-freeze";
import MyMapComponent from "./MyMapComponent";

import detailimg1 from "../../Assets/images/detail/detailimg1.png";
import detailimg2 from "../../Assets/images/detail/detailimg2.png";
import detailimg3 from "../../Assets/images/detail/detailimg3.png";
import detailimg4 from "../../Assets/images/detail/detailimg4.png";
import detailimg5 from "../../Assets/images/detail/detailimg5.png";

import clock from "../../Assets/images/icons/new/clock.png";
import ball from "../../Assets/images/icons/new/ball.png";
import rain from "../../Assets/images/icons/new/rain.png";
import dot from "../../Assets/images/icons/new/dot.png";

import CoverModal from "../Common/Modals/CoverModal.js";
import PlaceOffer from "../Common/Modals/PlaceOffer.js";
import HealthSafety from "../Common/Modals/HealthSafety.js";
import AvailabilityModal from "./AvailabilityModal.js";

import AvailCont from "../Common/AvailCont.js";
import ShowAllImg from "../Common/Modals/ShowAllImg.js";

import wifi from "../../Assets/images/icons/icons/wifi.png";
import ac from "../../Assets/images/icons/icons/ac.png";
import tv from "../../Assets/images/icons/icons/tv.png";
import hairdryer from "../../Assets/images/icons/icons/hairdryer.png";
import food from "../../Assets/images/icons/icons/food.png";
import bed from "../../Assets/images/icons/icons/bed.png";

import Cancellation from "../Common/Modals/CancellationModal.js";

import pool from "../../Assets/images/icons/icons/pool.png";

import key from "../../Assets/images/key.png";
import card from "../../Assets/images/card.png";
import badge from "../../Assets/images/badge.png";

import prof from "../../Assets/images/prof.jpg";
import prof1 from "../../Assets/images/prof1.jpg";
import prof2 from "../../Assets/images/prof2.jpg";
import prof3 from "../../Assets/images/prof3.jpg";

import ReviewModal from "../Common/Modals/ReviewModal.js";
import {
  getAdminDetails,
  getOverAllRating,
  getRoomRating,
  getRoomUsingId,
} from "../../Hooks/useUserHostingHook.js";
import { API_URL, toastOptions } from "../../config/env.js";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  editroompricehooks,
  getHostDetails,
  getUserEmail,
  hostinstantbookingstatushooks,
  reserveRoom,
} from "../../Hooks/useUserHook.js";
import { getPrice } from "../../Hooks/useUserHook.js";
import { Link, useNavigate } from "react-router-dom";

import { toast, Toaster } from "react-hot-toast";
import Login from "../Common/Modals/LoginModal";
import GiveReviewModal from "../Common/Modals/GiveReviewModal.js";
import AddressModal from "../Common/Modals/AddressModal.js";
import { GOOGLE_MAP_API_KEY } from "../../config/env.js";
import HostHeader from "../BecomeHost/AfterHost/HostHeader.js";
import { Arrayofdate } from "../../config/Userhlpr.js";
import CustomFilterHeader from "../BecomeHost/AfterHost/CustomFilterHeader.js";
import GiveReview from "../Common/Modals/GiveReivew.js";

const Header = React.lazy(() => import("../Common/Header"));
const Footer = React.lazy(() => import("../Common/Footer"));

const Card = React.lazy(() => import("./Card"));

// const Datepicker = React.lazy(() => import('../Common/Datepicker/Datepicker.js'));

const Roomedit = (props, { shouldSuspendRendering }) => {
  const initialText =
    "We had great time with Akash! The meals we prepared were delicious and I'm hoping to be able to recreate them at home. Akash is very knowledgeable and a super likable guy. We had great time with Akash! The meals we prepared were delicious";
  const [theSpace, setTheSpace] = useState(initialText);
  const [showone, setShowone] = useState(false);
  const [showtwo, setShowtwo] = useState(false);
  const [showthree, setShowthree] = useState(false);
  const [showfour, setShowfour] = useState(false);
  const [readmore, setReadmore] = useState(false);

  const [cover, setCover] = useState(false);
  const [placeoffer, setPlaceOffer] = useState(false);
  const [healthsafety, setHealthSafety] = useState(false);
  const [avail, setAvail] = useState(false);
  const [showallimg, setShowAllImg] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [roomDetails, setRoomDetails] = useState({});
  // console.log("roomdetaisl", roomDetails);
  const [review, setReview] = useState(false);
  // console.log("review", review);
  const [bedCount, setBedCount] = useState(0);
  // console.log("bedcount", bedCount);
  const [bedRoomCount, setBedRoomCount] = useState(0);
  // console.log("bedRoomcount", bedRoomCount);
  const [startDate, setStartDate] = useState(null);
  console.log("startdate", startDate);
  const [endDate, setEndDate] = useState(null);
  console.log("endDate", endDate);
  const [rentAmount, setRentAmount] = useState(0);
  console.log("rentAmount", rentAmount);
  const [daysDiff, setDaysDiff] = useState(0);
  const [bookingDetails, setBookingDetails] = useState({});
  const [adminDetails, setAdminDetails] = useState({});
  const [email, setEmail] = useState("");
  console.log("email", email);
  const [isRefundable, setIsRefundable] = useState(false);
  const [guestCount, setGuestCount] = useState({});
  const [userRoomDatas, setUserRoomDatas] = useState({});
  const [roomId, setRoomId] = useState("");
  console.log("roomId", roomId);
  const [serviceFee, setServiceFee] = useState(0);
  const [serviceFeeTotal, setServiceFeeTotal] = useState(0);
  const [guest, setGuest] = useState(0);
  const [overAllRating, setOverAllRating] = useState({});
  console.log("overAllRating hihi", overAllRating);
  const [ratings, setRatings] = useState({});
  console.log("ratings hihi", ratings);
  const [hostDetails, setHostDetails] = useState({});
  console.log("hostDetails", hostDetails);
  const [showlog, setShowLog] = useState(false);
  const [position, setPosition] = useState({});
  console.log("postiotion", position);
  const [coordsLoaded, setCoordsLoaded] = useState(false);
  console.log("coordsLoaded", coordsLoaded);

  const [exludedates, setExcludedates] = useState([]);
  const [specialdates , setSpecialdates] = useState([]);
  const [price , setPrice] = useState("");
  const [disable , setDisable] = useState(false)

  const [isPlaying, setIsPlaying] = useState(false);
  const [min , setMin] = useState("");
  const [max , setMax] = useState("");
  const [instantbook , setInstantbook] = useState(false);
  const [admininstantbook , setAdminInstantbook] = useState(false);
  const videoRef = useRef(null);
  const VideoSrc = 'https://player.vimeo.com/progressive_redirect/playback/682048671/rendition/360p?loc=external&oauth2_token_id=57447761&signature=5be12c7ba41b540b1bb6e8ff3cb8f859e9381ece088946bb8257fe3da7201f79'



  const cancellationPolicy = (data) => {
    setIsRefundable(data);
  };
  const guestcount = (val) => {
    // props.onSetGuest(guest);
    setGuestCount(val);
  };

  const bookRoom = async () => {
    const data = {
      email: email,
      roomId: roomDetails._id,
      hostEmail: roomDetails.email,
      roomTitle: roomDetails.title,
      checkInDate: startDate,
      checkOutDate: endDate,
      totalAmount: parseInt(rentAmount) + parseInt(serviceFee),
      serviceFeeAmount: parseInt(serviceFee),
      nights: daysDiff,
      isVerified: false,
      guestCount: guestCount,
      isRefundable: isRefundable,
      accommodationAddress: roomDetails.location,
    };
    console.log("bookroom", data);
    if (startDate && endDate && guestCount && rentAmount && daysDiff) {
      await reserveRoom(data).then((val) => {
        console.log("data from reserve room hook :", val);
      });
    } else {
      toast.error("complete all details to reserve!", toastOptions);
    }
  };

  
  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      setTimeout(()=>
      {
        document.getElementById("play_pause_btn").style.opacity = 0;
      }, 1000);
      videoRef.current.play();
      setIsPlaying(true);
    } else {
     
        document.getElementById("play_pause_btn").style.opacity = 1;
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const getHostingId = () => {
    const id = window.location.pathname.split("/")[2];
    console.log(
      "window.location.pathname",
      window.location.pathname.split("/")[2]
    );
    setRoomId(id);
    getRoomUsingId(id).then((data) => {
      console.log("getRoomUsingId in experience details page", data);
      const bedrooms = data.floorPlan.find((data) => {
        return data ? data.type == "bedrooms" : "";
      });
      console.log("bedroom count:", bedrooms);
      setBedRoomCount(bedrooms.count);
      const guest = data.floorPlan.find((data) => {
        return data.type == "guests";
      });
      // console.log("guest details" , guest.count);
      const beds = data.floorPlan.find((data) => {
        return data ? data.type == "beds" : "";
      });
      console.log("bed count:", beds?.count);
      setBedCount(beds?.count);
      setRoomDetails(data);
      setPrice(data?.price);
      setMin(data?.mindays);
      setMax(data?.maxdays);
      setInstantbook(data?.instantBooking);
      setAdminInstantbook(data?.instantBookingadmin);
      let exclued = Arrayofdate(data?.bookedDates).then((data) => {
        setExcludedates(data);
      });
      getHostDetails(data.email).then((data) => {
        console.log("dataSSSSSSSSSSSSSS", data);
        setHostDetails(data.data);
      });
    });
    getOverAllRating(id).then((data) => {
      setOverAllRating(data.data);
    });
    getRoomRating(id).then((data) => {
      setRatings(data.data);
    });
  };

  const handlechangeprice = async() => {
    setDisable(true);
    if(isNaN(parseFloat(price)) || parseFloat(price) < 0){
      toast.error("Invalid price value");
    }
    else{
      let payload = {
        id : roomId,
        price : price,
        ref : "price"
      }
      let edit = await editroompricehooks(payload);
      toast.success("successfully updated!")
      getHostingId();
    }
    setDisable(false);
  }

  const handleminmax = async() => {
    setDisable(true);
    if(isNaN(parseFloat(price)) || parseFloat(price) < 0){
      toast.error("Invalid price value");
    }
    else{
      let payload = {
        id : roomId,
        min : min,
        max : max,
        ref : "minmax"
      }
      let edit = await editroompricehooks(payload);
      toast.success("successfully updated!")
      getHostingId();
    }
    setDisable(false);
  }

  const addressFunc = (data) => {
    console.log(data);
  };

  // const getadmindetails = () => {
  //     getAdminDetails().then((data) => {
  //         console.log("admin details :", data);
  //         setAdminDetails(data[0]);
  //         setServiceFee(data[0].serviceFee);
  //     })
  // }

  const getData = () => {
    getadmindetails();
  };

  const getadmindetails = () => {
    getAdminDetails().then((data) => {
      console.log("admin details :", data);
      setAdminDetails(data);
      const serviceFeePercentage = data.filter((e) => e.name == "servicefee");
      console.log("serviceFeePercentage", serviceFeePercentage[0]);
      setServiceFee(serviceFeePercentage[0]?.percentage);
    });
  };

  const handleDateChange = (date) => {
    console.log("handle date change :", date, new Date(date));
    // initial change: start by setting the startDate
    if (!startDate && !endDate) {
      let nwdate = date?.setHours(new Date(roomDetails?.checkintime).getHours() , new Date(roomDetails?.checkintime).getMinutes(),0)
      setStartDate(nwdate);
      // startDate has been set, set the end date
    } else if (startDate && !endDate && startDate < date) {
      let nwdate = date?.setHours(new Date(roomDetails?.checkouttime).getHours() , new Date(roomDetails?.checkouttime).getMinutes(),0)
      setEndDate(nwdate);
    }

    if (startDate && startDate > date) {
      setStartDate(null);
      setEndDate(null);
    }

    // user is choosing another range => set the start date
    // and set the endDate back to null
    if (startDate && endDate) {
      setStartDate(date);
      setEndDate(null);
    }
  };

  const contacthost = () => {
    if (email) {
      let roomdatas = {
        id: roomId,
        email: email,
        firstName: hostDetails.firstName,
        lastName: hostDetails.lastName,
        responseTime: hostDetails.responseTime,
        profilePicName: hostDetails.profilePicName,
        profilePicPath: hostDetails.profilePicPath,
        hostEmail: roomDetails.hostEmail,
        serviceFee: parseInt(serviceFee),
        rentAmount: rentAmount,
        days: daysDiff,
        amountPerNight: roomDetails.price,
        startDate: startDate,
        endDate: endDate,
      };
      // roomdatas = JSON.stringify(roomdatas);
      console.log("roomDatas :", roomdatas);
      localStorage.setItem("contactDatas", JSON.stringify(roomdatas));
      window.location.href =
        window.location.origin + "/contacthost/" + roomDetails?.email;
    } else {
      setShowLog(true);
    }
  };

  const getCoordinates = async (address) => {
    try{
      console.log("address in geocoding :", address);
    await fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      address +
      "&key=" +
      GOOGLE_MAP_API_KEY
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results[0]) {
          const latitude = data.results[0].geometry.location.lat;
          const longitude = data.results[0].geometry.location.lng;
          console.log(
            "complete data from geocoding fetch",
            data,
            "lat lng from room address",
            { latitude, longitude }
          );
          setPosition({ lat: latitude, lng: longitude });
          setCoordsLoaded(true);
        }
      });
    }
    catch(e){
      console.log("error on map" , e);
    }
  };

  useEffect(() => {
    if (roomDetails) {
      const address =
        roomDetails.location?.street +
        "," +
        roomDetails.location?.area +
        "," +
        roomDetails.location?.city +
        "," +
        roomDetails.location?.state;
      getCoordinates(address);
    }
  }, [roomDetails]);

  useEffect(() => {
    // console.log("props data" , roomDetails.price);
    const mail = getUserEmail();
    setEmail(mail);

    getHostingId();
    getData();
  }, [showlog]);

  useEffect(() => {
    if (startDate && endDate) {
      console.log("startDate && endDate", startDate, endDate);
      let daydiff = new Date(endDate).getTime() - new Date(startDate).getTime();
      daydiff = Math.ceil(daydiff / (1000 * 3600 * 24));
      console.log("daydiff>>>>>>>>>>>", startDate, endDate);
      let data = {
        roomId: roomId,
        startDate: startDate,
        endDate: endDate,
      };
      let formData = new FormData();
      formData.append("roomId", roomId);
      formData.append("startDate", new Date(startDate).getTime());
      formData.append("endDate", new Date(endDate).getTime());
      let roomprice;
      getPrice(formData).then((data) => {
        console.log("fasdfjasdgfjhasd", data);
        let spl = data?.specialdates?.length > 0 ? data?.specialdates : [];
        setSpecialdates(spl);
        if (data.status) {
          roomprice = parseInt(data.price);
        } else {
          console.log("enter this", roomDetails.price);
          roomprice = parseInt(roomDetails.price);
        }
        console.log("roompricedata", roomprice);
        const rent = parseInt(data.price)//daydiff * roomprice;
        console.log(
          "daydiff :",
          daydiff,
          " parseInt(roomDetails.price)",
          roomDetails.price,
          "rent",
          rent
        );
        setDaysDiff(daydiff);

        // setServiceFee(serviceFee*daydiff);
        // const fee = (serviceFee / 100) * rent;
        var fee = 0;
        adminDetails?.map((data, i) => {
          let singlefee = parseFloat(data?.percentage / 100) * rent;
          fee = fee + singlefee;
        });
        setServiceFeeTotal(fee);
        setRentAmount(rent);
      });
      console.log("rooMdaetalssssss", roomDetails.legal);
    }
  }, [startDate, endDate]);

  // useEffect(()=>{

  //     navigator.geolocation.getCurrentPosition((location) => {

  //         setCoordsLoaded(true);
  //         console.log("current location :",location.coords.latitude,location.coords.longitude);
  //         const pos = {lat:location.coords.latitude,lng:location.coords.longitude}
  //         setPosition(pos);

  //     })

  //   },[]);

  const [givereview, setGiveReview] = useState(false);

  return (
    <div className="expdetails">
      {avail && (
        <AvailabilityModal
          id={roomId}
          email={email}
          roomDetails={roomDetails}
          onSetGuestCount={guestcount}
          onSetCancellation={cancellationPolicy}
          serviceFee={parseInt(serviceFee)}
          rentAmount={rentAmount}
          days={daysDiff}
          amountPerNight={roomDetails.price}
          startDate={startDate}
          endDate={endDate}
          onDismiss={() => setAvail(false)}

          spl = {specialdates}
        />
      )}
      {showlog && <Login onDismiss={() => setShowLog(false)} />}
      {cover && <CoverModal onDismiss={() => setCover(false)} />}
      {placeoffer && (
        <PlaceOffer
          data={roomDetails.amenities}
          onDismiss={() => setPlaceOffer(false)}
        />
      )}
      {healthsafety && (
        <HealthSafety onDismiss={() => setHealthSafety(false)} />
      )}

      {showallimg && (
        <ShowAllImg
          photos={roomDetails.photos}
          photosPath={roomDetails.photosPath}
          onDismiss={() => setShowAllImg(false)}
        />
      )}

      {cancel && <Cancellation onDismiss={() => setCancel(false)} />}

      {review && (
        <ReviewModal
          ratings={ratings}
          reviewDatas={roomDetails.review}
          overAllRating={overAllRating}
          onDismiss={() => setReview(false)}
        />
      )}
      {givereview && <GiveReview roomid = {roomDetails?._id} onDismiss={() => setGiveReview(false)} />}
      {/* <Header /> */}

      <div className="" style={{ marginTop: "0px" }}>
        {/* <HostHeader /> */}
        <CustomFilterHeader/>
      </div>

      <div className="detail_ful">

        <div className="mobileresevebtn">
          <button
            className="btn btn-theme"
            onClick={() => {
              setAvail(true);
            }}
          >
            Reserve
          </button>
        </div>
        <div className="container container_custom">
          <div className="detail_header">
            <p className="detail_heading">{roomDetails.title}</p>

            <p className="mt-2 detail_desc">
              {/* <span className='fa fa-star full_star me-1'></span> */}
              {/* <span className='me-1'>5.0 (19).</span> */}
              <span className="me-1">
                <h5 style={{ fontFamily: "serif" }}>
                  {roomDetails &&
                    roomDetails?.location &&
                    roomDetails?.location?.city}
                  ,{" "}
                  {roomDetails &&
                    roomDetails?.location &&
                    roomDetails?.location?.countrycode?.split("(")[0]}{" "}
                  .
                </h5>
              </span>
              {/* <span>Part of the<a href="#" className='ms-1'>Spare Resort Cooking collection</a></span> */}
            </p>
          </div>
          <div className="detail_images mt-3">
            <div className="row">
              <div className="col-12 col-md-3 px-1 showinthis767">
                <img
                  src={
                    roomDetails.photos &&
                    `${API_URL}${roomDetails?.photosPath}/${roomDetails?.photos[0]?.name
                    }`
                  }
                  className="img-fluid img_full_height"
                />
              </div>
              <div className="col-12 col-md-3 px-1">
                <img
                  src={
                    roomDetails.photos &&
                    `${API_URL}${roomDetails?.photosPath}/${roomDetails?.photos[1]?.name
                    }`
                  }
                  className="img-fluid img_full_height"
                />
              </div>
              <div className="col-12 col-md-3">
                <div className="row">
                  <div className="col-12 mb-2 px-1">
                    <img
                      src={
                        roomDetails?.photos &&
                        `${API_URL}${roomDetails?.photosPath}/${roomDetails?.photos[2]?.name
                        }`
                      }
                      className="img-fluid img_half_height"
                    />
                  </div>
                  <div className="col-12 px-1">
                    <img
                      src={
                        roomDetails?.photos &&
                        `${API_URL}${roomDetails?.photosPath}/${roomDetails?.photos[3]?.name
                        }`
                      }
                      className="img-fluid img_half_height"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3 px-1">
                <img
                  src={
                    roomDetails.photos &&
                    `${API_URL}${roomDetails?.photosPath}/${roomDetails?.photos[4]?.name
                    }`
                  }
                  className="img-fluid img_full_height"
                />
              </div>
            </div>
            <button
              className="showallimg show-all-img-btn"
              onClick={() => setShowAllImg(true)}
            >
              Show all images
            </button>
          </div>
          <div className="row mt-4">
            <div className="col-12 col-md-12 leftcont">
              <div className="d-flex">
                <div className="flex-grow-1 me-3">
                  <p className="detail_heading">
                    {roomDetails?.privacyType} hosted by {hostDetails?.firstName}{" "}
                    {hostDetails?.lastName}
                  </p>

                  {/* <p>5 hours. Hosted in English</p> */}
                </div>
                <div className="flex-shrink-0">
                  <img
                    src={
                      hostDetails && hostDetails?.profilePicturePath
                        ? API_URL + "/" + hostDetails?.profilePicturePath
                        : prof
                    }
                    width="56"
                    height="56"
                    alt="Sample Image"
                    className="prof_img_round"
                  />
                </div>
              </div>
              <hr />
              <div className="detail_content py-2">
                <div className="d-flex mb-3">
                  <div className="flex-shrink-0">
                    <img src={card} className="desc_icon" alt="Sample Image" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="name themeclr fw-600 f-14">Self check-in</p>
                    <p className="detail_desc_grey">
                      Check yourself in with the lockbox.
                    </p>
                  </div>
                </div>
                {/* <div className="d-flex mb-3">
                  <div className="flex-shrink-0">
                    <img src={badge} className="desc_icon" alt="Sample Image" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="name themeclr fw-600 f-14">
                      Muthukumar is a Superhost
                    </p>
                    <p className="detail_desc_grey">
                      Superhosts are experienced, highly rated hosts who are
                      committed to providing great stays for their guests.
                    </p>
                  </div>
                </div> */}
                {/* <div className="d-flex mb-3">
                  <div className="flex-shrink-0">
                    <img src={key} className="desc_icon" alt="Sample Image" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="name themeclr fw-600 f-14">
                      Great check-in experience
                    </p>
                    <p className="detail_desc_grey">
                      Check yourself in with the lockbox.
                    </p>
                  </div>
                </div> */}
              </div>
              <hr />
              {/* <p className="name themeclr fw-600 f-16 mb-2">What you'll do</p>
              <p className="detail_desc_grey mb-2">
                Every booking includes free protection from Host cancellations,
                listing inaccuracies, and other issues like trouble checking in.
              </p> */}
              {/* <p className="mb-3">
                {" "}
                <Button
                  onClick={() => setCover(true)}
                  variant="link"
                  className="link_blue_border ps-0"
                >
                  Learn more
                </Button>
              </p> */}
              <p className="name themeclr fw-600 f-16 mb-2">Description</p>
              <p className="detail_desc_grey mb-3">
                {roomDetails?.descriptionDetails}
              </p>
              {/* <p className="themeclr f-14 mb-2 fw-600">The space</p> */}
              {/* <p className='detail_desc_grey mb-2'>Fully furnished and neatly maintained with gas stove and cooking utensils...</p> */}
              {/* <p className="detail_desc_grey">
                {showone ? theSpace : theSpace.substring(0, 150)}
              </p>
              <p className="mb-3">
                <a
                  href="javascript:void(0)"
                  className="link_blue_border"
                  onClick={() => setShowone(!showone)}
                >
                  {showone ? "Show Less" : "Show More"}
                </a>
              </p>{" "} */}
              <hr />
              <p className="name themeclr fw-600 f-16 mb-2">
                Where you'll sleep
              </p>
              <div className="row mt-4 pb-3">
                <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                  <div className="card card_sleep">
                    <div className="card-body">
                      <img src={bed} className="desc_icon" alt="Sample Image" />

                      {/* {console.log(roomDetails.floorPlan.find((data)=>{return data.type=='bedrooms'}))} */}
                      <p className="name themeclr fw-600 f-16 mb-2 mt-3">
                        {bedRoomCount && bedRoomCount} Bed room
                      </p>
                      <p className="detail_desc_grey f-12">
                        {bedCount && bedCount} double bed
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <p className="name themeclr fw-600 f-16 mb-2">
                What this place offers
              </p>
              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  <div className="row">
                    {roomDetails.amenities &&
                      roomDetails.amenities.map((data, index) => (
                        <>
                          {index < 4 ? (
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-4">
                              <div className="d-flex align-items-center">
                                <img
                                  src={`${API_URL}${roomDetails.amenities[
                                    roomDetails.amenities.length - 1
                                  ].path
                                    }/${data.image}`}
                                  className="place_icon"
                                  alt=""
                                />
                                <span className="ms-2 detail_desc_grey f-12">
                                  {data.type}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              </div>

              
              <div className="pb-3">
                <button
                  type="button"
                  className="btn btn-theme filterbtn"
                  onClick={() => setPlaceOffer(true)}
                >
                  Show all{" "}
                  {roomDetails.amenities && roomDetails.amenities.length - 1}{" "}
                  amenities
                </button>
              </div>
              <hr />

              <p className="name themeclr fw-600 f-16 mb-2">
                Price
              </p>
              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  {/* {userData?.address ? userData?.address?.street+" "+userData?.address?.flat+" "+userData?.address?.city+" "+userData?.address?.stateprovince+" "+userData?.address?.postcode+" "+userData?.address?.country : userData?.address} */}
                  <input
                    type="text"
                    placeholder="Price"
                    className="form-control mb-4 input_big_ne"
                    value={price}
                    onChange = {(e)=>setPrice(e?.target?.value)}
                  />
                </div>
              </div>
             
              
              <div className="pb-3">
                <button
                  type="button"
                  className="btn btn-theme filterbtn"
                  onClick={()=>handlechangeprice()}
                  disable = {disable}
                >
                  Save
                 
                </button>
              </div>

             {admininstantbook && <>
              <p className="name themeclr fw-600 f-16 mb-2">
                Instant Booking
              </p>
              <div className="row mt-4">
                <div className="col-12 col-md-6 fomt_inst_book">
                  <Form.Check
                    type="switch"
                    // value = {instantbook}
                    checked = {instantbook}
                    onChange={async(e) => {
                      console.log("e.target.checked", e.target.checked);
                      // admininstantbooking(e.target.checked);
                      let payload = {id : window.location.pathname.split("/")[2] , instantbooking : e.target.checked}
                      let update = await hostinstantbookingstatushooks(payload);
                      setInstantbook(e.target.checked)
                      getHostingId();
                    }}
                    id="custom-switch"
                    className="ps-0 align-items-center"
                    label="Instant Booking"
                  />
                </div>
              </div>
              </>}
              <hr />

              {/* <p className="name themeclr fw-600 f-16 mb-2">
                Minimum Days
              </p>
              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  <input
                    type="text"
                    placeholder="Minimum Days"
                    className="form-control mb-4 input_big_ne"
                    value={min}
                    onChange = {(e)=>setMin(e?.target?.value)}
                  />
                </div>
                <p className="name themeclr fw-600 f-16 mb-2">
                Maximum Days
              </p>
                <div className="col-12 col-md-6">
                  <input
                    type="text"
                    placeholder="Maximum Days"
                    className="form-control mb-4 input_big_ne"
                    value={max}
                    onChange = {(e)=>setMax(e?.target?.value)}
                  />
                </div>
              </div>

              
              <div className="pb-3">
                <button
                  type="button"
                  className="btn btn-theme filterbtn"
                  onClick={()=>handleminmax()}
                  disable = {disable}
                >
                  Save
                 
                </button>
              </div> */}

              <div className='row withstat'>
                                                <>
                                               {roomDetails?.bookingInfo  ?.length > 0 && roomDetails?.bookingInfo?.map((data , i) =>
                                               <div className='cols col-xl-4 col-lg-6 mb-3'>

                                               <div className='shadowbox p-4 br-5'> 
                                       <div className='d-flex align-items-center'>
                                           {/* <img src={prof} className="prof" /> */}
                                           <h6 className='mb-3'><b>Booking Details</b></h6>

                                       </div>
                                       <div className='graybox br-0 mt-0 py-3 mobi_fle_ger'>
                                     
                                        

                                          
                                           <div className='d-flex jc-between'>

                                               <h6 className='mb-3 f-14'><p className='themeclr'>Check In</p> 
                                               <p className='text-muted'>{new Date(data?.checkInDate).toLocaleDateString()}</p></h6>
                                           </div>
                                           

                                           <div className='d-flex jc-between'>

<h6 className='mb-3 f-14'><p className='themeclr'>CheckOut</p> 
<p className='text-muted'>{new Date(data?.checkOutDate).toLocaleDateString()}</p></h6>
</div>

<div className='d-flex jc-between'>

<h6 className='mb-3 f-14'><p className='themeclr'>Type</p> 
<p className='text-muted'>Room {data?.type == "Blocked by roomHoster" ? "blocked" : "booked"}</p></h6>
</div>

<div className='d-flex jc-between'>

{data?.type != "Blocked by roomHoster" &&<h6 className='mb-0 f-14'><p className='themeclr'>E-mail ID</p> 
<p className='text-muted'>{data?.email}</p></h6>}
</div>
                                       </div>
                                       
                                   </div>


                                              
                                           </div> 
                                               )}

                                            
                                            </>
                                                
                                            </div>

              <hr />

              {roomDetails?.photos && roomDetails?.photos[roomDetails?.photos?.length-1]?.name?.split(".")[1]=="mp4" &&<div className="video_div">
              <div className='position-relative videsec_rela'>
{console.log("video" , roomDetails)}
<video
  ref={videoRef}
  src={roomDetails ?
    `${API_URL}${roomDetails && roomDetails?.photosPath && roomDetails?.photosPath}/${roomDetails?.photos?.length > 0 && roomDetails?.photos[roomDetails?.photos?.length-1]?.name}` : ""}
  // controls
  width='100%'
/>
<div className='position-absolute video-bg-btn'>
  <button onClick={togglePlayPause} id="play_pause_btn">
    {isPlaying ? <BsFillPauseFill className='text-white pause-icon' /> : <FaPlay className='play-icon' />}
  </button>
</div>
</div>
              </div>}
              <hr />

        
            </div>
            
          </div>
          <hr />

          {/* -------------------------------------------------- */}

          <div>
            <div className="d-flex justify-content-between">
              <p className="name themeclr fw-600 f-20 d-flex align-items-center">
                <span className="fa fa-star full_star me-1" />
                <span className="me-1">
                  {overAllRating &&
                    overAllRating.overAllReview &&
                    overAllRating.overAllReview.toFixed(1)}{" "}
                  ({overAllRating && overAllRating.users} reviews)
                </span>
              </p>
              {/* && !roomDetails?.review?.find(e => e?.email == email) */}
              {(roomDetails?.bookingInfo?.find(e=> e?.email == email) && roomDetails?.email != email)&&<button className="btn btn-theme" onClick={() => setGiveReview(true)}>Add review</button>}
            </div>
            <div className="row mt-4 reviews_row pb-5">

              <div className="col-12 col-md-5">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="reviews_text">Cleanliness</p>
                  <div className="d-flex align-items-center">
                    <ProgressBar
                      now={
                        ratings &&
                        ratings.cleanliness &&
                        ratings.cleanliness * 10 * 2
                      }
                      className="progress_review"
                    />
                    <span className="ms-2">
                      {ratings && ratings.cleanliness
                        ? ratings.cleanliness.toFixed(1)
                        : 0}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="reviews_text">Communication</p>
                  <div className="d-flex align-items-center">
                    <ProgressBar
                      now={
                        ratings &&
                        ratings.communication &&
                        ratings.communication * 10 * 2
                      }
                      className="progress_review"
                    />
                    <span className="ms-2">
                      {ratings && ratings.communication
                        ? ratings.communication.toFixed(1)
                        : 0}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="reviews_text">Check-in</p>
                  <div className="d-flex align-items-center">
                    <ProgressBar
                      now={
                        ratings && ratings.checkIn && ratings.checkIn * 10 * 2
                      }
                      className="progress_review"
                    />
                    <span className="ms-2">
                      {ratings && ratings.checkIn
                        ? ratings.checkIn.toFixed(1)
                        : 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5 offset-md-2">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="reviews_text">Accuracy</p>
                  <div className="d-flex align-items-center">
                    <ProgressBar
                      now={
                        ratings && ratings.accuracy && ratings.accuracy * 10 * 2
                      }
                      className="progress_review"
                    />
                    <span className="ms-2">
                      {ratings && ratings.accuracy
                        ? ratings.accuracy.toFixed(1)
                        : 0}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="reviews_text">Location</p>
                  <div className="d-flex align-items-center">
                    <ProgressBar
                      now={
                        ratings && ratings.location && ratings.location * 10 * 2
                      }
                      className="progress_review"
                    />
                    <span className="ms-2">
                      {ratings && ratings.location
                        ? ratings.location.toFixed(1)
                        : 0}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="reviews_text">Value</p>
                  <div className="d-flex align-items-center">
                    <ProgressBar
                      now={ratings && ratings.value && ratings.value * 10 * 2}
                      className="progress_review"
                    />
                    <span className="ms-2">
                      {ratings && ratings.value ? ratings.value.toFixed(1) : 0}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="row">
                  {roomDetails &&
                    roomDetails.review &&
                    roomDetails.review.map((value, index) =>
                      index < 4 ? (
                        <>
                          <div className="col-12 col-md-6 mb-3">
                            <div className="d-flex mb-3">
                              <div className="flex-shrink-0">
                                <img
                                  src={
                                    hostDetails &&
                                      hostDetails.profilePicturePath
                                      ? API_URL +
                                      "/" +
                                      hostDetails.profilePicturePath
                                      : prof
                                  }
                                  className="desc_icon"
                                  alt="Sample Image"
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p className="name themeclr fw-600 f-14">
                                  {value.name}
                                </p>
                                <p className="detail_desc_grey">
                                  {new Date(value.date).toLocaleDateString()}
                                </p>
                              </div>
                            </div>
                            <p className="detail_desc_grey">
                              {/* {showone?value.description:(value.description).substring(0,150)} */}
                              {value.description}
                            </p>
                            {/* <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={()=>setShowone(!showone)}>
                    {showone?"Show Less":"Show More"}
                        </a></p> */}
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    )}

                  {/* <div className='col-12 col-md-6 mb-3'>
                                <div className="d-flex mb-3">
                    <div className="flex-shrink-0">
                        <img src={prof1} className="desc_icon" alt="Sample Image" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <p className='name themeclr fw-600 f-14'>Charlotte</p>
                        <p className='detail_desc_grey'>June 2022</p>
                    </div>
                    </div>
                    <p className='detail_desc_grey'>
                        {showtwo?text:text.substring(0,150)}
                        </p>
                    <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={()=>setShowtwo(!showtwo)}>
                    {showtwo?"Show Less":"Show More"}
                        </a></p>
                    
                                </div>
                                <div className='col-12 col-md-6 mb-3'>
                                <div className="d-flex mb-3">
                    <div className="flex-shrink-0">
                        <img src={prof2} className="desc_icon" alt="Sample Image" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <p className='name themeclr fw-600 f-14'>Charlotte</p>
                        <p className='detail_desc_grey'>June 2022</p>
                    </div>
                    </div>
                    <p className='detail_desc_grey'>
                        {showthree?text:text.substring(0,150)}
                        </p>
                    <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={()=>setShowthree(!showthree)}>
                    {showthree?"Show Less":"Show More"}
                        </a></p>
                    
                    
                                </div>
                                <div className='col-12 col-md-6 mb-3'>
                                <div className="d-flex mb-3">
                    <div className="flex-shrink-0">
                        <img src={prof3} className="desc_icon" alt="Sample Image" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <p className='name themeclr fw-600 f-14'>Charlotte</p>
                        <p className='detail_desc_grey'>June 2022</p>
                    </div>
                    </div>
                    <p className='detail_desc_grey'>
                        {showfour?text:text.substring(0,150)}
                        </p>
                    <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={()=>setShowfour(!showfour)}>
                    {showfour?"Show Less":"Show More"}
                        </a></p>
                    
                                </div> */}
                  <div className="pb-3">
                    {roomDetails &&
                      roomDetails.review &&
                      roomDetails.review.length >= 2 ? (
                      <button
                        type="button"
                        className="btn btn-theme filterbtn"
                        onClick={() => setReview(true)}
                      >
                        Show all{" "}
                        {roomDetails && roomDetails.review
                          ? roomDetails.review.length
                          : 0}{" "}
                        reviews
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <hr />

              <div>
                <p className="name themeclr fw-600 f-16 mb-2 pt-4 pb-4">
                  What's includes
                </p>

                {coordsLoaded && (
                  <Freeze freeze={shouldSuspendRendering}>
                    {console.log(
                      "shouldSuspendRenderingshouldSuspendRendering",
                      shouldSuspendRendering
                    )}
                    <div className="mapComponent map_detail">
                      {/* <GoogleMaps position={position} onGetAddress={(val)=>addressFunc(val)} /> */}
                      <RoomMap position={position} />
                    </div>
                  </Freeze>
                )}

                <p className="detail_desc_grey py-4">
                  We will meet at my house and will cook in a well equiped
                  kitchen.
                  <br />
                  I would like to show you a special local market later after
                  the workshop.
                  <br />
                  To reach my place its best to use Uber or Ola taxi app its
                  very cost effective and convenient.
                </p>
                <hr />

                <div className="row pb-5">
                  <div className="col-12 col-md-7">
                    <div className="d-flex mb-3">
                      <div className="flex-shrink-0">
                        <img
                          src={
                            hostDetails && hostDetails.profilePicturePath
                              ? API_URL + "/" + hostDetails.profilePicturePath
                              : prof
                          }
                          className="desc_icon"
                          alt="Sample Image"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className="name themeclr fw-600 f-14">
                          {hostDetails.firstName}&nbsp;{hostDetails.lastName}
                        </p>
                        <p className="detail_desc_grey f-12 pb-1">
                          Host on FlutterPads since{" "}
                          {hostDetails &&
                            hostDetails.hostSince &&
                            new Date(hostDetails.hostSince).getFullYear()}
                        </p>
                        {/* <p className="detail_desc_grey f-12">
                          <span className="fa fa-star" />
                          <span className="ms-2">
                            {hostDetails &&
                              hostDetails.review &&
                              hostDetails.review.length}{" "}
                            Review(s)
                          </span>
                          {hostDetails && hostDetails.isIdentityVerified && (
                            <>
                              <span className="fa fa fa-shield ms-3" />
                              <span className="ms-2">Identity verified</span>
                            </>
                          )}
                        </p> */}
                      </div>
                    </div>
                    <p className="detail_desc_grey f-12 pb-1">
                      {hostDetails && hostDetails.bio}
                    </p>
                  </div>
                  <div className="col-md-5">
                    <p className="detail_desc_grey f-12 pb-1">
                      Language :
                      <span>
                        {hostDetails &&
                          hostDetails.languagesKnown &&
                          hostDetails.languagesKnown.join()}
                      </span>
                    </p>
                    <p className="detail_desc_grey f-12 pb-1">
                      Response rate :{" "}
                      <span>
                        {hostDetails && hostDetails.responseRate
                          ? `${hostDetails.responseRate}%`
                          : `0%`}
                      </span>
                    </p>
                    <p className="detail_desc_grey f-12 pb-1">
                      Response time :{" "}
                      <span>{hostDetails && hostDetails.responseTime}</span>
                    </p>

                    <Button
                      onClick={contacthost}
                      disabled={startDate == null || endDate == null}
                      className="btn-theme my-4 contact-host-btn"
                    >
                      Contact host
                    </Button>

                    <p className="detail_desc_grey f-12 pb-1">
                      To protect your payment, never transfer or communicate
                      outside of the FlutterPads website or app{" "}
                    </p>
                  </div>
                </div>

                <hr />
                <div className="pb-4">
                  <p className="name themeclr fw-600 f-16 mb-2 pt-4 pb-4 ">
                    Things to know
                  </p>
                  <div className="row thingstoknow">
                    <div className="col-md-4 mt-md-0 mt-3">
                      <p className="name themeclr fw-600 f-16 mb-2">
                        House rules
                      </p>
                      <p className="detail_desc_grey f-16 pb-1">
                        <img src={clock} /> Check-in: After 3.00 pm
                      </p>
                    </div>
                    <div className="col-md-4 mt-md-0 mt-3">
                      <p className="name themeclr fw-600 f-16 mb-2">
                        Health and safety
                      </p>

                      <p className="detail_desc_grey f-16 pb-1">
                        <img src={ball} />
                        COVID-19 safety practices apply
                      </p>
                      <p className="detail_desc_grey f-16 pb-1">
                        <img src={rain} />
                        Corbon monoxide alarm
                      </p>
                      <p className="detail_desc_grey f-16 pb-1">
                        <img src={dot} />
                        Smoke alarm
                      </p>
                      <Button
                        onClick={() => setHealthSafety(true)}
                        variant="link"
                        className="link_blue_border ps-0"
                      >
                        Show more
                      </Button>
                    </div>
                    <div className="col-md-4 mt-md-0 mt-3">
                      <p className="name themeclr fw-600 f-16 mb-2">
                        Cancellation Policy
                      </p>
                      <p className="detail_desc_grey f-16 pb-1">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Ab non facere incidunt assumenda amet perferendis
                        quasi, consequatur distinctio voluptates voluptatibus
                        quas eius. Quidem consequuntur nulla harum rem odit
                        officia a.
                      </p>
                      <Button
                        variant="link"
                        className="link_blue_border ps-0"
                        onClick={() => setCancel(true)}
                      >
                        Show more
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Toaster />
    </div>
  );
};

export default Roomedit;
