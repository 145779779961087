import React, { Component ,useState , useEffect} from 'react';
import Scrollbars from 'react-custom-scrollbars';

import { Button,Dropdown,Form } from 'react-bootstrap';
// import Dropzone from 'react-dropzone-uploader'
// import { getDroppedOrSelectedFiles } from 'html5-file-selector'

import img1 from '../../../Assets/images/where/img1.png'
import img2 from '../../../Assets/images/where/img2.png'
import img3 from '../../../Assets/images/where/img3.png'
import img4 from '../../../Assets/images/where/img4.png'
import img5 from '../../../Assets/images/where/img5.png'

import { uploadHostImages } from '../../../Hooks/useUserHostingHook';
import {toast, Toaster} from "react-hot-toast";
import { toastOptions } from '../../../config/env';



const PlacePhotos = (props) =>{
    console.log('placephotos propes',props,props.Images)
    const [imageFiles, setImageFiles] = useState([]);
    console.log('imageFiles hihi',imageFiles)
    const [previewUrl, setPreviewUrl] = useState([]);
    console.log('preciewUrl hihi',previewUrl)
    const[base64 , setBase64] = useState([])
    const[base64data,setBase64data] = useState([]);


//video 
     const [videoname , setVideoname]= useState("");
     const [video , setVideo] = useState({});

    console.log("base64arrrrrrrrrrrrrrrrr",base64data)
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    

    useEffect(()=>
    {
    let Image = JSON.parse(localStorage.getItem("imageUrl"))
    // setPreviewUrl(Image)
    console.log('Imagesssssssgal',Image)
    props.onstatus()
    console.log('propps.Imagessss',props.Images,props.Images.length)
    if(props.Images.length >= 5)
    {
        console.log('enter this now')
        let arr = [];
        Array.from(props.Images).forEach(async(val)=>
        {
           
            setPreviewUrl([...previewUrl,URL.createObjectURL(val)])
            arr.push(URL.createObjectURL(val))
        })
        setPreviewUrl(arr);
    }
    let data = JSON.parse(localStorage.getItem("test"));
     setBase64(data)
   let arrdata = localStorage.getItem("arr1")
   console.log('arrrdatatatata',arrdata)
        // +setPreviewUrl(Image)
    },[props.Image])
    const HandleFile = (data) => {
        console.log("HandleFileHandleFile", data.target.files);
        var file = data.target.files;
        var reader = new FileReader()
        if (file.length>=5) {
                setImageFiles(file);
                let arr = [];
                let base64Arr = [];
                console.log('base^$arr',base64Arr)
                let arr1 = []
                Array.from(file).forEach(async(val)=>{
                 console.log('datasarrayfrom',val)
                    var fileName = val.name;
                    var idxDot = fileName.lastIndexOf(".") + 1;
                    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                    
                if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp"){
                    arr1.push(val)
                    setPreviewUrl([...previewUrl,URL.createObjectURL(val)])
                    arr.push(URL.createObjectURL(val));
                    const baseImg = await toBase64(val);
                    console.log("baseImg>>>>>>>>>>>>.",baseImg)
               
                    // console.log("URL.createObjectURL(val) :",URL.createObjectURL(val),"baseImg",baseImg);
                    base64Arr.push(baseImg);
                   
                    arr1.push(val)
                    console.log('base^$Arr',base64Arr)
                    console.log("inside foreach :",arr1);
                    localStorage.setItem("test",JSON.stringify(base64Arr));
                    // localStorage.setItem("photos",JSON.stringify())
                    setBase64data(base64Arr)
                }
                else{
                    toast.error("Invalid file format !",toastOptions);
                }

                });
           console.log('ar1111111111111111111111111111',arr1)
                localStorage.setItem("imageUrl",JSON.stringify(arr));
                localStorage.setItem("arr1",JSON.stringify(arr1));
                 console.log('ar1111111111111111111111111111 2 ',localStorage.arr1) 
                setPreviewUrl(arr);
                console.log("previewUrl",previewUrl);
              
        
        }else{
            toast.error("Must select atleast 5 photos !",toastOptions);
        }
    }
    const uploadImages = async() => {
        // await uploadHostImages(imageFiles,localStorage.getItem("email"));
         props.onGetPhotos(imageFiles);
        console.log('imageFiles jijiji',imageFiles,imageFiles.length)
        console.log('prospssssss.Imagesssssss',props.Images)
        if(imageFiles.length <= 4)
        {
            toast.error("Please Upload 5 or More photos !",toastOptions);
        }
    }

    const fileParams = ({ meta }) => {
        return { url: 'https://httpbin.org/post' }
    }
    const onFileChange = ({ meta, file }, status) => { 
        console.log(status, meta, file) 
    }
    const onSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove())
    }
    // const getFilesFromEvent = e => {
    //     return new Promise(resolve => {
    //         getDroppedOrSelectedFiles(e).then(chosenFiles => {
    //             resolve(chosenFiles.map(f => f.fileObject))
    //         })
    //     })
    // }
    const selectFileInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
        const textMsg = files.length > 0 ? 'Upload Again' : 'Select Files'
        return (
            <label className="btn btn-danger mt-4">
                {textMsg}
                <input
                    style={{ display: 'none' }}
                    type="file"
                    accept={accept}
                    multiple
                    onChange={e => {
                        getFilesFromEvent(e).then(chosenFiles => {
                            onFiles(chosenFiles)
                        })
                    }}
                />
            </label>
        )
    }

    return(
        <div className='col-md-12'>
            <div className='placetype rightcont mb-5 describeplace '>
                <Scrollbars style={{ height: "calc(100vh - 320px)" }} className="scroll">
                    <div className='row  px-3 align-items-center height-100 mb-90 dragphotos'>
                        <div className='text-center dashedbord d-none'>

                            <span className='fa fa-picture-o'></span>
                            <h5>Drag your photos here</h5>
                            <p>Add atleast 5 photos</p>

                            <Button variant="link" className="text-dark">Upload from your Device</Button>
                        </div>
                        <h4>Add video</h4>

                        <div className='upphoto my-3 upphoto_big'>

                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                    
                                        <span className='fa fa-video-camera'></span>
                                       
                                        <p>
                                            {videoname?
                                            videoname
                                            :
                                            "Upload video"
                                        }
                                            
                                            </p>
                                        
                                        <input type = "file" onChange={(e) =>{
                                             var image = e?.target?.files[0];
                                             var fileName = image.name;
                                             var idxDot = fileName.lastIndexOf(".") + 1;
                                             var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                                             if (extFile == "mp4" || extFile == "mov" || extFile == "m4v" || extFile == "mkv" || extFile == "avi"){
                                                setVideoname(e?.target?.files[0]?.name);
                                                setVideo(e?.target?.files[0]);
                                                props?.onGetVideo(e?.target?.files[0]);
                                             }
                                             else{
                                                toast.error("Invalid Video Format!..")
                                             }
                            
                        }}/>
                                    </Button>

                                    </div>




                        <div className='uploadedpics'>
                        {/* <input type = "file" onChange={(e) =>{
                            setVideoname(e?.target?.files[0]?.name);
                            setVideo(e?.target?.files[0]);
                            props?.onGetVideo(e?.target?.files[0]);
                        }}/> */}

                            <div className='d-flex justify-content-between  mb-4'>

                                <div>
                                    <h4>Add atleast 5 photos</h4>
                                    {/* <p className='text-muted'>Drag to reorder</p> */}
                                </div>
                                <div>

                                    <Button className='btn-theme'  onClick={uploadImages} disabled={previewUrl.length<5} >Upload</Button>
                                    {/* {disabled={previewUrl.length<5}} */}
                                </div>
                            </div>
                            <div className='images'>
                                <div className='row'>

                                    <div className='col-md-12 position-relative'>
                                    <Button className='fullbutton'>
                                   
                                        <div className='d-flex justify-content-between position-absolute imagedrop px-3 pt-3'>
                                            <Button className='btn white-btn'>Cover Photo</Button>
                                            {/* <div>
                                            <Dropdown className='imageaction'>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    <span className='fa fa-ellipsis-h '></span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                                </Dropdown>
                                            </div> */}
                                        </div>
                                        {console.log('previewursl',previewUrl[0])}
                                        {/* {console.log('previewursl,,,',base64[0])} */}
                                        <img src={previewUrl != "" ? previewUrl[0] : (base64 ? base64[0] : img1) } />
                                        <Form className='hideinput'>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="file" multiple  onChange={HandleFile} />
                                        </Form.Group>
                                        </Form>
                                        </Button>

                                        

                                    </div>
                                    <div className='col-md-6 position-relative'>
                                        <Button className='fullbutton'>
                                            <img src={previewUrl != "" ? previewUrl[1] : (base64 ?base64[1] : img2)}/>

                                            <div className='uploadimage d-flex dashedbord align-items-center justify-content-center'>
                                                <span className='fa fa-picture-o'></span>
                                            </div>
                                        </Button>
                                    </div>
                                    <div className='col-md-6'>
                                    <Button className='fullbutton'>

                                        <img src={previewUrl != "" ? previewUrl[2] : (base64 ? base64[2] : img3)} />

                                          {/*    <div className='d-flex justify-content-between position-absolute imagedrop px-3 pt-3'>
                                            
                                           <div>
                                            <Dropdown className='imageaction'>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    <span className='fa fa-ellipsis-h '></span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2">Move Backwards</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3">Move Forwards</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-4">Move Cover phots</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-5">Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                                </Dropdown>
                                            </div> 
                                        </div>*/}
                                        <div className='uploadimage d-flex dashedbord align-items-center justify-content-center'>
                                                <span className='fa fa-picture-o'></span>
                                            </div>
                                    </Button>
                                    </div>
                                    <div className='col-md-6'>
                                    <Button className='fullbutton'>

                                        <img src={previewUrl != "" ? previewUrl[3] : ((base64) ? base64[3] : img4)} />

                                       {/* <div className='d-flex justify-content-between position-absolute imagedrop px-3 pt-3'>
                                        
                                         <div>
                                        <Dropdown className='imageaction'>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <span className='fa fa-ellipsis-h '></span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Move Backwards</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Move Forwards</Dropdown.Item>
                                                <Dropdown.Item href="#/action-4">Move Cover phots</Dropdown.Item>
                                                <Dropdown.Item href="#/action-5">Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                            </Dropdown>
                                        </div> 
                                    </div>*/}
                                       <div className='uploadimage d-flex dashedbord align-items-center justify-content-center'>
                                                <span className='fa fa-picture-o'></span>
                                            </div>
                                    </Button>
                                    </div>
                                    <div className='col-md-6'>
                                    <Button className='fullbutton'>

                                        <img src={previewUrl != "" ? previewUrl[4] : (base64 ? base64[4] : img5)} />

                                      {/*   <div className='d-flex justify-content-between position-absolute imagedrop px-3 pt-3'>
                                        
                                        <div>
                                        <Dropdown className='imageaction'>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <span className='fa fa-ellipsis-h '></span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Move Backwards</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Move Forwards</Dropdown.Item>
                                                <Dropdown.Item href="#/action-4">Move Cover phots</Dropdown.Item>
                                                <Dropdown.Item href="#/action-5">Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                            </Dropdown>
                                        </div> 
                                    </div>*/}
                                       <div className='uploadimage d-flex dashedbord align-items-center justify-content-center'>
                                                <span className='fa fa-picture-o'></span>
                                            </div>
                                    </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <Toaster
                        position="top-center"
                        reverseOrder={false}
                        />
                </Scrollbars>
            </div> 
        </div>
    )
}

export default PlacePhotos