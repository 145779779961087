import React, { Component, useState, useCallback, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FloatingLabel, Form } from "react-bootstrap";

import img1 from "../../../Assets/images/filter/beaches.png";
import img2 from "../../../Assets/images/filter/bed.png";
import img3 from "../../../Assets/images/filter/camp.png";
import img4 from "../../../Assets/images/filter/cave.png";
import img5 from "../../../Assets/images/filter/design.png";
import img6 from "../../../Assets/images/filter/island.png";
import img7 from "../../../Assets/images/filter/lake.png";
import img8 from "../../../Assets/images/filter/national.png";
import img9 from "../../../Assets/images/filter/omg.png";
import img10 from "../../../Assets/images/filter/pools.png";
import img11 from "../../../Assets/images/filter/tiny.png";

import { propTypes } from "react-bootstrap/esm/Image";

import { API_URL } from "../../../config/env";
const Highlights = (props) => {
  const [charcount, setCharCount] = useState(0);
  const [highlightsList, setHighlightsList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([]);

  const setData = useCallback(
    async (val, index) => {
      if (!highlightsList.includes(val)) {
        setHighlightsList([...highlightsList, val]);
        setSelectedIndex([...selectedIndex, index]);
        localStorage.setItem(
          "highlightsIndex",
          JSON.stringify([...selectedIndex, index])
        );
      } else if (highlightsList.includes(val)) {
        const filteredArray = highlightsList.filter((item) => item !== val);
        const filteredIndex = selectedIndex.filter((item) => item !== index);
        setHighlightsList(filteredArray);
        setSelectedIndex(filteredIndex);
        localStorage.setItem("highlightsIndex", JSON.stringify(filteredIndex));
      }

      props.onGetHighlights(highlightsList);
    },
    [selectedIndex, setHighlightsList]
  );

  useEffect(() => {
    setSelectedIndex(
      localStorage.getItem("highlightsIndex")
        ? JSON.parse(localStorage.getItem("highlightsIndex"))
        : selectedIndex
    );
    localStorage.getItem("description")
      ? props.onGetHighlights(JSON.parse(localStorage.getItem("description")))
      : localStorage.removeItem("highlightsIndex");
  }, []);

  // const setData = (val,index) => {
  //     let arrayData = [];
  //     let arrayIndex = [];
  //     arrayData = highlightsList;
  //     arrayIndex = selectedIndex;

  //     if(!arrayData.includes(val)){

  //     arrayData.push(val);
  //     arrayIndex.push(index);

  //     }
  //     else if(arrayData.includes(val)){

  //         const filteredArray = arrayData.filter((item) => item !== val);
  //         arrayData=(filteredArray);
  //         const filteredIndex = arrayIndex.filter((item) => item !== index);
  //         arrayIndex=(filteredIndex);
  //     }
  //     console.log("arrayIndex",arrayIndex);
  //     setHighlightsList(arrayData);
  //     setSelectedIndex(arrayIndex);

  //     props.onGetHighlights(arrayData);
  // }
  return (
    <div className="col-lg-12 col-xl-12 col-md-12 hignlights">
      <div className="placetype placetype_heoght_fix mb-5">
        <Scrollbars
          style={{ height: "calc(100vh - 320px)" }}
          className="scroll"
        >
          <div
            className="row  px-3 height-100  align-items-center pb-5"
            data-aos="fade-up"
          >
            <div>
              <h5 className="mb-5">Choose upto 2 highlights</h5>
              <ul className="">
                {console.log("props.data.path", props.data.path)}
                {props.data &&
                  props.data.content.map((val, index) => (

                    
                   val?.status != "Inactive" && <li
                      className={selectedIndex.includes(index) ? "active" : ""}
                      onClick={() => {
                        setData(val, index);
                      }}
                    >
                    <img src={API_URL + props.data.path + "/" + val.image} />
                      <span>{val.type}</span>
                    </li>


                  ))}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default Highlights;
