import React, { useState, useEffect } from 'react';
import Footer from '../../../../Common/Footer';
import HostHeader from '../../HostHeader';
import prof from '../../../../../Assets/images/prof.jpg'
import DataTable from 'react-data-table-component';
import { Tabs, Tab } from 'react-bootstrap'
import CancelModal from './Modal/CancelBook';
import { bookingExperienceUserDetails, completedExperienceBookingDetails } from '../../../../../Hooks/useUserHook'
import {checkExperienceBookStatus} from "../../../../../Hooks/useUserExperienceHook"
import { API_URL, toastOptions } from '../../../../../config/env.js';
import ExperiencePaymentIntegrationModal from '../../../../Common/Modals/ExperiencePaymentModel';
import { useNavigate } from 'react-router-dom';
// import ReviewExp from '../../../../Common/Modals/'
import ExperienceGiveReview from '../../../../Common/Modals/ExperienceGivereview';
const TripsExp = (props) => {
    const [cancel, setCancel] = useState(false)
    const [expBookDetails, setExpBookDetails] = useState([])
    const [upcoming, setUpcoming] = useState(true)
    const [previous, setPrevious] = useState(false)
    const [completedExpDetails,setCompletedExpDetails] = useState([])
    const [showModel, setShowModel] = useState(false);
    const [ pendingData, setPendingData] = useState({});
    const [experienceReview,setExperienceReview] = useState(false);
    const [room , setRoom] = useState('')
    
    // const[givereview , setGiveReview] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        const id = window.location.pathname.split("/")[4];
        const room = window.location.pathname.split("/")[3];
        console.log('rodfasjdsadhdcbsfgcg',room)
        setRoom(room)
        console.log("id-----------", id);
        // checkExperienceBookStatus(id)
        bookingExperienceUserDetails(id).then((result) => {
            console.log("bookingExperienceDetails", result);
            setExpBookDetails(result)
        })
        completedExperienceBookingDetails(id).then((result) => {
            console.log("completedExperienceBookingDetails",result);
            setCompletedExpDetails(result)
        })
        // checkExperienceBookStatus(id)
    }, [])
    // console.log("THE bookingExperienceDetails ----> ", expBookDetails[0])

    const getPendingRecords = async(index) =>{
        const data = expBookDetails[index]
        console.log("INdex Value",index , data?.experienceId);
        // console.log("THE bookingExperienceDetails ----> ",expBookDetails[index]);
        setPendingData(data)
    }
    console.log("--=------=-=-=-=-=-=-=-=-=-==-=",pendingData)
    // const data = [
    //     {
    //       id: 1,
    //       date: "12/04/2022 to 15/08/2022",
    //       rname:"Dummy Resort",
    //       rplace:"Madurai, Thirunagar",
    //       profimg:<img src={prof} />,
    //       profname:"David",
    //       btn:<button
    //       type="button"
    //       class="btn btn-warning text-light nowrap m-auto"
    //       data-bs-toggle="modal"
    //       data-bs-target="#myModal" >
    //   Processing
    //     </button>

    //     },
    //     {
    //         id: 2,
    //         date: "12/04/2022 to 15/08/2022",
    //         rname:"Dummy Resort",
    //         rplace:"Madurai, Thirunagar",
    //         profimg:<img src={prof} />,
    //         profname:"David",
    //         btn:<button
    //                 type="button"
    //                 class="btn btn-success text-light nowrap m-auto"
    //                 data-bs-toggle="modal"
    //                 data-bs-target="#myModal" >
    //                      Success
    //                 </button>,
    //         btn2:<button
    //                  type="button"
    //                  class="btn btn-secondary text-light nowrap m-auto"
    //                  data-bs-toggle="modal"
    //                  data-bs-target="#myModal" >
    //                       Expired
    //                  </button>

    //       },
    //       {
    //         id: 3,
    //         date: "12/04/2022 to 15/08/2022",
    //         rname:"Dummy Resort",
    //         rplace:"Madurai, Thirunagar",
    //         profimg:<img src={prof} />,
    //         profname:"David",
    //         btn:<button
    //                 type="button"
    //                 class="btn btn-success text-light nowrap m-auto"
    //                 data-bs-toggle="modal"
    //                 data-bs-target="#myModal" >
    //                      Success
    //                 </button>,
    //         btn2:<button
    //                  type="button" onClick={() => setCancel(true)}
    //                  class="btn btn-danger text-light nowrap m-auto"
    //                  data-bs-toggle="modal"
    //                  data-bs-target="#myModal" >
    //                       Cancel
    //                  </button>

    //       }
    // ]
    const columns = [
        {
            name: "Host",
            sortable: true,
            reorder: false,
            cell: (expBookDetails) => (
                <>
                    <img src={expBookDetails?.Host?.profilePicturePath ? API_URL + "/" + expBookDetails.Host.profilePicturePath : prof} />
                    <p>{expBookDetails?.Host?.firstName ? expBookDetails?.Host?.firstName : "" + " " + expBookDetails?.Host?.lastName ? expBookDetails?.Host?.lastName : ""}</p>
                </>),

        },
        {
            name: "Date",
            cell: (expBookDetails) => (
                <>
                    {new Date(expBookDetails?.date).toLocaleDateString()}
                </>
            ), minWidth: "200px"
        },
        {
            name: "Location",
            cell: (expBookDetails) => (
                <>
                    <p>
                        {expBookDetails?.roomDetails?.meetingLocation?.streetAddress} {expBookDetails?.roomDetails?.meetingLocation?.suite},
                        {expBookDetails?.roomDetails?.meetingLocation?.city}&nbsp;{expBookDetails?.roomDetails?.meetingLocation?.zip}&nbsp;,{expBookDetails?.roomDetails?.meetingLocation?.state}.
                    </p>
                </>),
            minWidth: "200px"
        },
        {
            name: "Status",
            button: true,
            cell: (expBookDetails, index) => (
                <>{
                    expBookDetails && expBookDetails.isCompleted == true ?
                    <>
                     <p>Completed</p> 
                    </> : 
                    <>{
                        expBookDetails && expBookDetails.status == "pending" ?
                        <>
                        {console.log('expBookDetails.status',expBookDetails.status)}
                         <button
                        type="button"
                        class="btn btn-success text-light nowrap m-auto"
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                        onClick={() => {getPendingRecords(index); setShowModel(true)}}
                    >
                        {/* expBookDetails?.status */}
                        {"Pay Now"} 
                    </button>
                        </> :
                         <button
                         type="button"
                         class="btn btn-success text-light nowrap m-auto"
                         data-bs-toggle="modal"
                         data-bs-target="#myModal"
                     >
                         {expBookDetails?.status}
                     </button>
                    }
                    {/* <button
                        type="button"
                        class="btn btn-success text-light nowrap m-auto"
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                    >
                        {expBookDetails?.status}
                    </button> */}
                    </>
                }
                    
                </>),
            minWidth: "250px"
        },
        // {
        //     name: "Option",
        //     button: true,
        //     cell: (expBookDetails) => (
        //         <button
        //             type="button"
        //             class="btn btn-chat nowrap"
        //             data-bs-toggle="modal"
        //             data-bs-target="#myModal"
        //             // data-bs-target="/host-dashboard/inbox/:id"
        //             onClick={()=>navigate("/host-dashboard/inbox/:id")}
        //         >
        //             Message History
        //             <span className='fa fa-paper-plane'></span>
        //         </button>),
        //     minWidth: "250px"
        // },

    ]

    const columns1 = [
        {
            name: "Host",
            sortable: true,
            reorder: false,
            cell: (completedExpDetails) => (
                <>
                    <img src={completedExpDetails?.Host?.profilePicturePath ? API_URL + "/" + completedExpDetails.Host.profilePicturePath : prof} />
                    <p>{completedExpDetails?.Host?.firstName?completedExpDetails?.Host?.firstName:"" + " " + completedExpDetails?.Host?.lastName ? completedExpDetails?.Host?.lastName : ""}</p>
                </>),

        },
        {
            name: "Date",
            cell: (completedExpDetails) => (
                <>
                    {new Date(completedExpDetails?.date).toLocaleDateString()}
                </>
            ), minWidth: "200px"
        },
        {
            name: "Location",
            cell: (completedExpDetails) => (
                <>
                    <p>
                        {completedExpDetails?.roomDetails?.meetingLocation?.streetAddress} {completedExpDetails?.roomDetails?.meetingLocation?.suite},
                        {completedExpDetails?.roomDetails?.meetingLocation?.city}&nbsp;{completedExpDetails?.roomDetails?.meetingLocation?.zip}&nbsp;,{completedExpDetails?.roomDetails?.meetingLocation?.state}.
                    </p>
                </>),
            minWidth: "200px"
        },
        {
            name: "Status",
            button: true,
            cell: (completedExpDetails) => (
                <>
                <p>Completed</p>
                </>),
            minWidth: "250px"
        },
        {
            name: "Review",
            button: true,
            cell: (expBookDetails,index) => (
                <button
                         type="button"
                         class="btn btn-primary  text-light nowrap m-auto"
                         data-bs-toggle="modal"
                         data-bs-target="#myModal"
                         onClick={()=> {setExperienceReview(true);getPendingRecords(index)}}
                     >
                         Review
                     </button>),
            minWidth: "250px"
        },

    ]


    return (
        <div className='trips'>
            {cancel && <CancelModal onDismiss={() => setCancel(false)} />}
            <HostHeader />
            <div className='container container_custom mh-100vh-header'>
                <div className='shadowbox p-3 my-4'>
                    <div className='d-sm-flex jc-between align-items-center'>
                        {console.log('roomssssdfasdfasdfas',room)}
                        <h4 className='mb-sm-0 mb-3 themeclr'>Experience</h4>
                        <div className='togglebtn toggle_ho_scr'> <button className={upcoming ? "active btn" : "btn"} onClick={() => { setUpcoming(true); setPrevious(false) }}>Upcoming Trip</button>
                            <button className={previous ? "active btn" : "btn"} onClick={() => { setUpcoming(false); setPrevious(true) }}>Previous Trip</button></div>
                    </div>
                </div>

                <div className={upcoming ? 'disputetab shadowbox ' : "d-none"}>
                    <DataTable className='tripdis_tab' pagination columns={columns} data={expBookDetails} />
                </div>
                <div className={previous ? 'disputetab shadowbox ' : "d-none"}>
                        <DataTable className='tripexp_tab' pagination columns={columns1} data={completedExpDetails} />
                        </div>
            </div>
            {
                 showModel &&
                 <>
                 <ExperiencePaymentIntegrationModal data = {pendingData} stripe = {props?.stripe} onDismiss = {() => setShowModel(false)}/>
                 </>
            }
            {
                experienceReview &&
                <>
                <ExperienceGiveReview experienceId = {pendingData?.experienceId} onDismiss = {
                    () => {setExperienceReview(false)}
                }/>
                </>
            }
            <Footer />
        </div>
    )
}

export default TripsExp