import React, { Component, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {Modal, Button, Form} from 'react-bootstrap'




const CameraModal = (props) => {
    const[showcammodal , setShowCamModal] = useState(true)

  
    return(
    
        <Modal show={showcammodal} centered scrollable className='modal_style'>
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </Modal.Header>
            <Modal.Body>
                <h6> Security camera(s)</h6>
                <p className='f-14'> Hosts are required to disclose all security cameras and other recording devices in their
                     listings. Intentionally concealed recording devices,or devices that observe the interior 
                     of bedrooms and bathrooms, are prohibited. Learn more
                </p>

                <h6 className='mt-4'>Weapons</h6>
                <p className='f-14'>
                    All weapons at a listing must be properly disclosed, stored and secured.
                </p>

                <h6 className='mt-4'>Dangerous animals</h6>
                <p className='f-14'>
                    Hosts should not keep a potentially dangerous animal (one that’s capable of causing serious 
                    harm to humans or other animals) in a listing without properly disclosing its presence and
                    securing it in a safe and secure accommodation.
                </p>
                <hr />
                <div className='text-end'>
                    <Button className='btn-theme'>Done</Button>
                </div>
                
            </Modal.Body>
        </Modal>
     
    )
}

export default CameraModal