import React, { useState, useEffect } from 'react';
import { ProgressBar, Button, Form, InputGroup, Accordion } from 'react-bootstrap'
import FilterExp from '../../../src/Components/Landing/FilterExp'
import { API_URL, toastOptions } from '../../config/env.js';
import { Freeze } from 'react-freeze';
import MyMapComponent from '../../../src/Components/Landing/MyMapComponent';

import { getSingleExperienceDetails,overAllReviewExperience } from '../../Hooks/useUserHostingHook'
import detailimg1 from '../../Assets/images/detail/detailimg1.png'
import detailimg2 from '../../Assets/images/detail/detailimg2.png'
import detailimg3 from '../../Assets/images/detail/detailimg3.png'
import detailimg4 from '../../Assets/images/detail/detailimg4.png'
import detailimg5 from '../../Assets/images/detail/detailimg5.png'

import clock from "../../Assets/images/icons/new/clock.png";
import ball from "../../Assets/images/icons/new/ball.png";
import rain from "../../Assets/images/icons/new/rain.png";
import dot from "../../Assets/images/icons/new/dot.png";

import CoverModal from '../Common/Modals/CoverModal.js';
import PlaceOffer from '../Common/Modals/PlaceOffer.js';
import HealthSafety from '../Common/Modals/HealthSafety.js';
import ExperienceReviewModel from '../Common/Modals/ExperienceReviewModel';
import Cancellation from '../Common/Modals/CancellationModal.js';

import AvailabilityModal from '../../../src/Components/Landing/AvailabilityModal.js';
import AvailCont from '../Common/AvailCont.js';


import wifi from '../../Assets/images/icons/icons/wifi.png'
import ac from '../../Assets/images/icons/icons/ac.png'
import tv from '../../Assets/images/icons/icons/tv.png'
import hairdryer from '../../Assets/images/icons/icons/hairdryer.png'
import food from '../../Assets/images/icons/icons/food.png'
import bed from '../../Assets/images/icons/icons/bed.png'

import pool from '../../Assets/images/icons/icons/pool.png'

import key from '../../Assets/images/key.png'
import card from '../../Assets/images/card.png'
import badge from '../../Assets/images/badge.png'

import prof from '../../Assets/images/prof.jpg'
import prof1 from '../../Assets/images/prof1.jpg'
import prof2 from '../../Assets/images/prof2.jpg'
import prof3 from '../../Assets/images/prof3.jpg'
import { editexperiencepricehooks, getHostDetails } from '../../Hooks/useUserHook.js';
import { Link, useNavigate } from 'react-router-dom';
import BookingCard from './Modal/BookingCard';
import BookCardModal from './Modal/BookCardModal';
import ShowAllImg from '../Common/Modals/ShowAllImg';
import { GOOGLE_MAP_API_KEY } from '../../config/env.js';
import RoomMap from '../Maps/RoomMap';
import SeeMoreDates from './Modal/SeeMoreDates';
import { toast } from 'react-hot-toast';





const Header = React.lazy(() => import('../Common/Header'));
const Footer = React.lazy(() => import('../Common/Footer'));

const Card = React.lazy(() => import('../../../src/Components/Landing/Card.js'))

const Datepicker = React.lazy(() => import('../Common/Datepicker/Datepicker.js'));


const Experienceedit = (props, { shouldSuspendRendering }) => {

    const text = "We had great time with Akash! The meals we prepared were delicious and I'm hoping to be able to recreate them at home. Akash is very knowledgeable and a super likable guy. We had great time with Akash! The meals we prepared were delicious";

    const [showone, setShowone] = useState(false);
    const [showtwo, setShowtwo] = useState(false);
    const [showthree, setShowthree] = useState(false);
    const [showfour, setShowfour] = useState(false);
    const [readmore, setReadmore] = useState(false);

    const [cover, setCover] = useState(false)
    const [placeoffer, setPlaceOffer] = useState(false)
    const [healthsafety, setHealthSafety] = useState(false)
    const [avail, setAvail] = useState(false)
    const [moreDates,setMoreDates] = useState(false)
    const [showallimg, setShowAllImg] = useState(false)
    const [cancel, setCancel] = useState(false)
    const [hostDetails, setHostDetails] = useState({}) 
    const [review, setReview] = useState(false)
    const [experienceDatas, setExperienceDatas] = useState({})
    const [coordsLoaded, setCoordsLoaded] = useState(false);
    const [position, setPosition] = useState({});
    const [overAllReview,setOverAllReview] = useState();
    const [price , setPrice] = useState("");
    const [disable , setDisable] = useState(false);
    useEffect(() => { UserExperienceHook() }, []);

    const UserExperienceHook = () => {
        const id = window.location.pathname.split("/")[2];
        console.log('expHome', id);
        getSingleExperienceDetails(id).then((data) => {
            console.log('setUseUserExperienceHook', data)
            setExperienceDatas(data);
            setPrice(data?.pricePerGuest);
            getHostDetails(data.email).then((data) => {
                //console.log('setHostDetails',data.data)
                setHostDetails(data.data);
            })
            overAllReviewExperience(data?._id).then((overall) => {
                console.log(overall);
                setOverAllReview(overall)
            })
        })

    }

    const getCoordinates = async (address) => {
        console.log("address in geocoding :", address);
        await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + address + '&key=' + GOOGLE_MAP_API_KEY)
            .then(response => response.json())
            .then(data => {
                if (data.results[0]) {
                    const latitude = data.results[0].geometry.location.lat;
                    const longitude = data.results[0].geometry.location.lng;
                    //console.log("complete data from geocoding fetch", data, "lat lng from room address", { latitude, longitude });
                    setPosition({ lat: latitude, lng: longitude });
                    setCoordsLoaded(true);
                }
            })
    }

    useEffect(() => {
        if (experienceDatas) {
            const address = experienceDatas.meetingLocation?.streetAddress + "," + experienceDatas.meetingLocation?.suite + "," + experienceDatas.meetingLocation?.city + "," + experienceDatas.meetingLocation?.state;
            //console.log('ExperienceDatas Address', address);
            getCoordinates(address);
        }
    }, [experienceDatas])

    const navigate = useNavigate();

    const navigatehosthome = () => {
        navigate('/confirmandpay')
    }

    const handleSave = async() => {
        setDisable(true);
        if(isNaN(parseFloat(price)) || parseFloat(price) < 0){
          toast.error("Invalid price value");
        }
        else{
          let payload = {
            id : experienceDatas?._id,
            price : price
          }
          let edit = await editexperiencepricehooks(payload);
          toast.success("successfully updated!")
          UserExperienceHook();
        }
        setDisable(false);
      }


    return (
        <div className='expdetails with_footer_exp'>
            {moreDates && <SeeMoreDates data = {experienceDatas} onDismiss={() => setMoreDates(false)} />}
             {cover && <CoverModal onDismiss={() => setCover(false)} />} 
        {placeoffer && <PlaceOffer onDismiss={() => setPlaceOffer(false)} />}
        {healthsafety && <HealthSafety onDismiss={() => setHealthSafety(false)} />}
            {showallimg && <ShowAllImg onDismiss={() => setShowAllImg(false)} />}
            {cancel && <Cancellation onDismiss={() => setCancel(false)} />}
            {review && <ExperienceReviewModel onDismiss={() => setReview(false)}  data = {experienceDatas}/>}
            <Header />
            <div className='detail_ful pt-4'>
               
                <div className='container container_custom'>
                    <div className='detail_header '>
                        <div>
                            <p className='detail_heading'>{experienceDatas.title} with {experienceDatas.firstName}&nbsp;{experienceDatas.lastName}</p>
                            <p className='mt-2 detail_desc'>
                                <span className='fa fa-star full_star me-1'></span>
                                <span className='me-1'>{overAllReview?.data} (19).</span>
                                <span className='me-1'><a href="#" className=''>{experienceDatas && experienceDatas.meetingLocation && experienceDatas.meetingLocation.state}, {experienceDatas && experienceDatas.meetingLocation && experienceDatas.meetingLocation.countrycode.split("(")[0]}</a> .</span>
                                <span>Part of the<a href="#" className='ms-1'> FlutterPads Cooking collection</a></span>
                            </p>
                        </div>


                    </div>
                    {/* <div className='d-flex gap-2 share justify-content-end'>
                        <button className='btn'><span className='fa fa-bookmark'></span> Save</button>
                        <button className='btn'><span className='fa fa-share-alt-square'></span> Share</button>
                    </div> */}
                    <div className='detail_images mt-5'>
                        <div className='row'>
                            <div className='col-12 col-md-3 px-1 showinthis767'>
                                <img src={experienceDatas.photos && `${API_URL}${experienceDatas.photosPath}/${experienceDatas.photos[0].name}`} className="img-fluid img_full_height" />
                            </div>
                            <div className='col-12 col-md-3 px-1'>
                                <img src={experienceDatas.photos && `${API_URL}${experienceDatas.photosPath}/${experienceDatas.photos[1].name}`} className="img-fluid img_full_height" />
                            </div>
                            <div className='col-12 col-md-3'>
                                <div className='row'>
                                    <div className='col-12 mb-2 px-1'>
                                        <img src={experienceDatas.photos && `${API_URL}${experienceDatas.photosPath}/${experienceDatas.photos[2].name}`} className="img-fluid img_half_height" />

                                    </div>
                                    <div className='col-12 px-1'>
                                        <img src={experienceDatas.photos && `${API_URL}${experienceDatas?.photosPath}/${experienceDatas?.photos[3]?.name}`} className="img-fluid img_half_height" />

                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-3 px-1'>
                                <img src={experienceDatas.photos && `${API_URL}${experienceDatas?.photosPath}/${experienceDatas?.photos[4]?.name}`} className="img-fluid img_full_height" />
                            </div>
                        </div>
                        {/* <button className='showallimg' onClick={() => setShowAllImg(true)}>Show all images</button> */}
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12 col-md-12 leftcont'>

                            <div className="d-flex">
                                <div className="flex-grow-1 me-3">
                                    <p className='detail_heading'>{experienceDatas.title} event hosted by {experienceDatas.firstName}&nbsp;{experienceDatas.lastName} </p>
                                    <p>Hosted in English</p>
                                </div>
                                <div className="flex-shrink-0">
                                    <img src={(hostDetails && hostDetails.profilePicturePath) ? API_URL + "/" + hostDetails.profilePicturePath : prof} width="56" height="56" alt="Sample Image" className='prof_img_round' />
                                </div>
                            </div>
                            <hr />
                            <div className='detail_content py-2'>
                                <div className="d-flex mb-3">
                                    <div className="flex-shrink-0">
                                        <img src={card} className="desc_icon" alt="Sample Image" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <p className='name themeclr fw-600 f-14'>Cultural immersion</p>
                                        <p className='detail_desc_grey'>Guests felt absorbed in a community or culture</p>
                                    </div>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="flex-shrink-0">
                                        <img src={badge} className="desc_icon" alt="Sample Image" />
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <p className='name themeclr fw-600 f-14'>Supoer Story telling</p>
                                        <p className='detail_desc_grey'>Guest say this Host tells great stories</p>
                                    </div>
                                </div>
                            </div>
                            {/* <hr /> */}
                            {/* <p className='name themeclr fw-600 f-16 mb-2'>What you'll do</p>
                            <p className='detail_desc_grey mb-2'>{experienceDatas.whatYouWillDo}</p> */}
                            {/* <hr /> */}

                            {/* <p className='name themeclr fw-600 f-16 mb-2'>What's included</p>
                            <div className='row mt-4 pb-3'>
                            {
                                experienceDatas && experienceDatas.whatYouWillProvide && experienceDatas.whatYouWillProvide[0].provisions && experienceDatas.whatYouWillProvide[0].provisions.map((value, index) => 
                                      
                                        <div className='col-12 col-sm-6 col-md-4 col-xl-3'>
                                            <div className='card card_sleep'>
                                                <div className='card-body'>
                                                    <img src={bed} className="desc_icon" alt="Sample Image" />
                                                    <p className='name themeclr fw-600 f-16 mb-2 mt-3'>{value.item}</p>
                                                    <p className='detail_desc_grey f-12'>{value.description}</p>

                                                </div>
                                            </div>
                                        </div>
                                )
                            }
                            </div> */}
                            {/* </div>
                            <div className='col-12 col-sm-6 col-md-4 col-xl-3'>
                            <div className='card card_sleep'>
                            <div className='card-body'>
                                         <img src={bed} className="desc_icon" alt="Sample Image" />
                                    <p className='name themeclr fw-600 f-16 mb-2 mt-3'>Drink</p>
                                <p className='detail_desc_grey f-12'>drink</p>

                            </div>
                        </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4 col-xl-3'>
                            <div className='card card_sleep'>
                            <div className='card-body'>
                                         <img src={bed} className="desc_icon" alt="Sample Image" />
                                    <p className='name themeclr fw-600 f-16 mb-2 mt-3'>Equipment</p>
                                <p className='detail_desc_grey f-12'>We will be cooking our meal</p>

                            </div>
                        </div>
                            </div>
                        </div> */}



                        </div>
                       
                    </div>
                    <hr />

                    <div>
                       
              <p className="name themeclr fw-600 f-16 mb-2">
                Price
              </p>
              <div className="row mt-4">
                <div className="col-12 col-md-6">
                  {/* {userData?.address ? userData?.address?.street+" "+userData?.address?.flat+" "+userData?.address?.city+" "+userData?.address?.stateprovince+" "+userData?.address?.postcode+" "+userData?.address?.country : userData?.address} */}
                  <input
                    type="text"
                            placeholder="Price"
                    className="form-control mb-4 input_big_ne"
                   value={price}
                   onChange = {(e) => setPrice(e?.target?.value)}
                  />
                </div>
              </div>

              
              <div className="pb-3">
                <button
                  type="button"
                  className="btn btn-theme filterbtn"
                  disabled = {disable}
                  onClick={() => handleSave()}
                >
                  Save
                 
                </button>
              </div>
              <hr />

              {/* <div className='row withstat'>
                                                <>
                                                <div className='cols col-xl-4 col-lg-6 mb-3'>

                                                <div className='shadowbox p-4 br-5'> 
                                        <div className='d-flex align-items-center'>
                                            <h6 className='mb-3'><b>Experience Details</b></h6>

                                        </div>
                                        <div className='graybox br-0 mt-0 py-3 mobi_fle_ger'>




<div className='d-flex jc-between'>

<h6 className='mb-3 f-14'><p className='themeclr'>Date</p> 
<p className='text-muted'>10/8/2023</p></h6>
</div>


<div className='d-flex jc-between'>

<h6 className='mb-3 f-14'><p className='themeclr'>Price</p> 
<p className='text-muted'>$100</p></h6>
</div>

<div className='d-flex jc-between'>

<h6 className='mb-3 f-14'><p className='themeclr'>No.of Persons</p> 
<p className='text-muted'>3</p></h6>
</div>


</div>
                                        
                                    </div>


                                               
                                            </div>

                                            <div className='cols col-xl-4 col-lg-6 mb-3'>

<div className='shadowbox p-4 br-5'> 
<div className='d-flex align-items-center'>
<h6 className='mb-3'><b>Experience Details</b></h6>

</div>
<div className='graybox br-0 mt-0 py-3 mobi_fle_ger'>




<div className='d-flex jc-between'>

<h6 className='mb-3 f-14'><p className='themeclr'>Date</p> 
<p className='text-muted'>10/8/2023</p></h6>
</div>


<div className='d-flex jc-between'>

<h6 className='mb-3 f-14'><p className='themeclr'>Price</p> 
<p className='text-muted'>$100</p></h6>
</div>

<div className='d-flex jc-between'>

<h6 className='mb-3 f-14'><p className='themeclr'>No.of Persons</p> 
<p className='text-muted'>3</p></h6>
</div>


</div>

</div>



</div>

<div className='cols col-xl-4 col-lg-6 mb-3'>

<div className='shadowbox p-4 br-5'> 
<div className='d-flex align-items-center'>
<h6 className='mb-3'><b>Experience Details</b></h6>

</div>
<div className='graybox br-0 mt-0 py-3 mobi_fle_ger'>




<div className='d-flex jc-between'>

<h6 className='mb-3 f-14'><p className='themeclr'>Date</p> 
<p className='text-muted'>10/8/2023</p></h6>
</div>


<div className='d-flex jc-between'>

<h6 className='mb-3 f-14'><p className='themeclr'>Price</p> 
<p className='text-muted'>$100</p></h6>
</div>

<div className='d-flex jc-between'>

<h6 className='mb-3 f-14'><p className='themeclr'>No.of Persons</p> 
<p className='text-muted'>3</p></h6>
</div>


</div>

</div>



</div>
                                            </>
                                                
                                            </div> */}

              <hr/> 
                    </div>
                    <div>
                        <p className='name themeclr fw-600 f-20 d-flex align-items-center mb-4'>
                            <span className='fa fa-star full_star me-1'></span>
                            <span className='me-1'>{overAllReview?.data} ({experienceDatas?.review?.length} reviews)</span>
                        </p>
                        <div>
                            <div>
                                <div className='row'>
                                    {
                                        experienceDatas && experienceDatas.review && experienceDatas.review.map((val,index) =>
                                        <div className='col-12 col-md-6 mb-3'>
                                                <div className="d-flex mb-3">
                                                    <div className="flex-shrink-0">
                                                        <img src={(val && val.profilePicPath) ? API_URL + "/" + val.profilePicPath : prof} className="desc_icon" alt="Sample Image" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className='name themeclr fw-600 f-14'>{val.name}</p>
                                                        <p className='detail_desc_grey'>{new Date(val.date).toUTCString().slice(7, 16)}</p>
                                                    </div>
                                                </div>
                                                <p className='detail_desc_grey'>
                                                    {/* {showone?value.description:(value.description).substring(0,150)} */}
                                                    {val.description}
                                                </p>
                                            </div>
                                        )
                                    }
                                    {/* <div className='col-12 col-md-6 mb-3'>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                                <img src={prof} className="desc_icon" alt="Sample Image" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <p className='name themeclr fw-600 f-14'>Charlotte</p>
                                                <p className='detail_desc_grey'>June 2022</p>
                                            </div>
                                        </div>
                                        <p className='detail_desc_grey'>
                                            {showone ? text : text.substring(0, 150)}
                                        </p>
                                        <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={() => setShowone(!showone)}>
                                            {showone ? "Show Less" : "Show More"}
                                        </a></p>

                                    </div> */}
                                    {/* <div className='col-12 col-md-6 mb-3'>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                                <img src={prof1} className="desc_icon" alt="Sample Image" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <p className='name themeclr fw-600 f-14'>Charlotte</p>
                                                <p className='detail_desc_grey'>June 2022</p>
                                            </div>
                                        </div>
                                        <p className='detail_desc_grey'>
                                            {showtwo ? text : text.substring(0, 150)}
                                        </p>
                                        <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={() => setShowtwo(!showtwo)}>
                                            {showtwo ? "Show Less" : "Show More"}
                                        </a></p>
                                    </div> */}
                                    {/* <div className='col-12 col-md-6 mb-3'>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                                <img src={prof2} className="desc_icon" alt="Sample Image" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <p className='name themeclr fw-600 f-14'>Charlotte</p>
                                                <p className='detail_desc_grey'>June 2022</p>
                                            </div>
                                        </div>
                                        <p className='detail_desc_grey'>
                                            {showthree ? text : text.substring(0, 150)}
                                        </p>
                                        <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={() => setShowthree(!showthree)}>
                                            {showthree ? "Show Less" : "Show More"}
                                        </a></p>


                                    </div> */}
                                    {/* <div className='col-12 col-md-6 mb-3'>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                                <img src={prof3} className="desc_icon" alt="Sample Image" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <p className='name themeclr fw-600 f-14'>Charlotte</p>
                                                <p className='detail_desc_grey'>June 2022</p>
                                            </div>
                                        </div>
                                        <p className='detail_desc_grey'>
                                            {showfour ? text : text.substring(0, 150)}
                                        </p>
                                        <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={() => setShowfour(!showfour)}>
                                            {showfour ? "Show Less" : "Show More"}
                                        </a></p>

                                    </div> */}
                                    {experienceDatas.review?.length > 2 && <div className='pb-3'>
                                        <button type="button" className="btn btn-theme filterbtn" onClick={() => setReview(true)}>Show all {experienceDatas.review?.length} reviews</button>
                                    </div>}
                                </div>

                            </div>
                            <hr />
                            <div>
                                <p className='name themeclr fw-600 f-16 mb-2 pt-4 pb-4'>What's included</p>
                                {coordsLoaded && <Freeze freeze={shouldSuspendRendering}>
                                    <div className="mapComponent map_detail" >
                                        {/* <GoogleMaps position={position} onGetAddress={(val)=>addressFunc(val)} /> */}
                                        <RoomMap position={position} />
                                    </div>
                                </Freeze>}
                                <p className='detail_desc_grey py-4'>We will meet at my house and will cook in a well equiped kitchen.
                                    <br />
                                    I would like to show you a special local market later after the workshop.
                                    <br />
                                    To reach my place its best to use Uber or Ola taxi app its very cost effective and convenient.
                                </p>
                                <hr />
                                <div className='row pb-5'>
                                    <div className='col-12 col-md-7'>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                                <img src={(hostDetails && hostDetails.profilePicturePath) ? API_URL + "/" + hostDetails.profilePicturePath : prof} className="desc_icon" alt="Sample Image" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <p className='name themeclr fw-600 f-14'>{hostDetails.firstName}&nbsp;{hostDetails.lastName}</p>
                                                <p className='detail_desc_grey f-12 pb-1'>Host on FlutterPads since 2015</p>
                                                <p className='detail_desc_grey f-12'>
                                                    <span className='fa fa-star'></span>
                                                    <span className='ms-2'>19 Reviews</span>
                                                    <span className='fa fa fa-shield ms-3'></span>
                                                    <span className='ms-2'>Identity verified</span>
                                                </p>
                                            </div>
                                        </div>
                                        <p className='detail_desc_grey f-12 pb-1'>{hostDetails.bio}
                                        </p>
                                    </div>
                                    <div className='col-md-5'>
                                        <p className='detail_desc_grey f-12 pb-1'>Language : <span>{hostDetails && hostDetails.languagesKnown &&
                                                hostDetails.languagesKnown.join()}</span></p>
                                        <p className='detail_desc_grey f-12 pb-1'>Response rate : <span>{(hostDetails && hostDetails.responseRate) ? `${hostDetails.responseRate}%` : `0%`}</span></p>
                                        <p className='detail_desc_grey f-12 pb-1'>Response time : <span>{hostDetails && hostDetails.responseTime}</span></p>

                                        <Link to="/contacthost" className="btn btn-theme my-4">Contact host</Link>
                                        <p className='detail_desc_grey f-12 pb-1'>To protect your payment, never transfer or communicate outside of the FlutterPads website or app </p>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h4 className='themeclr'>Dietary Preference</h4>
                                    <p className='text-muted'>
                                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis perspiciatis dolorem aperiam explicabo aliquam necessitatibus impedit pariatur maiores.
                                        Velit laudantium eligendi incidunt omnis at reiciendis molestias labore dicta nostrum necessitatibus!
                                    </p>
                                    <p className='text-muted my-3'><span className='fa fa-cutlery me-2'></span> Vegeterian</p>
                                    <button className='btn btn-theme mt-4'>Contact Host</button>
                                </div>
                                <hr />
                                <div className='pb-4'>
                                    <p className='name themeclr fw-600 f-16 mb-2 pt-4 pb-4 '>Things to know</p>
                                    <div className='row thingstoknow' >
                                        <div className='col-md-4 mt-md-0 mt-3'>
                                            <p className='name themeclr fw-600 f-16 mb-2'>House rules</p>
                                            <p className='detail_desc_grey f-16 pb-1'><img src={clock} /> Check-in: After 3.00 pm</p>

                                        </div>
                                        <div className='col-md-4 mt-md-0 mt-3'>
                                            <p className='name themeclr fw-600 f-16 mb-2'>Health and safety</p>

                                            <p className='detail_desc_grey f-16 pb-1'><img src={ball} />COVID-19 safety practices apply</p>
                                            <p className='detail_desc_grey f-16 pb-1'><img src={rain} />Corbon monoxide alarm</p>
                                            <p className='detail_desc_grey f-16 pb-1'><img src={dot} />Smoke alarm</p>
                                            <Button onClick={() => setHealthSafety(true)} variant="link" className='link_blue_border ps-0'>Show more</Button>
                                        </div>
                                        <div className='col-md-4 mt-md-0 mt-3'>
                                            <p className='name themeclr fw-600 f-16 mb-2'>Cancellation Policy</p>
                                            <p className='detail_desc_grey f-16 pb-1'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ab non
                                                facere incidunt assumenda amet perferendis quasi, consequatur distinctio voluptates voluptatibus quas eius. Quidem consequuntur
                                                nulla harum rem odit officia a.</p>
                                            <Button variant="link" className='link_blue_border ps-0' onClick={() => setCancel(true)}>Show more</Button>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    )
}

export default Experienceedit;