import React, { Component, useState, useEffect } from "react";

import { propTypes } from "react-bootstrap/esm/Image";
import Scrollbars from "react-custom-scrollbars";

const DescribePlace = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState({});

  const setData = (val, index) => {
    setSelectedIndex(index);
    setSelectedValue(val);
    localStorage.setItem("describePlaceIndex", index);
    props.onGetDescribePlace(val);
  };

  useEffect(() => {
    setSelectedIndex(
      localStorage.getItem("describePlaceIndex")
        ? localStorage.getItem("describePlaceIndex")
        : selectedIndex
    );
    localStorage.getItem("propertyType")
      ? props.onGetDescribePlace(
          JSON.parse(localStorage.getItem("propertyType"))
        )
      : localStorage.removeItem("describePlaceIndex");
  }, []);

  return (
    <div className="col-lg-12 col-xl-12 col-md-12">
      <div className="placetype rightcont mb-5 describeplace">
        <Scrollbars
          style={{ height: "calc(100vh - 320px)" }}
          className="scroll"
        >
          <div
            className="row  px-3 align-items-center height-100 mb-5 py-2"
            data-aos="fade-up"
          >
            <ul>
              {props.data &&
                props.data.content.map((val, index) => (
                  val?.status != "Inactive" && <li>
                    <button
                      onClick={() => {
                        setData(val, index);
                      }}
                      className={
                        index == selectedIndex
                          ? "placecards active"
                          : "placecards"
                      }
                    >
                      <h6 className="mb-0">{val.heading}</h6>
                      <p className="f-14">{val.content}</p>
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default DescribePlace;
