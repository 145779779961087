import React, { useEffect, useState } from 'react';
import Footer from '../../../../Common/Footer';
import HostHeader from '../../HostHeader';
import prof from  '../../../../../Assets/images/prof.jpg'
import DataTable from 'react-data-table-component';
import CancelBook from './Modal/CancelBook'
import {Tabs, Tab} from 'react-bootstrap'

import { checkBookingStatus, getUserDatas } from '../../../../../Hooks/useUserHook';
import { getUserBookings, getUserCancelledTrips, getUserTrips } from '../../../../../Hooks/useUserHostingHook';
import { API_URL } from '../../../../../config/env';
// import { getUserDatas } from '../../../../../Hooks/useUserHook';
// import { getUserBookings } from '../../../../../Hooks/useUserHostingHook';
import GiveReview from '../../../../Common/Modals/GiveReivew';
import { useNavigate } from 'react-router-dom';



const TripsStay = () => {
    const [bookingDatas,setBookingDatas] = useState([]);
    const [tripDatas,setTripDatas] = useState([]);
    const [cancelledDatas,setCancelledDatas] = useState([]);
    console.log('cancelledDatas',cancelledDatas)
    const [cancel,setCancel] = useState(false);
    const [cancelDetails, setCancelDetails] = useState({});
    console.log('cancelDetails',cancelDetails)
    const [reload, setReload] = useState(false);
    const [cancelledTab, setCancelledTab] = useState(false);
    const [roomid , setRoomid] = useState("");

    const navigate = useNavigate();

    useEffect(()=>{
        const userdata = getUserDatas();
        console.log('userdata',userdata)
        getUserBookings(userdata.email).then((data)=>{
            setBookingDatas(data);
        })
        getUserTrips(userdata.email).then((data)=>{
            setTripDatas(data);
        })
        getUserCancelledTrips(userdata.email).then((data)=>{
            console.log('UserCancelledTrips',data)
            setCancelledDatas(data);
        })
    },[reload]);


    const data = [
        {
          id: 1,
          date: "12/04/2022 to 15/08/2022",
          rname:"Dummy Resort",
          rplace:"Madurai, Thirunagar",
          profimg:<img src={prof} />,
          profname:"David",
          btn:<button
          type="button"
          class="btn btn-warning text-light nowrap m-auto"
        //   data-bs-toggle="modal"
        //   data-bs-target="#myModal" 
          >
      Processing
        </button>
    
        },
        {
            id: 2,
            date: "12/04/2022 to 15/08/2022",
            rname:"Dummy Resort",
            rplace:"Madurai, Thirunagar",
            profimg:<img src={prof} />,
            profname:"David",
            btn:<button
                    type="button"
                    class="btn btn-success text-light nowrap m-auto"
                    // data-bs-toggle="modal"
                    // data-bs-target="#myModal" 
                    >
                         Success
                    </button>,
            btn2:<button
                     type="button"
                     class="btn btn-warning text-light nowrap m-auto"
                    //  data-bs-toggle="modal"
                    //  data-bs-target="#myModal" 
                     >
                          Cancelled
                     </button>
      
          },
          {
            id: 3,
            date: "12/04/2022 to 15/08/2022",
            rname:"Dummy Resort",
            rplace:"Madurai, Thirunagar",
            profimg:<img src={prof} />,
            profname:"David",
            btn:<button
                    type="button"
                    class="btn btn-success text-light nowrap m-auto"
                    // data-bs-toggle="modal"
                    // data-bs-target="#myModal" 
                    >
                         Success
                    </button>,
            btn2:<button
                     type="button"
                     class="btn btn-danger text-light nowrap m-auto"
                    //  data-bs-toggle="modal"
                    //  data-bs-target="#myModal" 
                     >
                          Cancelled
                     </button>
      
          }
    ]
    
    const columns = [
        {
            name:"Host",
            sortable: true,
            reorder: false,
            cell: (data) => (
                <><img src={(data.Host.profilePicturePath)?API_URL+"/"+data.Host.profilePicturePath:prof} />
                {data.Host.firstName} {data.Host.lastName}</> ),
           
        },
        {
            name:"Date",
            cell: (data) => (
             <>{new Date(data.checkInDate).toLocaleDateString()} to {new Date(data.checkOutDate).toLocaleDateString()}</> ),minWidth:"200px"
        },
        {
            name:"Room Title",
            cell: (data) => (
             <>{data?.roomTitle}</> ),minWidth:"200px"
        },

        {
            name:"Location",
            cell: (data) => (
                <>
                <p>{data.accommodationAddress.city} {data.accommodationAddress.countrycode}</p>
                </>),
                minWidth:"200px"
        },
        {
            name:"Status",
            button: true,
            cell: (data) => (
              <>
              {/* {(data.isVerified)?
                (data.isValidTransaction?
                    successButton:
                    ((data.expired)?
                expiredButton:
                pendingButton)):
                (data.isRejected?
                    rejectedButton
                    :(data.expired?
                        expiredButton:
                        processingButton))} */}

                        <button
                        type="button"
                        disabled={data.isRejected || data.iscancelled || data.expired }
                        class={(data.isVerified)?
                            (data.isValidTransaction?
                                "btn btn-success text-light nowrap m-auto":
                                ((data.expired)?
                                "btn btn-secondary text-light nowrap m-auto":
                                "btn btn-warning text-light nowrap m-auto")):
                            (data.isRejected?
                                "btn btn-danger text-light nowrap m-auto"
                                :(data.expired?
                                    "btn btn-secondary text-light nowrap m-auto":
                                    "btn btn-warning text-light nowrap m-auto"))}
                                    onClick={()=>{(data.isVerified)?(data.isValidTransaction?navigate("/payment-success/"+data._id):navigate("/confirm-and-pay/"+data._id)):console.log("pending")}}
                        // data-bs-toggle="modal"
                        // data-bs-target="#myModal" 
                        >
                             {(data.isVerified)?
                (data.isValidTransaction?
                    (data.iscancelled?"cancelled":"Confirmed"):
                    ((data.expired)?
                "Expired":
                // Pending
                (data.iscancelled?"cancelled":"Pay Now"))):
                (data.isRejected?
                    "Rejected"
                    :(data.expired?
                        "Expired":
                        (data.iscancelled?"cancelled":"Processing")))}
                        </button>
                
                        {(data.iscancelled)?
                <></>:
                (data.expired?<></>:<button
                type="button"
                class={(data.iscancelled)?
                    "":
                    (data.expired?"":"btn btn-warning text-light nowrap m-auto")}
                    onClick={()=>{data.iscancelled?console.log("cancelled"):setCancel(true);setCancelDetails(data)}}
                // data-bs-toggle="modal"
                // data-bs-target="#myModal" 
                >
                     Cancel
                </button>)}

                </> ),
              minWidth:"250px"
        },
        {
            name:"Option",
            button: true,
            cell: (data) => (
              <button
                type="button"
                class="btn btn-theme nowrap"
                // data-bs-toggle="modal"

                // data-bs-target="#myModal"
                onClick={()=>{console.log("data.hostEmail :",data.hostEmail); 
                    navigate(`/host-dashboard/inbox/${data.email}/${data.hostEmail}`,{state:data.hostEmail})
                }}
                >
            Message History
            <span className='fa fa-paper-plane ms-1'></span>

              </button> ),
              minWidth:"250px"
        },
      
       
     
    ]


    const columns2 = [
        {
            name:"Host",
            sortable: true,
            reorder: false,
            cell: (data) => (
                <><img src={(data.Host.profilePicturePath)?API_URL+"/"+data.Host.profilePicturePath:prof} />
                {data.Host.firstName} {data.Host.lastName}</> ),
           
        },
        {
            name:"Date",
            cell: (data) => (
             <>{new Date(data.checkInDate).toLocaleDateString()} to {new Date(data.checkOutDate).toLocaleDateString()}</> ),minWidth:"200px"
        },
        {
            name:"Room Title",
            cell: (data) => (
             <>{data?.roomTitle}</> ),minWidth:"200px"
        },
        {
            name:"Location",
            cell: (data) => (
                <>
                <p>{data.accommodationAddress.city} {data.accommodationAddress.countrycode}</p>
                </>),
                minWidth:"200px"
        },
        {
            name:"Status",
            button: true,
            cell: (data) => (
              <>
                        <button
                        type="button"
                        // class="btn btn-warning text-light nowrap m-auto"
                        class={"btn btn-success text-light nowrap m-auto"}
                        // data-bs-toggle="modal"
                        // data-bs-target="#myModal" 
                        >
                             Success
                        </button>
                

                </> ),
              minWidth:"250px"
        },
        {
            name:"Review",
            button: true,
            cell: (data) => (
              <button
                type="button"
                className="btn btn-theme"
                // class="btn btn-chat nowrap"
                // data-bs-toggle="modal"
                // data-bs-target="#myModal"
               onClick={()=>{setRoomid(data?.roomId); setGiveReview(true)}}
                >
            Add Review
           
              </button> ),
              minWidth:"250px"
        },
      
       
     
    ]
    const columns3 = [
        // {
        //     name:"Host",
        //     sortable: true,
        //     reorder: true,
        //     cell: (data) => (
        //         <><img src={(data.Host.profilePicturePath)?API_URL+"/"+data.Host.profilePicturePath:prof} />
        //         {data.Host.firstName} {data.Host.lastName}</> ),
           
        // },

        {
            name:"Cancellation Id",
            sortable: true,
            reorder: false,
            cell: (data) => (
                <p>{data.cancellationDetails._id}</p> ),
        },
        {
            name:"Room Title",
            sortable: true,
            reorder: false,
            cell: (data) => (
                <p>{data.roomTitle}</p> ),
        },
        {
            name:"Date",
            cell: (data) => (
             <>{new Date(data.checkInDate).toLocaleDateString()} to {new Date(data.checkOutDate).toLocaleDateString()}</> ),minWidth:"200px"
        },
        {
            name:"Booking Id",
            cell: (data) => (
                <>
                <p>{data._id}</p>
                </>),
                minWidth:"200px"
        },
        {
            name:"Status",
            button: true,
            cell: (data) => (
              <>
                        <button
                        type="button"
                        onClick={()=>{navigate(`/host-dashboard/trips/cancellation/${data.cancellationDetails._id}`)}}
                        // class="btn btn-warning text-light nowrap m-auto"
                        class={"btn btn-success text-light nowrap m-auto"}
                        // data-bs-toggle="modal"
                        // data-bs-target="#myModal" 
                        >
                             status
                        </button>
                

                </> ),
              minWidth:"250px"
        },
        // {
        //     name:"Review",
        //     button: true,
        //     cell: (data) => (
        //       <button
        //         type="button"
        //         class="btn btn-chat nowrap"
        //         data-bs-toggle="modal"
        //         data-bs-target="#myModal"
               
        //         >
        //     Add Review
           
        //       </button> ),
        //       minWidth:"250px"
        // },
      
       
     
    ]


    const[upcoming, setUpcoming] = useState(true)
    const[previous, setPrevious] = useState(false)
    const[givereview , setGiveReview] = useState(false)
{console.log('bookingDatas',bookingDatas,'tripDatas',tripDatas)}
    return(
        <div className='trips'>
            {givereview && <GiveReview roomid = {roomid} onDismiss={() => setGiveReview(false)}/> }
            <HostHeader />
            {cancel&&<CancelBook data={cancelDetails} onReload={()=>{setReload(true)}} onDismiss={()=>{setCancel(false)}}/>}
              <div className='container container_custom mh-100vh-header'>
                     <div className='shadowbox p-3 my-4'>
                            <div className='d-sm-flex jc-between align-items-center'>
                                <h4 className='mb-sm-0 mb-3 themeclr'>Stay</h4>
                               <div className='togglebtn toggle_ho_scr'> 
                               <button className={upcoming ? "active btn" : "btn"} onClick={() => {setUpcoming(true);setPrevious(false);setCancelledTab(false)}}>Upcoming Trip</button>
                                <button className={previous ? "active btn" : "btn"} onClick={() => {setUpcoming(false);setPrevious(true);setCancelledTab(false)}}>Previous Trip</button>
                                <button className={cancelledTab ? "active btn" : "btn"} onClick={() => {setUpcoming(false);setPrevious(false);setCancelledTab(true);}}>Cancelled Trip</button>
                                </div>
                            </div>
                        </div>
      
                        <div className={upcoming ? 'disputetab shadowbox ' : "d-none"}>
                           <DataTable className='tripstay_tab1' pagination columns={columns} data={bookingDatas} />
                        </div>
                        <div className={previous ? 'disputetab shadowbox ' : "d-none"}>

                        <DataTable className='tripstay_tab2' pagination columns={columns2} data={tripDatas} />
                        </div>
                        <div className={cancelledTab ? 'disputetab shadowbox ' : "d-none"}>
                        <DataTable className='tripstay_tab3' pagination columns={columns3} data={cancelledDatas} />

                        </div>
              </div>
              <Footer/>
        </div>
    )
}

export default TripsStay;