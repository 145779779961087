import React, { Component, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";

import img1 from "../../../Assets/images/where/img1.png";
import img2 from "../../../Assets/images/where/img2.png";
import img3 from "../../../Assets/images/where/img3.png";
import img4 from "../../../Assets/images/where/img4.png";
import img5 from "../../../Assets/images/where/img5.png";
import img6 from "../../../Assets/images/where/img6.png";
import img7 from "../../../Assets/images/where/img7.png";
import img8 from "../../../Assets/images/where/img8.png";
import img9 from "../../../Assets/images/where/img9.png";
import img10 from "../../../Assets/images/where/img10.png";
import img11 from "../../../Assets/images/where/img11.png";
import img12 from "../../../Assets/images/where/img12.png";
import img13 from "../../../Assets/images/where/img13.png";
import { API_URL } from "../../../config/env";

const PlaceType = (props) => {
  console.log("placetypess ----------", props);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState("");
  console.log("selectedValue", selectedValue);

  const setData = (val, index) => {
    console.log("val,index", val, index);
    setSelectedIndex(index);
    setSelectedValue(val);
    console.log("localStorage.setItem(placeTypeIndex,selectedIndex)", index);
    localStorage.setItem("placeTypeIndex", index);
    props.onGetPlaceType(val);
  };

  useEffect(() => {
    console.log(
      "localStorage.getItem(placeTypeIndex)",
      localStorage.getItem("placeTypeIndex"),
      'localStorage.getItem("propertyTypeGroup")',
      localStorage.getItem("propertyTypeGroup")
    );
    setSelectedIndex(
      localStorage.getItem("placeTypeIndex")
        ? localStorage.getItem("placeTypeIndex")
        : selectedIndex
    );
    localStorage.getItem("propertyTypeGroup")
      ? props.onGetPlaceType(
          JSON.parse(localStorage.getItem("propertyTypeGroup"))
        )
      : localStorage.removeItem("placeTypeIndex");
  }, []);

  return (
    <div className="col-lg-12 col-xl-12 col-md-12">
      <div className="rightcont rightcont placetype placetype_he_us mb-5">
        <Scrollbars
          style={{ height: "calc(100vh - 320px)" }}
          className="scroll"
        >
          <div
            className="row  px-3 align-items-center height-100 py-1"
            data-aos="fade-up bg-nth-child"
          >
            {props.data &&
              props.data.content.map((val, index) => (
                val?.status != "Inactive" && 
                <ul className="col-lg-4 bg-nth-child">
                  <li
                    onClick={() => {
                      setData(val, index);
                    }}
                    className={
                      index == selectedIndex
                        ? "active become-host-card-bottom-style"
                        : "become-host-card-bottom-style"
                    }
                  >
                    <div
                    className="d-flex flex-column align-items-center justify-content-center py-4"
                    >
                      <img src={API_URL + props.data.path + "/" + val.image} />

                      <h6 className="mt-3 mb-0 host-text-name">{val.type}</h6>
                      
                    </div>
                    <p className={`p-bg-color-${(index % 3) + 1}`}/> 
                  </li>
                </ul>
              ))}
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default PlaceType;
