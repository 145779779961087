import React,{useEffect, useState} from "react";
import {Button } from 'react-bootstrap'
 

const ConfirmAvailcont = (props) =>{
    const [price, setPrice] = useState();
    const [button , setButton] = useState(true);

    useEffect(() => {
      setButton(props?.butn);
    } , [props?.butn])

    const setPricePerDay = (e) => {
        if(e.target.id == "price"){
            props.onSetPriceDate(price)
        }else{
            props.onSetPriceDate("****");
        }
    }

    return(
        <div className="availability pt-4">
        {/* <h4 className="dates">Wed, 24 Aug </h4> */}
        {/* <div className="d-flex jc-between my-5">
            <h6 className="mb-0">Available</h6>
            <div>
                <Button variant="outline-dark" className="circlebtn me-2"><span className="fa fa-times"></span></Button>
                <Button variant="dark" className="circlebtn"><span className="fa fa-check"></span></Button>
            </div>
        </div>
        <hr className="themehr"/> */}
        <h6 className="mb-4">Pricing</h6>
        <hr className="themehr"/>
        <div className="show_detail_dta">
{props?.selectedref?.startDate && <div>
    <p>From date : {props?.selectedref?.startDate && new Date(props?.selectedref?.startDate)?.toLocaleDateString()}</p>
    <p>To date : {props?.selectedref?.endDate && new Date(props?.selectedref?.endDate)?.toLocaleDateString()}</p>
    <p>Price : {props?.selectedref?.price && props?.selectedref?.price}</p>
    
</div>}
        <p>From Date: {(props?.from)?(new Date(props?.from))?.toLocaleDateString():""}</p>
        <p>To Date: {(props?.to)?(new Date(props?.to))?.toLocaleDateString():""}</p>
        </div>
        <button className="w-full text-start changeprice mb-4">
            <p className="f-12 text-muted">Nightly price</p>
            <input type="Number" min={1} className="w-full nobord" onChange={(e)=>{setPrice(e.target.value)}}/>
            {/* <p>$ 658</p> */}
        </button>
        <button className="btn btn-theme my-3 me-3" id="price" onClick={setPricePerDay}
        disabled = {button}
        >set price</button>
        <button className="btn btn-theme my-3" id="block" 
        disabled = {button}
        onClick={setPricePerDay}>Block dates</button>

        {/* <h6 className="mt-4">Custom settings</h6>
        <p className="text-muted">
        Adjust your length-of-stay settings or add a discount for the selected dates to 
        encourage guests to book.
        </p>
        <button className="btn btn-theme my-4" >+ Add custom settings</button>
        <hr className="themehr"/>

    <h6 className="mt-4 d-flex jc-between mb-0">Private note<b><Button variant="link" className="text-dark p-0">Edit</Button></b>
      </h6>
      <p>jhgdu</p> */}

        </div>
    )
}

export default ConfirmAvailcont