import React, { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';

import Login from '../Common/Modals/LoginModal.js'
import { Dropdown, Tabs, Tab, Row, Col, Nav, Modal, Accordion, InputGroup, Form, Toast } from "react-bootstrap";
import '../../Assets/css/header.css';
import { Scrollbars } from 'react-custom-scrollbars';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Counter from './counter';
import WhereInput from './WhereInput.js';
import Cookies from 'universal-cookie';

import logo from "../../Assets/images/landingPage/flutterpadlogo.png"
import user from "../../Assets/images/newLogo.png"
import map1 from "../../Assets/images/map-1.png"
import map2 from "../../Assets/images/map-2.png"
import map3 from "../../Assets/images/map-3.png"  
import map4 from "../../Assets/images/map-4.png"

import map5 from "../../Assets/images/map-5.png"
import map6 from "../../Assets/images/map-6.png"
import countrycode from "../../config/countrycode.json"
import calendar from "../../Assets/images/calender_icon.png"
import { experiencehostingfilterhook, filter } from "../../Hooks/filterHook";
import { set } from 'date-fns';
import Filtermodal from './Filter/Filtermodal.js';
import { getUserEmail, getUserDatas, userLogout, getusersdatahooks, getunreadnotificationhooks, readonehooks, readallhooks, switchhostandguesthooks } from '../../Hooks/useUserHook.js';
import countryname, { Unitedkingdom } from '../../config/countryname';
import { toast, Toaster } from 'react-hot-toast';
import { API_URL, toastOptions } from '../../config/env.js';
import { io } from 'socket.io-client';
// import {Iamflexible} from '../../config/countryname';
const Servicemodal = React.lazy(() => import('./Modals/Servicemodal'));

const Header = (props) => {
  console.log('props>>>>>', props, props.email)
  const [viewanywhere, setViewanywhere] = useState('Anywhere')
  const [viewcountry, setViewcountry] = useState('Search destinations');
  const [show, setShow] = useState(false);
  const [showlog, setShowLog] = useState(false);
  const [email, setEmail] = useState("");
  const [userDatas, setUserDatas] = useState({});
  console.log('userDatas', userDatas)
  const [showFilter, setShowFilter] = useState(false);
  const [country, setCountry] = useState([]);
  const [adult, setAdult] = useState(0);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [pets, setPets] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isfilterbutton, setIsfilterbutton] = useState(false);
  const [buttonfiltereddata, setButtonfiltereddata] = useState([]);
  const [iscountry, setIscountry] = useState(true);
  const [isdates, setIsdates] = useState(true);

  const [unread , setUnread] = useState([]);
  
  // const []

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (data, a) => {
    console.log("e data handle change", data);
    setCountry(data)
    setIscountry(false);
    setViewcountry(a);
    setViewanywhere(a);
  };

  const socket = io(API_URL);

  const getnotification = async() =>{
      let user = getUserDatas()
      let notifydata = await getunreadnotificationhooks(user?._id)
      console.log("notifydata" , notifydata);
      setUnread(notifydata?.data?.data);
  }

  const handlereadone = async(id) => {
    let user = getUserDatas()
    let readone = await readonehooks(id)
    let refresh = await getnotification();
  }
  const handlereadall = async() => {
    let user = getUserDatas()
    let readone = await readallhooks(user?._id)
    let refresh = await getnotification();
  }

  useEffect(()=>{
    async function fetchdata(){
      await getnotification()
    }
    fetchdata();
  } , [])

  useEffect(()=>{
    socket.on("getMessage" , ()=>{
       getnotification()
    })
  } , [socket])


  let { useremail } = useParams();
  const [showservice, setShowservice] = useState(false);
  // const [isfilter , setIsfilter] = useState(false);
  const [counter, setCounter] = useState({ Adults: 0, Children: 0, Infants: 0, Pets: 0 });
  const [userdata , setUserdata] = useState({})
  const [open, setopen] = useState(false)
  const [topsec, setTopsec] = useState(false)
  const handleadultplus = () => {
    if ((adult + children) < 16) {
      setAdult(adult + 1);
    }
  };
  const handleadultminus = () => {
    if (children > 0 || infants > 0 || pets > 0)
    // if(children == 0 || infants == 0 || pets == 0)
    {
      // if(adult>0)
      // {
      //   setAdult(adult-1)
      // }
      if (adult > 1) {
        setAdult(adult - 1)
      }
    }
    else
      if (adult > 0) {
        setAdult(adult - 1)
      }


  };
  const handlechildplus = () => {
    if ((adult + children) < 16) {
      setChildren(children + 1);
    }
    if (children == 0 && adult == 0)
      setAdult(1);
  };
  const handlechildminus = () => {
    if (children > 0) {
      setChildren(children - 1)
    }

  };
  const handleinfantplus = () => {
    if (infants < 5) {
      setInfants(infants + 1);
    }
    if (infants == 0 && adult == 0)
      setAdult(1);
  };
  const handleinfantminus = () => {
    if (infants > 0) {
      setInfants(infants - 1)
    };
  }
  const handlepetplus = () => {
    if (pets < 5) {
      setPets(pets + 1);
    }

    if (pets == 0 && adult == 0)
      setAdult(1);
  };
  const handlepetminus = () => {
    if (pets > 0)
      setPets(pets - 1)
  };

  const handleResize = () => {

    setTopsec(false);
    // setIscountry(true);
  }

  window.addEventListener('scroll', handleResize);

  const checkUserLogin = async (type) => {
    console.log("check user login :", email);
    if (email) {
      if(userdata?.govtproof?.length > 0 && userdata?.govtproof[0]?.verification == "accepted"){
        if(parseFloat(userDatas?.hostsubscribedaycount) > 0){
          if (type == "room") {
            window.location.href = window.location.origin + `/host/${email}`
          } else if (type == "experience") {
            window.location.href = window.location.origin + `/host-experience/${email}`
          }
        }
        else{
          toast.error("Need Host Subscription")
          window.location.href = window.location.origin + `/account`
        }
      }
      else{
        toast.error("Upload your government id!")
        window.location.href = window.location.origin + `/account`
      }
    } else {
      setShowLog(true);
    }
  }

  const logout = () => {
    // debugger
    // cookies.remove("email");
    // cookies.remove("User-Datas");
    // window.location.href = window.location.origin;
    userLogout().then((data) => {
      window.location.href = window.location.origin;
      console.log('dataslogut', data)
      toast.success(data, toastOptions);
      if (data.status) {
        // window.location.reload();
      }
    });
  }


  const hostDashboard = async () => {
    if (email) {
      window.location.href = window.location.origin + `/host-dashboard/${email}`
    } else {
      setShowLog(true);
    }
  }


  function showHeader() {
    setopen(true);
  }


  const handleDateChange = (date) => {
    console.log("handle date change :", date);
    // initial change: start by setting the startDate
    if (!startDate && !endDate) {
      setStartDate(date);
      // startDate has been set, set the end date
    }
    else if (startDate && !endDate && startDate < date) {
      setEndDate(date);
      setIsdates(false);
    }
    if (startDate && startDate > date) {
      setStartDate(date);
      // setEndDate('');
    }
    // user is choosing another range => set the start date
    // and set the endDate back to null
    if (startDate && endDate) {
      setStartDate(date);
      setEndDate(null);

    }

  }

  const filterdata = async () => {
    console.log("filter data");
    //  setIsfilter(true);
    setTopsec(false)
    console.log("date", startDate, endDate);
    let payload = {
      country: country,
      checkInDate: startDate,
      checkOutDate: endDate,
      adult: adult,
      children: children,
      infants: infants,
      pets: pets,
      guest: (adult + children)
      //  startdate : startDate,
      //  enddate : endDate
      //  count : counter
    }
    console.log("payload", payload);
    await filter(payload)
      .then((data) => {
        console.log("filter return : ", data);
        props.onSetFilteredData(data);
      })
      .catch((e) => console.log("filter return error", e))

  }
  //  useEffect(()=>{
  //   async function fetchdata(){
  //     let email = await cookies.get('email');
  //     console.log("emailid ",email)
  // }
  // fetchdata();
  //  })

  const setLogout = async () => {
    console.log('enterrrrrrrrrring')
    if (props.email != 'undefined' && props.email != undefined && props.email != '') {
      await logout()
      props.email = 'undefined'
    }
  }

  useEffect(() => {
    async function fetchdata(){
      const data = getUserEmail();
      var userdata = await getusersdatahooks(data);
      setUserdata(userdata);
    }
    fetchdata();
  },[])

  useEffect(() => {
    const data = getUserEmail();
    setEmail(data)
    setUserDatas(getUserDatas())
    // setLogout()

  }, [showlog]);
  //    const filterbuttondata = (val) => {
  //     console.log("props" , val);
  //     if(val.data.type == 'success')
  //         setIsfilterbutton(true)
  //         setButtonfiltereddata(val.data.record);
  // }


  const filterdataexperience = async () => {
    console.log("experience filter data");
    //  setIsfilter(true);
    setTopsec(false)
    console.log("date", startDate, endDate);
    let payload = {
      country: country,
      checkInDate: startDate,
      checkOutDate: endDate,
      adult: adult,
      children: children,
      infants: infants,
      pets: pets,
      guest: (adult + children)
      //  startdate : startDate,
      //  enddate : endDate
      //  count : counter
    }
    console.log("payload", payload);
    await experiencehostingfilterhook(payload)
      .then((data) => {
        console.log("filter return : ", data);
        props.onSetFilteredData(data);
      })
      .catch((e) => console.log("filter return error", e))

  }



  return (
    <div>

      {/* {showFilter && <Filtermodal onSetFilterbuttondata = {filterbuttondata}/>} */}

      {showlog && <Login onDismiss={() => setShowLog(false)} />}
      <header className={topsec ? 'header py-1 topsec' : ' header py-1'}>
        <div id="backdrp" className={!topsec ? "d-none" : "d-block"} onClick={() => setTopsec(false)}></div>
        <div className='container container_custom'>
          <div className={!topsec ? "d-none " : "d-block"}>

          </div>
          <div className='row justify-content-center'>
            <div className={!topsec ? "col-md-8 topcontsec vanish" : "col-md-8 topcontsec "}>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-5'>

              <Link to="/" className='nav-link logo px-0' ><img src={logo} className="img-fluid" alt="logo" /></Link>
            </div>
            <div className="col-6 justify-content-center d-lg-flex d-none centerhead">


              {/* <div className='tab_header_main'> */}
             
            </div>
            <div className='col-lg-3 col-7 text-end pe-lg-0'>
              <div className='right-head'>
                <Dropdown className='user_dd ps-0'>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn_trans_icon px-0 prof">
                    Become a Host
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='dd_menu_shadow'>
                    <Dropdown.Item onClick={() => { checkUserLogin("room") }}>Host a Room</Dropdown.Item>
                    <Dropdown.Item onClick={() => { checkUserLogin("experience") }}>Host an Experience</Dropdown.Item>
                    {/* <button className='btn btn_grey' onClick={()=>{checkUserLogin()}}>Become a Host</button> */}
                  </Dropdown.Menu>
                </Dropdown>
                {/* <button className='btn btn_trans_icon lang'>
                  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false"><path d="m8.002.25a7.77 7.77 0 0 1 7.748 7.776 7.75 7.75 0 0 1 -7.521 7.72l-.246.004a7.75 7.75 0 0 1 -7.73-7.513l-.003-.245a7.75 7.75 0 0 1 7.752-7.742zm1.949 8.5h-3.903c.155 2.897 1.176 5.343 1.886 5.493l.068.007c.68-.002 1.72-2.365 1.932-5.23zm4.255 0h-2.752c-.091 1.96-.53 3.783-1.188 5.076a6.257 6.257 0 0 0 3.905-4.829zm-9.661 0h-2.75a6.257 6.257 0 0 0 3.934 5.075c-.615-1.208-1.036-2.875-1.162-4.686l-.022-.39zm1.188-6.576-.115.046a6.257 6.257 0 0 0 -3.823 5.03h2.75c.085-1.83.471-3.54 1.059-4.81zm2.262-.424c-.702.002-1.784 2.512-1.947 5.5h3.904c-.156-2.903-1.178-5.343-1.892-5.494l-.065-.007zm2.28.432.023.05c.643 1.288 1.069 3.084 1.157 5.018h2.748a6.275 6.275 0 0 0 -3.929-5.068z"></path></svg>
                </button> */}

                <Dropdown className='user_dd ps-0 ms-3'>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn_trans_icon px-0 prof">
                    <img src={userDatas && userDatas.profilePicturePath ? (API_URL + userDatas.profilePicturePath) : user} className="img-fluid headerprof" alt="user" />
                    <i className="fa fa-bars ms-2" aria-hidden="true"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='dd_menu_shadow'>

                    {userDatas && userDatas.email  && <Dropdown.Item  onClick={() => hostDashboard()}>Dashboard</Dropdown.Item>}
                    {userDatas && userDatas.email &&<Link to="/account" className='dropdown-item'>Account</Link>}
                    {userDatas && userDatas.email && <Link to="/wishlist" className='dropdown-item'>Wishlists</Link>}
                    {/* {email&&<Link to="/pending-orders" className='dropdown-item'>Trips</Link>} */}
                   {userDatas && userDatas.email && <Dropdown.Item onClick={async() => {
                          let payload = {
                            id : userDatas?._id,
                            host : !userDatas?.isHost
                          }
                          let res = await switchhostandguesthooks(payload);
                          let up = await getusersdatahooks(getUserEmail());
                          setTimeout(window.location.reload() , 500);
                        }}>
                  Switch To {userDatas?.isHost == true ? "Travelling" : "Hosting"}
                </Dropdown.Item>}
                    <Dropdown.Item onClick={() => { (userDatas && userDatas.email) ? logout() : setShowLog(true) }}>{(userDatas && userDatas.email) ? "Logout" : "Signup/Login"}</Dropdown.Item>


                              {/* <Dropdown.Divider />
                      <Dropdown.Item href="#">Host Your Home</Dropdown.Item>
                      <Dropdown.Item href="#">Host An Experience</Dropdown.Item>
                      <Dropdown.Item href="#">Help</Dropdown.Item> */}

                  </Dropdown.Menu>
                </Dropdown>


              </div>
            </div>


          </div>
        </div>

      </header>
      
      <div className='mobile-filter'>
        <div className='container container_custom'>
          <div className='row'>
            <div className='col-12 d-lg-none d-block'>
              
            </div>

          </div>
        </div>
      </div>


      <Modal show={show} onHide={handleClose} className="mobilewhere modal_style" scrollable>
        <Modal.Header >
          <button type="button" className="btn close close_modal" onClick={() => handleClose()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey="stays">
            <Row className='d-flex align-items-center justify-content-between flex-column'>
              <Col className="mx-auto bg-white">
                <Nav variant="pills" className="">
                  <Nav.Item>
                    <Nav.Link eventKey="stays">Stays</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link to="/experience" eventKey="experiences">Experiences</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
          <Nav.Link eventKey="onlineexperiences">Online Experiences</Nav.Link>
        </Nav.Item> */}
                </Nav>
              </Col>
              <Col className="mx-auto mt-3 max-850">
                <Tab.Content>
                  <Tab.Pane eventKey="stays">

                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header><p className='me-3'>Where</p><p>I'm Flexible</p></Accordion.Header>
                        <Accordion.Body>
                          <div className=''>
                            <InputGroup className="mb-3">
                              <InputGroup.Text id="basic-addon1"><span className='fa fa-search'></span></InputGroup.Text>
                              <Form.Control
                                placeholder="Search destinations"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                            </InputGroup>
                            <div className='maps'>
                              <div className=''>
                                <img className='' src={map1} />
                                <p className='drop_header_country'>I'm Flexible</p>
                              </div>
                              <div className=''>
                                <img className='' src={map2} />
                                <p className='drop_header_country'>Europe</p>
                              </div>
                              <div className=''>
                                <img className='' src={map3} />
                                <p className='drop_header_country'>United Kingdom</p>
                              </div>
                              <div className=''>
                                <img className='' src={map4} />
                                <p className='drop_header_country'>United States</p>
                              </div>
                              <div className=''>
                                <img className='' src={map3} />
                                <p className='drop_header_country'>Canada</p>
                              </div>
                              <div className=''>
                                <img className='' src={map4} />
                                <p className='drop_header_country'>Middle East</p>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1" className='adddate'>
                        <Accordion.Header><p className='me-3'>When</p><p>Add Date</p></Accordion.Header>
                        <Accordion.Body>
                          <Tabs defaultActiveKey="date" id="uncontrolled-tab-example" className="mb-3 where_tab">
                            <Tab eventKey="date" title="Choose Date">
                              <Datepicker />
                              <div className='text-center btn_cal_grp mt-3'>
                                <div className="btn-group btn_blue_grp" role="group" aria-label="Basic example">
                                  <button type="button" className="btn btn-primary">Exact dates</button>
                                  <button type="button" className="btn btn-primary"><span className='pe-1'>&#177;</span>1 day</button>
                                  <button type="button" className="btn btn-primary"><span className='pe-1'>&#177;</span>2 days</button>
                                  <button type="button" className="btn btn-primary"><span className='pe-1'>&#177;</span>3 days</button>
                                  <button type="button" className="btn btn-primary"><span className='pe-1'>&#177;</span>7 days</button>

                                </div>
                              </div>
                            </Tab>
                            <Tab eventKey="flexible" title="I'm flexible">
                              <p className='drop_header text-center'>How long would you like to stay?</p>
                              <div className='text-center'>
                                <div className="btn-group btn_blue_grp" role="group" aria-label="Basic example">
                                  <button type="button" className="btn btn-primary">Weekend</button>
                                  <button type="button" className="btn btn-primary">Week</button>
                                  <button type="button" className="btn btn-primary">Month</button>
                                </div>
                              </div>
                              <p className='drop_header text-center mt-3'>When do you want to go?</p>
                              <Scrollbars style={{ width: 500, height: 150 }} className="calendar_scroll">
                                <div className='calendar_div_scroll'>
                                  <div className='card card_calendar'>
                                    <div className='card-body'>
                                      <div className='text-center'>
                                        <img src={calendar} className="img-fluid" alt="logo" />
                                        <p className='month_title mb-0'>July</p>
                                        <p className='year_title mb-0'>2022</p>

                                      </div>

                                    </div>
                                  </div>
                                  <div className='card card_calendar'>
                                    <div className='card-body'>
                                      <div className='text-center'>
                                        <img src={calendar} className="img-fluid" alt="logo" />
                                        <p className='month_title mb-0'>August</p>
                                        <p className='year_title mb-0'>2022</p>

                                      </div>

                                    </div>
                                  </div>
                                  <div className='card card_calendar'>
                                    <div className='card-body'>
                                      <div className='text-center'>
                                        <img src={calendar} className="img-fluid" alt="logo" />
                                        <p className='month_title mb-0'>September</p>
                                        <p className='year_title mb-0'>2022</p>

                                      </div>

                                    </div>
                                  </div>
                                  <div className='card card_calendar'>
                                    <div className='card-body'>
                                      <div className='text-center'>
                                        <img src={calendar} className="img-fluid" alt="logo" />
                                        <p className='month_title mb-0'>October</p>
                                        <p className='year_title mb-0'>2022</p>

                                      </div>

                                    </div>
                                  </div>
                                  <div className='card card_calendar'>
                                    <div className='card-body'>
                                      <div className='text-center'>
                                        <img src={calendar} className="img-fluid" alt="logo" />
                                        <p className='month_title mb-0'>November</p>
                                        <p className='year_title mb-0'>2022</p>

                                      </div>

                                    </div>
                                  </div>
                                  <div className='card card_calendar'>
                                    <div className='card-body'>
                                      <div className='text-center'>
                                        <img src={calendar} className="img-fluid" alt="logo" />
                                        <p className='month_title mb-0'>December</p>
                                        <p className='year_title mb-0'>2022</p>

                                      </div>

                                    </div>
                                  </div>
                                  <div className='card card_calendar'>
                                    <div className='card-body'>
                                      <div className='text-center'>
                                        <img src={calendar} className="img-fluid" alt="logo" />
                                        <p className='month_title mb-0'>January</p>
                                        <p className='year_title mb-0'>2023</p>

                                      </div>

                                    </div>
                                  </div>
                                  <div className='card card_calendar'>
                                    <div className='card-body'>
                                      <div className='text-center'>
                                        <img src={calendar} className="img-fluid" alt="logo" />
                                        <p className='month_title mb-0'>February</p>
                                        <p className='year_title mb-0'>2023</p>

                                      </div>

                                    </div>
                                  </div>
                                  <div className='card card_calendar'>
                                    <div className='card-body'>
                                      <div className='text-center'>
                                        <img src={calendar} className="img-fluid" alt="logo" />
                                        <p className='month_title mb-0'>March</p>
                                        <p className='year_title mb-0'>2023</p>

                                      </div>

                                    </div>
                                  </div>
                                  <div className='card card_calendar'>
                                    <div className='card-body'>
                                      <div className='text-center'>
                                        <img src={calendar} className="img-fluid" alt="logo" />
                                        <p className='month_title mb-0'>April</p>
                                        <p className='year_title mb-0'>2023</p>

                                      </div>

                                    </div>
                                  </div>
                                  <div className='card card_calendar'>
                                    <div className='card-body'>
                                      <div className='text-center'>
                                        <img src={calendar} className="img-fluid" alt="logo" />
                                        <p className='month_title mb-0'>May</p>
                                        <p className='year_title mb-0'>2023</p>

                                      </div>

                                    </div>
                                  </div>
                                  <div className='card card_calendar'>
                                    <div className='card-body'>
                                      <div className='text-center'>
                                        <img src={calendar} className="img-fluid" alt="logo" />
                                        <p className='month_title mb-0'>June</p>
                                        <p className='year_title mb-0'>2023</p>

                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </Scrollbars>

                            </Tab>

                          </Tabs>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header><p className='me-3'>Who</p><p>Add Guest</p></Accordion.Header>
                        <Accordion.Body>

                          {/* <Counter /> */}

                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="experiences">
        Experiences
        </Tab.Pane>
        <Tab.Pane eventKey="onlineexperiences">
        Online Experiences
         </Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>

      </Modal>
      <Toaster />
    </div>
  )
}

export default Header;