import { combineReducers } from "redux"; 

export const login = "login";
export const logout = "logout";

const initialUserData = {
    // appId : "",
    // bookingInfo : [],
    // createdAt : "",
    // email : "",
    // firstName : "",
    // isHost : false,
    // lastName : "",
    // loginType : "",
    // otp : 0,
    // token : "",
    // updatedAt : "",
    // userId : "",
    // wishList : [],
    // __v : 0,
    // _id : ""
}

function getUserDataAction(state=initialUserData, action) {
    console.log("getUserDataAction in action.js redux",state,action)
    switch (action.type) {
      
      case login:
        return {
        ...state,
        ...action.loginData
        };

      case logout:
        return {
        ...state,
        ...action.logoutData
        };
      default:
        return state;
    }
    }

    const userDataActions = combineReducers({
        getUserDataAction
    });

    export default userDataActions;