import React, { Component, useEffect, useState } from "react";
import {
  Dropdown,
  Tabs,
  Tab,
  Row,
  Col,
  Nav,
  Modal,
  Accordion,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
// import { Map, GoogleApiWrapper } from 'google-maps-react';
import logo from "../../Assets/images/favicon.png";
import user from "../../Assets/images/user.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { toastOptions } from "../../config/env";
// places
import Start from "./Propertytype/Start";
import host from "../../Assets/images/hostBg.png";
import PlaceType from "./Propertytype/PlaceType";
import HostQuestion from "../BecomeHost/HostQuestion";
import DescribePlace from "../BecomeHost/Propertytype/DescribePlace";
import PlaceLocation from "../BecomeHost/Propertytype/PlaceLocation";
import Amenities from "./Propertytype/Amenities";
import Space from "./Propertytype/Space";
import PlacePhotos from "./Propertytype/PlacePhotos";
import CreateTitle from "./Propertytype/CreateTitle";
import Highlights from "./Propertytype/Highlights";
import CreateDescription from "./Propertytype/CreateDescription";
import SetPrice from "./Propertytype/SetPrice";
import LastQuest from "./Propertytype/LastQuest";
import CheckList from "./Propertytype/CheckList";
import GuestCount from "./Propertytype/GuestCount";

import HostContent from "../../config/HostContent.json";

import { getHostingDetails } from "../../Hooks/useUserHostingHook";
import {
  uploadHostImages,
  createHostUser,
} from "../../Hooks/useUserHostingHook";
import CreateDescriptionContent from "./Propertytype/DescriptionContent";
import { getUserDatas, getUserEmail } from "../../Hooks/useUserHook";
import RoomCategory from "./Propertytype/RoomCaegory";
import CancellationPolicy from "./Propertytype/CancellationPolicy";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import CustomFilterHeader from "./AfterHost/CustomFilterHeader";

const PropertyType = () => {
  const [count, setCount] = useState(0);
  console.log("setcount hihi", count);
  const [hostDatas, setHostDatas] = useState([]);
  console.log("hostData------------------s",hostDatas, hostDatas[6],hostDatas[7]);
  const [userHostDatas, setUserHostDatas] = useState({});
  console.log("userHostdatas", userHostDatas.photos);
  const [email, setEmail] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);
  console.log("btnStatus", btnStatus);
  const [userDatas, setUserDatas] = useState({});
  console.log("userDatas", userDatas.photos);
  const [Image, setImageData] = useState([]);
  const [video , setVideo] = useState({});
  console.log("consolegal", Image);

  const getHostDatasFunction = async () => {
    const emailid = window.location.pathname.split("/")[2];
    if (
      emailid == "undefined" ||
      emailid == undefined ||
      emailid != getUserEmail()
    ) {
      window.location.href = window.location.origin + "/";
    }
    console.log("email id :", emailid);
    setEmail(emailid);
    setUserDatas(getUserDatas());
    getHostingDetails().then((data) => {
      console.log("data from gethosting data function", data);
      setHostDatas(data);
    });
  };

  const getTitle = (data) => {
    if (data.length == 0) {
      setBtnStatus(false);
    } else {
      console.log("data from get title:", data);
      userHostDatas.title = data;
      localStorage.setItem("title", data);
      console.log("getTitle hihi");
      setBtnStatus(true);
    }
  };

  const getPlaceType = (data) => {
    console.log("data from get place type:", data);
    userHostDatas.propertyTypeGroup = data;
    localStorage.setItem("propertyTypeGroup", JSON.stringify(data));
    console.log("getPlaceType hihi");
    setBtnStatus(true);
  };

  const getDescribePlace = (data) => {
    console.log("data from get describe place:", data);
    userHostDatas.propertyType = data;
    localStorage.setItem("propertyType", JSON.stringify(data));
    console.log("getDescribePlace hihi");
    setBtnStatus(true);
  };

  const getPlaceLocation = (data) => {
    console.log("data from get place location:", data);
    userHostDatas.address = data;
    localStorage.setItem("address", JSON.stringify(data));
    setBtnStatus(true);
    console.log("getPlaceLocation hihi" , btnStatus);
  };

  const getAmenities = (data) => {
    console.log("data from get amenities:", data);
    userHostDatas.amenities = data;
    localStorage.setItem("amenities", JSON.stringify(data));
    if (data?.length == 0) {
      setBtnStatus(false);
    } else {
      console.log("getAmenities hihi");
      setBtnStatus(true);
    }
  };

  const getSpace = (data) => {
    console.log("data from get content:", data);
    userHostDatas.privacyType = data;
    localStorage.setItem("privacyType", data);
    console.log("getSpace hihi");
    setBtnStatus(true);
  };

  // const toBase64 = file => new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = error => reject(error);
  // });
  const toBase64 = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    console.log("reader.result : ", reader.result);
    // Promise.all(result).then(response=)
    reader.onload = () => reader.result;
    return reader.result;
  };

  const getPhotos = (data) => {
    try {
      var arr = [];
      var obj = {};
      console.log("datssgalss", JSON.stringify(data));
      setImageData(data);
      setBtnStatus(true);
      userHostDatas.photos = data;
      Array.from(data).forEach((val) => {
        obj.lastModified = val.lastModified;
        obj.lastModifiedDate = val.lastModifiedDate;
        obj.name = val.name;
        obj.size = val.size;
        obj.type = val.type;
        obj.webkitRelativePath = val.webkitRelativePath;
        arr.push(obj);
      });
      localStorage.setItem("photos", JSON.stringify(arr));
      console.log("getPhotos hihi");
      setBtnStatus(true);
    } catch (e) {
      console.log("errrrr", e);
    }
  };

  const getVideo = (data) => {
    try {
      var arr = [];
      var obj = {};
      console.log("datssgalss", JSON.stringify(data));
      setVideo(data);
      // setBtnStatus(true);
      userHostDatas.video = data;
      // Array.from(data).forEach((val) => {
      //   obj.lastModified = val.lastModified;
      //   obj.lastModifiedDate = val.lastModifiedDate;
      //   obj.name = val.name;
      //   obj.size = val.size;
      //   obj.type = val.type;
      //   obj.webkitRelativePath = val.webkitRelativePath;
      //   arr.push(obj);
      // });
      // localStorage.setItem("photos", JSON.stringify(arr));
      // console.log("getPhotos hihi");
      // setBtnStatus(true);
    } catch (e) {
      console.log("errrrr", e);
    }
  };

  const getHighlights = (data) => {
    console.log("data from get highlights:", data);
    userHostDatas.description = data;
    localStorage.setItem("description", JSON.stringify(data));
    if (data.length == 0) {
      setBtnStatus(false);
    } else {
      console.log("getHighlights hihi");
      setBtnStatus(true);
    }
  };

  const getPrice = (data) => {
    console.log("data from get price:", data);
    if (data == 0) {
      setBtnStatus(false);
    } else {
      if(data?.price)
      {userHostDatas.price = data?.price;}
      else{
        setBtnStatus(false);
        return
      }
      if(data?.checkin)
       {userHostDatas.checkintime = data?.checkin}
      else{
        setBtnStatus(false);
        return
      }
        
      if(data?.checkout)
        {userHostDatas.checkouttime = data?.checkout}
        else{
          setBtnStatus(false);
          return
        }
      localStorage.setItem("price", data);
      console.log("getPrice hihi");
      setBtnStatus(true);
    }
  };

  const getLastQuest = (data) => {
    console.log("data from get last quest:", data);
    userHostDatas.legal = data;
    // localStorage.setItem("legal",JSON.stringify(data));
    localStorage.setItem("legal", data);
    console.log("getLastQuest hihi");
    setBtnStatus(true);
  };

  const getCheckList = (data) => {
    console.log("data from get check list:", data);
    userHostDatas.instantBooking = data;
    localStorage.setItem("instantBooking", data);
    console.log("getCheckList hihi");
    setBtnStatus(true);
  };

  const getGuestCount = (data) => {
    console.log("data from get guest count:", data);
    if(data.reduce(
      (accumulator, currentValue) => accumulator + parseFloat(currentValue?.count),
      0,
    ) > 0){
      userHostDatas.floorPlan = data;
    localStorage.setItem("floorPlan", JSON.stringify(data));
    console.log("getGuestCount hihi");
    setBtnStatus(true);
    }
    else{
      setBtnStatus(false);
    }
  };

  const getDescriptionContent = (data) => {
    if (data.length == 0) {
      setBtnStatus(false);
    } else {
      if(userHostDatas.rules?.length == 0){
        setBtnStatus(false);
      }
      else{
        setBtnStatus(true);
      }
      console.log("getDescriptionContent", data);
      userHostDatas.descriptionContent = data;
      localStorage.setItem("descriptionContent", data);
      console.log("getDescriptionContent hihi");
      // setBtnStatus(true);
    }
  };

  const getrules = (data) => {
    if (data.length == 0) {
      setBtnStatus(false);
    }
    else {
      if(userHostDatas.descriptionContent?.length == 0){
        setBtnStatus(false);
      }
      else{
        setBtnStatus(true);
      }
      console.log("getDescriptionContent", data);
      userHostDatas.rules = data;
      localStorage.setItem("rules", data);
      console.log("getDescriptionContent hihi");
      
    }
  }

  // const getroomcheckin = (data) => {
  //   userHostDatas?.checkintime = data;
  // }
  console.log("userHostDatas", userHostDatas);
  const createHost = async () => {
    setBtnStatus(false);
    console.log("hello");
    userHostDatas.email = email;
    userHostDatas.firstName = userDatas.firstName;
    userHostDatas.lastName = userDatas.lastName;
    // userHostDatas.cancellationPolicy = "flexible";
    // userHostDatas.instantBooking = false;
    console.log(
      "userHostDatas.title",
      userHostDatas.title,
      "userHostDatas.propertyTypeGroup",
      userHostDatas.propertyTypeGroup,
      "userHostDatas.propertyType",
      userHostDatas.propertyType,
      "userHostDatas.address",
      userHostDatas.address,
      "userHostDatas.amenities",
      userHostDatas.amenities,
      "&userHostDatas.privacyTyp",
      userHostDatas.privacyType,
      "userHostDatas.photos",
      userHostDatas.photos,
      "userHostDatas.description",
      userHostDatas.description,
      "userHostDatas.price",
      userHostDatas.price,
      "userHostDatas.legal",
      userHostDatas.legal,
      "userHostDatas.floorPlan",
      userHostDatas.floorPlan,
      "userHostDatas.descriptionContent",
      userHostDatas.descriptionContent,
      "userHostDatas.cancellationPolicy",
      userHostDatas.cancellationPolicy,
      "userHostDatas.category",
      userHostDatas.category
    );
    if (
      userHostDatas.title &&
      userHostDatas.propertyTypeGroup &&
      userHostDatas.propertyType &&
      userHostDatas.address &&
      userHostDatas.amenities &&
      userHostDatas.privacyType &&
      userHostDatas.photos &&
      userHostDatas.description &&
      userHostDatas.price &&
      userHostDatas.legal &&
      userHostDatas.floorPlan &&
      userHostDatas.descriptionContent &&
      userHostDatas.cancellationPolicy &&
      userHostDatas.category
    ) {
      console.log("createhost", hostDatas[4].path);
      const path = { path: hostDatas[4].path };
      userHostDatas.amenities.push(path);
      console.log("userHostDatas in createhost:", userHostDatas);
      await createHostUser(userHostDatas);
      toast.success(`Successfully Hosted ${userHostDatas.title}`, toastOptions);
      localStorage.clear("title");
      localStorage.clear("propertyTypeGroup");
      localStorage.clear("propertyType");
      localStorage.clear("address");
      localStorage.clear("amenities");
      localStorage.clear("privacyType");
      localStorage.clear("photos");
      localStorage.clear("description");
      localStorage.clear("price");
      localStorage.clear("legal");
      localStorage.clear("instantBooking");
      localStorage.clear("floorPlan");
      localStorage.clear("descriptionContent");
      localStorage.clear("category");
      localStorage.clear("cancellationPolicy");
      localStorage.clear("rules");
      window.location.href =
        window.location.origin + `/host-dashboard/listings/${email}/stays`;
    } else {
      toast.error("Enter all datas to host!", toastOptions);
    }
  };

  // const settrue = () => {
  //     console.log('set true hihi')
  //     setBtnStatus(true);

  // }

  const btnFunction = () => {
    setCount(count + 1);
    setBtnStatus(false);
    console.log("func1 hihi");
  };

  const btnFunction2 = () => {
    console.log("func2 hihi");
    setCount(count - 1);
    setBtnStatus(true);
  };

  const getPlaceCategory = (data) => {
    console.log("data get from category :", data);
    userHostDatas.category = data;
    localStorage.setItem("category", JSON.stringify(data));
    if (data?.length == 0) {
      setBtnStatus(false);
    } else {
      console.log("setplacecategory hihi");
      setBtnStatus(true);
    }
  };

  const getCancellationPolicy = (data) => {
    console.log("cancellationPolicy", data);
    localStorage.setItem("cancellationPolicy", data);
    userHostDatas.cancellationPolicy = data;
    console.log("getCancellationPolicy hihi");
    setBtnStatus(true);
  };

  useEffect(() => {
    getHostDatasFunction();
  }, []);

  const statuschng = () => {
    console.log("category lemngth", localStorage.getItem("category"));
    JSON.parse(localStorage.getItem("category"))?.length > 0
      ? setBtnStatus(true)
      : setBtnStatus(false);
  };

  const statuschange = () => {
    if (Image.length > 0) {
      setBtnStatus(true);
    } else {
      setBtnStatus(false);
    }
  };
  const statuschngammenities = () => {
    JSON.parse(localStorage.getItem("amenities"))?.length > 0
      ? setBtnStatus(true)
      : setBtnStatus(false);
  };
  {
    console.log("hostDatas", hostDatas[0]);
  }
  {
    console.log("hostDatas1", hostDatas[1]);
  }
  {
    console.log("hostDetailsssssssssssssssssss", hostDatas, hostDatas[8]);
  }
  const switchFunction = () => {
    // console.log(count,'count val')
    let content;
    switch (count) {
      case 0: //property-type-group
        content = (
          <PlaceType data={hostDatas[0]} onGetPlaceType={getPlaceType} />
        );
        break;
      case 1: //property-type
        content = (
          <DescribePlace
            data={hostDatas[1]}
            onGetDescribePlace={getDescribePlace}
          />
        );
        break;
      case 2: //privacy-type
        content = <Space data={hostDatas[2]} onGetSpace={getSpace} />;
        break;
      case 3:
        content = (
          <RoomCategory
            data={hostDatas[7]}
            onGetPlaceCategory={getPlaceCategory}
            onstatus={statuschng}
          />
        );
        break;
      case 4: //address
        content = <PlaceLocation onGetPlaceLocation={getPlaceLocation} />;
        break;
      case 5: //floor plan
        content = (
          <GuestCount data={hostDatas[8]} onGuestCount={getGuestCount} />
        );
        break;
      case 6: //privacy-type
        content = <LastQuest data={hostDatas[5]} onLastQuest={getLastQuest} />;
        // content = (
        //   <CancellationPolicy
        //     data={hostDatas[6]}
        //     onGetCancellationPolicy={getCancellationPolicy}
        //   />
        // );
        break;

      case 7: //amenities
        // content = <Amenities data={hostDatas[4]} onGetAmenities={getAmenities}
        content = (
          <Amenities
            data={hostDatas[3]}
            onGetAmenities={getAmenities}
            onstatus={statuschngammenities}
          />
        );
        break;
      case 8: //photos
        content = (
          <PlacePhotos
            onGetPhotos={getPhotos}
            Images={Image}
            onstatus={statuschange}
            onGetVideo = {getVideo}
          />
        );
        break;
      case 9: //title
        content = <CreateTitle onGetTitle={getTitle} />;
        break;
      case 10: //description
        content = (
          <Highlights data={hostDatas[4]} onGetHighlights={getHighlights} />
        );
        break;
      case 11: //description
        content = (
          <CreateDescriptionContent
            onGetDescriptionContent={getDescriptionContent}
            onGetRules = {getrules}
          />
        );
        break;
      case 12: //price
        content = <SetPrice onGetPrice={getPrice} />;
        break;
      case 13: //legal
        // content = <LastQuest data={hostDatas[5]} onLastQuest={getLastQuest} />;
        content = (
          <CancellationPolicy
            data={hostDatas[6]}
            onGetCancellationPolicy={getCancellationPolicy}
          />
        );
        break;
      case 14: //preview
        content = (
          <CheckList data={userHostDatas} onGetCheckList={getCheckList} />
        );
        break;
    }

    return content;
  };
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();
  const navigatehosthome = () => {
    navigate("/hosting");
  };
  const nextbtn = () => {
    setCounter(counter + 1);
    if (counter == 15) {
      navigatehosthome();
    }
  };
  return (
    <>
      <div id="proptype" className="property-type">
        <Header />
        <div
          className={
            counter == 14
              ? "propstype bg-dark min-height-100vh pb-5"
              : "propstype  min-height-100vh pb-5"
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-md-4 themebg">
                <div className="row justify-content-center qustheight">
                  <div className="col-lg-12 d-flex">
                    <div className="place-questions">
                      <div className="p-4 d-flex flex-column align-items-center justify-content-center">
                        <div className="host-img-sec">
                          <img src={host} alt="host" />
                        </div>
                        <p>{HostContent[count].quest}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  counter == 14
                    ? "col-md-8 bg-dark text-white mt-md-0 mt-0 col_heght_righ_new"
                    : "col-md-8 mt-md-0 mt-4 col_heght_righ_new"
                }
              >
                <div className="row mb-2">
                  <div className="headercont">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 props-head">
                      <ul className="mb-0 ps-0">
                        <li>
                          <button
                            onClick={() => {
                              window.location.href =
                                window.location.origin + "/";
                            }}
                            className="gray-btn save-edit-btn"
                          >
                            Save and exit
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {hostDatas && switchFunction()}
                </div>

                <div className="fixeddiv" id="progressdiv" data-counter={count}>
                  <Button
                    className="ms-3 back-btn"
                    variant="link"
                    onClick={() => {
                      count != 0 ? btnFunction2() : setCount(0);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    className="me-3 enable next-btn"
                    variant="light"
                    disabled={!btnStatus}
                    onClick={() => {
                      count != 14 ? btnFunction() : createHost();
                    }}
                  >
                    {count != 14 ? "Next" : "Create"}
                  </Button>{" "}
                </div>
              </div>
            </div>
            <div className="" />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PropertyType;
