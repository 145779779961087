import React, { Component, useEffect, useMemo, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Cookies from "universal-cookie";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { API_URL } from "../../config/env";
import { Link } from "react-router-dom";
import { getUserEmail, getusersdatahooks } from "../../Hooks/useUserHook";
// import { addwishlist , getwishlistarray} from '../../Hooks/filterHook';
import Login from "../Common/Modals/LoginModal.js";
import { getOverAllRating } from "../../Hooks/useUserHostingHook";

const cookies = new Cookies();

const Card = (props) => {
  console.log("dsadvhnsavdhasd", props);
  // const [wishedlist , setWishedlist] = useState([]);
  const [email, setEmail] = useState("");
  const [loginModal, setLoginModal] = useState(false);
  const [aaa, setAaa] = useState("");
  const [roomRating, setRoomRating] = useState({});
  const [userdata , setUserdata] = useState({});
  const [photos,setPhotos] = useState([]);
  const [isWishlisted,setWishlisted] = useState("fav-btn fa fa-heart-o")
  const [wishref , setWishref] = useState(1);
  const handleFav = async () => {
    const mail = getUserEmail();
    console.log("fskdfksadf", mail)
    //     // console.log("handlefav:",email,"mail",mail);
    //     if(mail){
    //      // var imageid = props.data._id
    // // console.log("props :" , props);
    // let payload = {
    //     roomid : props.data._id,
    //     emailid : await mail
    // }
    // // console.log("payload" , payload);
    // await addwishlist(payload)
    //  .then((data) => { console.log("wishlist r eturn: " , data);
    //  setWishedlist(data);
    //  alert("wishlist updated!");
    // })
    //  .catch((e) => console.log("wishlist return error" , e))
    //    }else{
    //     setLoginModal(true);
    //    }
    console.log("email " , mail);
    if (mail) {
      let updt = await props.wishlistarray(props.data._id, mail);
      getusersdatahooks(mail);
    } else {
      setLoginModal(true);
    }
  };
  console.log("wishref" , wishref);


  const renderPhotos = (_photos,_btnClass) =>{
    console.log("dsadhgsahgdas REnder PHOTOS : ",props.data._id,_btnClass,isWishlisted,_photos[0]?.isWishlisted)
   return _photos.map((j) => (
    singlePhoto(j,_btnClass)
   ))
   
  }

  const singlePhoto = (j,_btnClass)=>{
    console.log("dsadhgsahgdas Single Photo : ",j,_btnClass)
    return ( <div class="item">
    {/* <Link to={"/room/"+props.data._id}> */}
    {/* {console.log("API_URL+props.data.photosPath+/j.name",`${API_URL}${props.data.photosPath}/${j.name}`)} */}
    <img src={`${API_URL}${props.data.photosPath}/${j.name}`} />
    {/* <p className="name themeclr fw-600 f-14">{userdata?.wishList?.includes(props.data._id)}   {`data :: ${BtnClass} : props : ${props.data._id} :: REsulr : ${userdata?.wishList?.includes(props.data._id)} :: New  ; ${isWishlisted} :: photots ${j?.isWishlisted} `}</p> */}
    {/* <button onClick={handleFav} className={(email&&props.data._id.includes(props.userData.wishList))?'fav-btn fa fa-heart':'fav-btn fa fa-heart-o'}></button> */}
    {/* <button onClick={handleFav} className={(props.userData.wishList.includes(props.data._id))?'fav-btn fa fa-heart':'fav-btn fa fa-heart-o'}></button> */}
   <button onClick={handleFav} className={_btnClass} />
    
    {/* </Link> */}
  </div>)
  }



  useEffect(() => {
    // console.log("dsadhgsahgdas props?.userData" , props);
    // const newPhotos = props?.data?.photos.map(v => ({...v, isWishlisted: props?.userData?.wishList?.find((item) => item == props?.data._id) ? "fav-btn fa fa-heart" : "fav-btn fa fa-heart-o"}));
    // setTimeout(()=>{
      // setUserdata(props?.userData)
      // setPhotos(newPhotos)
      // setWishlisted(props?.userData?.wishList?.find((item) => item == props?.data._id) ? "fav-btn fa fa-heart" : "fav-btn fa fa-heart-o")
      setWishref((wish) => wish + 1)
    // } , 1000);
  } , [props])

  // useEffect(()=>{
  //   renderPhotos(photos,isWishlisted)
  // },[photos,isWishlisted])

  useEffect(() => {
    console.log("initial UseEffect");
    (async () => {
      // props.wishdata()
      // setWishedlist(props.userData.wishList);
      const mail = await getUserEmail();
      // const wishlistarray = await getwishlistarray(mail);
      //         setWishedlist(wishlistarray);

      //         console.log("wishlist array" , wishlistarray.data.record[0]);
      if (mail) {
        setEmail(mail);

        // wishdata();
      }
    })();
  }, []);

  useEffect(() => {
    if (props.data._id) {
      getOverAllRating(props.data._id).then((data) => {
        setRoomRating(data.data);
      });
    }
  }, [props.data._id]);

  const BtnClass = useMemo(() => {
    console.log("dsadhgsahgdas prop id", props.data._id,userdata?.wishList?.some((item) => item == props.data._id));
    //    if(email){
    if (userdata?.wishList?.find((item) => item == props.data._id)) {
      return "fav-btn fa fa-heart";
    } else {
      console.log("False");
      return "fav-btn fa fa-heart-o";
    }
    //    }
  }, [email,userdata]);

  return (
    <div className="grid-5 mt-4">
      {loginModal && (
        <Login
          onDismiss={() => {
            setLoginModal(false);
          }}
        />
      )}
      {/* {BtnClass && console.log("dsadhgsahgdas : ",photos) } */}
      { 
      // BtnClass && 
      <div className="where-card yellownav">
        <div className="card-img">
          <OwlCarousel
            className="owl-theme"
            dots={false}
            margin={10}
            nav={true}
            items={1}
            id="big"
          >
            {props?.data?.photos && 
                props?.data?.photos.map((j) => 
                 {if(j?.name?.split(".")[1]!="mp4") {return(<div class="item">
                  <Link target = "_blank" to={"/room/" + props.data._id}>
                    <img src={`${API_URL}${props.data.photosPath}/${j.name}`} />
                    </Link>
                    <button onClick={handleFav} className={props?.userData?.wishList?.find((item) => item == props.data._id) ? "fav-btn fa fa-heart" : "fav-btn fa fa-heart-o"}></button>
                 
                  </div>)}}
                )
            }
            {/* {photos && renderPhotos(photos,isWishlisted)} */}
          </OwlCarousel>
        </div>

        <Link target = "_blank" to={"/room/" + props.data._id} className="nounder">
          <div className="where-cont mt-3">
            <div className="d-flex justify-content-between">
              <p className="name themeclr fw-600 f-14">{props.data.title}</p>
              <p className="fav txtgray f-12">
                New<span className="fa fa-star ms-1" />
              </p>
            </div>
            {/* <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>{roomRating&&roomRating.overAllReview&&(roomRating.overAllReview).toFixed(1) +"("+roomRating?.users+")"}</p> */}
            <p className="txtgray f-12">
              Hosted by {props.data.firstName} {props.data.lastName}
            </p>
            <p className="date themeclr fw-600 card-date-custom f-12">12 Jul</p>
            <p className="rent themeclr f-14 fw-600 card-amount-dis">
              {props?.symbol} {props.data.price} per Day
            </p>
          </div>
        </Link>
      </div> }
     
    </div>
  );
};

export default Card;
