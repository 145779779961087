import axios from 'axios';
import Cookies from 'universal-cookie';
import {createExperienceApi, deleteExperienceApi, getExperienceCategoriesApi,getExperienceDatasApi,getExperienceProvisionsApi, getUserExperienceHostingsApi,bookingExperienceApi, bookExperienceCreatePayments,bookExperienceVerifyPayments, getBookingExperienceDetails, checkExperienceBookingStatus,confirmPayExperience} from '../config/userRouteFront';
import { API_URL } from '../config/env';
import { getUserDatas } from './useUserHook';
import { currency } from '../config/currencycode';
const cookies = new Cookies();

export const getExperienceCategories = async () => {
    let dataToReturn;
    await axios.get(`${API_URL}${getExperienceCategoriesApi}`).then((data)=>{
        dataToReturn = data.data;
    })
    return dataToReturn;
}

export const getExperienceProvisions = async () => {
    let dataToReturn;
    await axios.get(`${API_URL}${getExperienceProvisionsApi}`).then((data)=>{
        dataToReturn = data.data;
    })
    return dataToReturn;
}

export const getExperienceFromLocal = () => {
    return JSON.parse(localStorage.getItem("experienceDetails"));
}

// export const createExperience = () => {
//     const data = getExperienceFromLocal();
//     const userData = getUserDatas();
//     console.log("data in create experience :",data);
// }

export const dataURLtoFile = (dataurl, filename) => {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

export const createExperience = async () => {
    let dataToReturn;
    const hostDatas = getExperienceFromLocal();
    const userData = getUserDatas();

    const bodyformData = new FormData();
    for (var i = 0; i < hostDatas.photos.length; i++) {
        var file = dataURLtoFile(hostDatas.photos[i].data,hostDatas.photos[i].name);
        console.log("file inside create experience :",file);
        bodyformData.append(`Files${i}`, file)
    }
    bodyformData.append('email',userData.email);
    bodyformData.append('firstName',userData.firstName);
    bodyformData.append('lastName',userData.lastName);

    bodyformData.append('country',hostDatas.country);
    bodyformData.append('language',"english");
    bodyformData.append('category',JSON.stringify(hostDatas.categories));
    bodyformData.append('startTime',hostDatas.date[0]);
    bodyformData.append('endTime',hostDatas.date[1]);
    bodyformData.append('title',hostDatas.title);
    bodyformData.append('tagline',hostDatas.tagLine);
    bodyformData.append('whatYouWillDo',hostDatas.whatYouWillDo);
    bodyformData.append('whereYouWillBe',hostDatas.whereYouWillBe);
    bodyformData.append('whatGuestShouldKnow',JSON.stringify(hostDatas.whatGuestShouldKnow));
    bodyformData.append('bio',hostDatas.bio);
    bodyformData.append('maxGuest',hostDatas.maxGuest);
    bodyformData.append('meetingLocation',JSON.stringify(hostDatas.meetingLocation));
    bodyformData.append('whatYouWillProvide',JSON.stringify(hostDatas.whatYouWillProvide));
    bodyformData.append('whoCanAttend',JSON.stringify(hostDatas.whoCanAttend));
    bodyformData.append('pricePerGuest',hostDatas.pricePerGuest);
    bodyformData.append('preparationTime',JSON.stringify(hostDatas.preparationTime));
    bodyformData.append('packingList',JSON.stringify(hostDatas.packingList));
    bodyformData.append('termsAndConditions',JSON.stringify(hostDatas.termsAndConditions));
          
    for (const value of bodyformData.values()) {
        console.log("values of formdata inside useUserHostingHook:",value);
    }

    await axios({
        'method': 'post',
        'url': API_URL+createExperienceApi,
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': cookies.get('accessToken')?`Bearer ${cookies.get('accessToken')}`:((cookies.get('User-Datas'))?`Bearer ${(cookies.get('User-Datas')).token}`:"")
        },
        'data': bodyformData,

    })
    .then((data)=>{
        // cookies.set('User-Datas',data.data, { path: '/'},{expires: new Date(Date.now()+600000) })
        dataToReturn = data.data;
        localStorage.removeItem("experienceDetails");
    })
    return dataToReturn;
}

export const getUserExperienceHostings = async (email) => {
    let dataToReturn;
    await axios.get(API_URL+getUserExperienceHostingsApi+"/"+email).then((data)=>{
        dataToReturn = data.data;
    });
    return dataToReturn;
}

export const deleteExperience = async (id) => {
    let dataToReturn;
    await axios.get(API_URL+deleteExperienceApi+"/"+id).then((data)=>{
        dataToReturn = data.data;
    });
    return dataToReturn;
}

export const getExperienceDatas = async (start, end , curr) => {
    let data = {
        starting:start,
        ending:end,
        currency : curr
    }
    let dataToReturn;
    await axios.post(API_URL+getExperienceDatasApi,data).then((datas)=>{
        dataToReturn = datas.data;
    });
    return dataToReturn;
}

export const bookingExperienceDetails = async (data) => {
    let dataToReturn;
    await axios.post(API_URL+bookingExperienceApi,data).then((responce) => {
        console.log('RESPONCE DATA', responce.data)
        dataToReturn = responce.data;
    });
    return dataToReturn;
}

export const experienceCreateRazorPayment = async (data) => {
    console.log('data 2:',data)
    let dataToReturn;
    await axios.post(API_URL+bookExperienceCreatePayments,data).then((responce) => {
        dataToReturn = responce.data;
    });
    return dataToReturn
}

export const experienceVerifyRazorPayment = async (data) => {
    console.log('data 2:',data)
    let dataToReturn;
    await axios.post(API_URL+bookExperienceVerifyPayments,data).then((responce) => {
        dataToReturn = responce.data;
    });
    return dataToReturn
}

export const getExperiencsDetails  = async (id) => {
    console.log('Hook data:',id);
    let dataToReturn;
    await axios.get(API_URL+getBookingExperienceDetails+"/"+id).then((responce) => {
        dataToReturn = responce.data
        console.log('REsponce',responce.data);
    });
    return dataToReturn;
}

export const checkExperienceBookStatus  = async (id) => {
    console.log('Hook data:',id);
    let dataToReturn;
    await axios.get(API_URL+checkExperienceBookingStatus+"/"+id).then((responce) => {
        dataToReturn = responce.data
        console.log('REsponce',responce.data);
    });
    return dataToReturn;
}


export const confirmPaymentExperience = async (id) => {
    console.log("Hooks dta: ",id);
    let data = {
        Id: id
    }
    let dataToReturn;
    await axios.post(API_URL+confirmPayExperience,data).then((responce) => {
        dataToReturn = responce.data
        console.log('REsponce',responce.data);
    });
    return dataToReturn;
}