import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import AddPhotos from './AddPhotos';
import ConfirmProvides from './ConfirmProvides';
import CreateHost from './CreateHost';
import ExperianceSide from './ExperianceSide';
import ExpTitle from './ExpTitle';
import FinishTouch from './FinishTouch';
import GuestKnows from './GuestKnows';
import MeetingLocation from './MeetingLocation';
import MentionBe from './MentionBe';
import MentionDo from './MentionDo';
import MinimumGuest from './MinimumGuest';
import SetTime from './SetTime';
import TagLine from './TagLine';
import TypesOfExp from './TypesOfExp';
import WhoCan from './WhoCan';
import WriteBio from './WriteBio';

import ReviewExperience from './ReviewExperience';
import CreatePack from './CreatePack';
import TimePrepare from './TimePrepare';
import SetPrice from './SetPrice';
import img1 from "../../../../Assets/images/where/img1.png"
import { propTypes } from 'react-bootstrap/esm/Image';
import { createExperience, getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';
import toast from 'react-hot-toast';
import { toastOptions } from '../../../../config/env';
import { useNavigate } from 'react-router-dom';
import { getUserDatas } from '../../../../Hooks/useUserHook';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

const ExperianceBase = (props) =>{
    const[steps , setSteps] = useState(1)
    const[title, setTitle] = useState("");
    const [tagline, setTagline] = useState("");
    const[willdo, setWillDo] = useState("Give an overview description of what your guests will be doing on this experience.")
    const[notes, setNotes] = useState("Is there anything else you’d like guests to know before booking")
    const[bio, setBio] = useState("Write somethig abou you...")
    const[guestcount, setGuestCount] = useState("2")
    const[groupsize, setGroupSize] = useState("1")
    const[where, setWhere] = useState("Tell your guests where you’ll be taking them for this experience")
    const[provides , setProvides] = useState([])
    const [experienceDatas, setExperienceDatas] = useState({});
    const [userDatas, setUserDatas] = useState({});

    const navigate = useNavigate();

    const changesteps = (data) => {
        setSteps(data)
    }
  
    useEffect(()=>{
        const data = getExperienceFromLocal()
        console.log('datasuuu',data)
        setExperienceDatas(data);
        // setTitle(data.title);
        // setTagline(data.tagLine);
        setUserDatas(getUserDatas());
    },[]);

    const setLocal = async (data,type,previewUrl) => {
        let dataToLocal = (getExperienceFromLocal())?getExperienceFromLocal():{};
        if(type == "categories"){
            dataToLocal.categories = data;
        }
        else if(type == "title"){
            dataToLocal.title = data;
        }
        else if(type == "date"){
            dataToLocal.date = data;
        }
        else if(type == "tagLine"){
            setTagline(data);
            dataToLocal.tagLine = data; 
        }
        else if(type == "photos"){
            console.log("photos in experiance base",data);
            var arr = [];
            var obj = {};
            Array.from(data).forEach((val)=>{
                obj.lastModified = val.lastModified;
                obj.lastModifiedDate = val.lastModifiedDate;
                obj.name = val.name;
                obj.size = val.size;
                obj.type = val.type;
                obj.webkitRelativePath = val.webkitRelativePath;
                arr.push(obj);
            })
            dataToLocal.photos = data;
            console.log("image array",arr,"image object",obj,"dataToLocal",dataToLocal);
            dataToLocal.previewUrl = previewUrl;
        }
        else if(type == "whatYouWillDo"){
            dataToLocal.whatYouWillDo = data;
        }
        else if(type == "whereYouWillBe"){
            dataToLocal.whereYouWillBe = data;
        }
        else if(type == "meetingLocation"){
            dataToLocal.meetingLocation = data;
        }
        else if(type == "whatYouWillProvide"){
            dataToLocal.whatYouWillProvide = data;
        }
        else if(type == "whatGuestShouldKnow"){
            dataToLocal.whatGuestShouldKnow = data;
        }
        else if(type == "bio"){
            dataToLocal.bio = data;
        }
        else if(type == "whoCanAttend"){
            dataToLocal.whoCanAttend = data;
        }
        else if(type == "maxGuest"){
            dataToLocal.maxGuest = data;
        }
        else if(type == "pricePerGuest"){
            dataToLocal.pricePerGuest = data;
        }
        else if(type == "preparationTime"){
            dataToLocal.preparationTime = data;
        }
        else if(type == "packingList"){
            dataToLocal.packingList = data;
        }
        else if(type == "termsAndConditions"){
            dataToLocal.termsAndConditions = data;
            localStorage.setItem("experienceDetails",JSON.stringify(dataToLocal));
            await createExperience().then((result)=>{
                console.log("createExperience ---->",result);
                localStorage.clear("experienceDetails");
                toast.success(result.message,toastOptions);
                // navigate(`/host-dashboard/listings/${userDatas.email}`);
            });
        }
        console.log('dataTolocal',dataToLocal)
        localStorage.setItem("experienceDetails",JSON.stringify(dataToLocal));
    }

    return(
        <div className='d-flex align-items-center'>
        <div className='expsidebar'>
            <ExperianceSide getpage = {(data) => changesteps(data)}/>
        </div>
        <div className='expcont d-flex align-items-center'>
            <div className='container-theme '>
                <div className='shadowbox br-10 p-4'>
                    <div className='row align-items-center  jc-center expcontcontainer'>
                        
                        <div className='col-lg-7'>
{
steps == 1 ? <TypesOfExp onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(2)} /> :
steps == 2 ? <CreateHost count = {() => changesteps(3)} /> : 
steps == 3 ? <ExpTitle onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(4)} exptitle={setTitle}  /> : 
steps == 4 ? <SetTime onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(5)} /> : 
steps == 5 ? <TagLine onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(6)} /> : 
steps == 6 ? <AddPhotos onSetLocal={(data,type,previewUrl)=>setLocal(data,type,previewUrl)} count = {() => changesteps(7)} /> : 
steps == 7 ? <MentionDo onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(8)} expdo = {setWillDo} /> :
steps == 8 ? <MentionBe onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(9)} expbe = {setWhere} /> : 
steps == 9 ? <MeetingLocation onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(10)} />  :  
steps == 10 ? <ConfirmProvides onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(11)} expprovide={setProvides} /> : 
steps == 11 ? <GuestKnows onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(12)} expnotes = {setNotes} /> : 
steps == 12 ? <FinishTouch count = {() => changesteps(13)} /> :  
steps == 13 ? <WriteBio onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(14)} expbio={setBio} /> : 
steps == 14 ? <WhoCan onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(15)} expwhocan={setGuestCount} /> :
steps == 15 ? <MinimumGuest onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(16)} expgroup = {setGroupSize} /> : 
steps == 16 ? <SetPrice onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(17)} /> :
steps == 17 ? <TimePrepare onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(18)}/> : 
steps == 18 ? <CreatePack onSetLocal={(data,type)=>setLocal(data,type)} count = {() => changesteps(19)} /> :
<ReviewExperience onSetLocal={(data,type)=>setLocal(data,type)} />  
}




                    </div>
                    <div className='col-md-5 d-none d-lg-block'>
                    <div className='mob-box'>
                                <div className='mobile '>
                                    <div className='themescroll'>
                                    <div className='card card_right_box border-0'>
                                        <div className='card-header p-0 border-0'>
                                            <div className='img_right_exp'>
                                            <img src={(getExperienceFromLocal()&&getExperienceFromLocal().photos&&getExperienceFromLocal().photos[0].data)?getExperienceFromLocal().photos[0].data:img1} className="img-fluid w-100" />
                                            </div>
                                        </div>
                                       
                                    </div>

                                    <div className='card card_right_box mt-4 shadowbox border-0'>
                                        <div className='card-body'>
                                        <Button variant="link" className="nounder text-start ps-0" onClick={() => setSteps(3)}>
                                            <p className='text_blue_big'>{(getExperienceFromLocal()&&getExperienceFromLocal().title)?getExperienceFromLocal().title:"Experience title"}</p>
                                            <p className='text-muted'>{(getExperienceFromLocal()&&getExperienceFromLocal().tagLine)?getExperienceFromLocal().tagLine:"Your tagline goes here.."}</p>
                                        </Button> 
                                        <hr className='hr_light' />
                                        <p className='text-muted'>{(getExperienceFromLocal()&&getExperienceFromLocal().categories)?getExperienceFromLocal().categories[0]:"Arts & Design Experience"}</p>
                                        <p className='text-muted'>Hosted by <span className='text_blue'>{userDatas&&userDatas.firstName?`${userDatas.firstName} ${userDatas.lastName}`:""}</span></p>

                                        <hr className='hr_light' />
                                        <p className='text-muted'><i class="fa fa-clock-o" aria-hidden="true"></i> 1 Hour total</p>

                                        <hr className='hr_light' />
                                        <Button variant="link" className="nounder text-start ps-0" onClick={() => setSteps(7)}>
                                            <p className='text_blue_big'>What I'll do</p>
                                            <p className='text-muted'>{getExperienceFromLocal()&&getExperienceFromLocal().whatYouWillDo?getExperienceFromLocal().whatYouWillDo:"what you will do"}</p>
                                        </Button>

                                        {/* <hr className='hr_light' /> */}
                                        {/* <Button variant="link" className="nounder text-start ps-0" onClick={() => setSteps(10)}>
                                        <p className='text_blue_big'>What I'll provide</p>
                                        <p className='text-muted'>{provides} 
                                        <i className={provides == "Meal" ? "ms-2 fa fa-cutlery" : provides == "Drinks" ? "ms-2 fa fa-coffee" 
                                        : provides == "Accomodation" ? "ms-2 fa fa-home" : 
                                        provides == "Tickets" ? "ms-2 fa fa-ticket" : provides == "Transportation" ? "ms-2 fa fa-car" : 
                                        provides == "Equipment" ? "ms-2 fa fa-suitcase" : provides == "Snacks" ?  "ms-2 fa fa-cutlery" : "fa fa-"} aria-hidden="true"></i>
                                        </p>
                                        </Button> */}

                                        <hr className='hr_light' />
                                        <Button variant="link" className="nounder text-start ps-0" onClick={() => setSteps(8)}>
                                            <p className='text_blue_big'>Where we'll be</p>
                                            <p className='text-muted'>{getExperienceFromLocal()&&getExperienceFromLocal().whereYouWillBe?getExperienceFromLocal().whereYouWillBe:"where you will be"}.</p>
                                        </Button>
                                        <hr className='hr_light' />
                                        <Button variant="link" className="nounder text-start ps-0" onClick={() => setSteps(13)}>
                                        <p className='text_blue_big'>Notes</p>
                                        <p className='text-muted'>{notes}</p>
                                        </Button>
                                        <hr className='hr_light' />
                                        <Button variant="link" className="nounder text-start ps-0" onClick={() => setSteps(9)}>

                                        <p className='text_blue_big'>Where we'll meet</p>
                                        <p className='text-muted'>{getExperienceFromLocal()&&getExperienceFromLocal().meetingLocation?`${getExperienceFromLocal().meetingLocation.locationName}-${getExperienceFromLocal().meetingLocation.city}`:"where we will meet"}</p>
                                        </Button>

                                        {/* <hr className='hr_light' />
                                        <Button variant="link" className="nounder text-start ps-0" onClick={() => setSteps(13)}>
                                        <p className='text_blue_big'>About your host</p>
                                        <p className='text-muted'>{bio}</p>
                                        </Button> */}

                                        <hr className='hr_light' />
                                        <Button variant="link" className="nounder text-start ps-0" onClick={() => setSteps(15)}>
                                        <p className='text-muted'>Group size upto {getExperienceFromLocal()&&getExperienceFromLocal().maxGuest?getExperienceFromLocal().maxGuest:"1"} guest</p>
                                        </Button>

                                        <hr className='hr_light' />
                                        <Button variant="link" className="nounder text-start ps-0" onClick={() => setSteps(14)}>
                                        <p className='text_blue_big'>Who can come</p>
                                        <p className='text-muted'>Guests ages {getExperienceFromLocal()&&getExperienceFromLocal().whoCanAttend&&getExperienceFromLocal().whoCanAttend.age?getExperienceFromLocal().whoCanAttend.age:"18"} and up can attend.</p>
                                        </Button>

                                        </div>
                                       
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
    )
}

export default ExperianceBase