import React, {useEffect, useState} from 'react';
import {Row, Col, Tab ,Nav,Button} from 'react-bootstrap'
import {toast, Toaster} from "react-hot-toast";


import { useLocation} from 'react-router-dom'
import { toastOptions } from '../../../config/env';
import { getUserDatas } from '../../../Hooks/useUserHook';
import { hostRoomApproval } from '../../../Hooks/useUserHostingHook';

const YourReservationTab = (props) => {


    const approve = async (data,index,isrejected) => {
        let user = getUserDatas()
        const value = {
            email:props.email,
            isVerified : data,
            bookingId: props.datas[index]._id,
            roomId: props.datas[index].roomId,
            isRejected:isrejected,
            id : user?._id
        }
        console.log("value from your reservation tab",value,"props.datas",props.datas);
        await hostRoomApproval(value).then((data)=>{console.log("data in approve" , data);
        if(data?.type == "error"){
            toast.error(data?.msg)
        }else{
            toast.success(data,toastOptions);
            props.onRefresh();
        }
        })
    }

    return(
        <>
        <div className='no-stat-new'>
                                            {/* <div className='no-stat-cont'>
                                                <span className='fa fa-clock-o'></span>
                                                <p>You don’t have any guests checking out today or tomorrow.</p>
                                            </div> */}


                                            <div className='row withstat'>
                                                {props.datas.map((val,index)=><>
                                                <div className='cols col-xl-4 col-lg-6 mb-3'>

                                                <div className='shadowbox p-4 br-5'> 
                                        <div className='d-flex align-items-center'>
                                            {/* <img src={prof} className="prof" /> */}
                                            <h6 className='mb-3'><b>{val.roomTitle} at {val.accommodationAddress.city}</b></h6>

                                        </div>
                                        <div className='graybox br-0 mt-3 py-3 mobi_fle_ger'>
                                            <div className='d-flex jc-between'>
                                                <h6 className='mb-3 f-14'>
                                                    <p className='themeclr'>Booked by</p>
                                                     <p className='text-muted'>{val.email}</p></h6>
                                            </div>
                                            <div className='d-flex jc-between'>
                                            <h6 className='mb-3 f-14'><p className='themeclr'>Room ID</p>
                                            <p className='text-muted'>{val.roomId}</p></h6>

                                            </div>

                                            <div className='d-flex jc-between'>

                                                <h6 className='mb-3 f-14'><p className='themeclr'>Booking ID</p> <p className='text-muted'>{val._id}</p></h6>
                                            </div>

                                            <div className='d-flex jc-between'>

                                                <h6 className='mb-3 f-14'><p className='themeclr'>Check In</p> <p className='text-muted'>{new Date(val.checkInDate).toLocaleDateString()}</p></h6>
                                            </div>
                                            

                                            <div className='d-flex jc-between'>

<h6 className='mb-0 f-14'><p className='themeclr'>CheckOut</p> <p className='text-muted'>{new Date(val.checkOutDate).toLocaleDateString()}</p></h6>
</div>
                                        </div>
                                        <h6 className='mb-3 f-14 mt-3 d-flex jc-between'>
                                         <span className='text-muted'>{val.nights} Night(s)</span></h6>
                                        <hr className='themeclr' />
                                        <h6 className='mb-0 f-14 mt-3 d-flex jc-between'><span className='themeclr'>Amount:</span> <span className='success-box suc_box_blue'>${val.totalAmount}</span></h6>
                                  
                                         {(props.isPending)? 
                                        <div className='d-flex mt-3'><button onClick={()=>{approve(true,index,false)}} type="button" class="btn btn-theme  me-2">Accept</button><button onClick={()=>{approve(false,index,true)}} type="button" class="btn btn-theme ">Decline</button></div>:<></>
                                        } 
                                  
                                    </div>


                                               
                                            </div>
                                            </>)}
                                                
                                            </div>
                                           
                                        </div>
                                        <Toaster/>
        </>
    )
}

export default YourReservationTab