
import React, { Component, useEffect, useState } from 'react';

import Scrollbars from 'react-custom-scrollbars';
import { Form} from 'react-bootstrap'
import '../../../Assets/css/header.css'


import img1 from '../../../Assets/images/where/img1.png'
import img2 from '../../../Assets/images/filter/bed.png'

const GuestCount = (props) => {
    console.log('propddattttt',props.data)
   const [count, setCount] = useState(0);
   console.log('countvalue',count)
   const [countArray, setCountArray] = useState([]);
   console.log('counTArray',countArray)

    useEffect(()=>{
        let dataArr = [];
        props.data.content.map((val)=>{
            console.log("inside map:",val);
            dataArr.push(val);
        })
        console.log("localStorage.getItem(floorPlan)",localStorage.getItem("floorPlan"),"countarray:",countArray);
        localStorage.getItem("floorPlan")?setCountArray(JSON.parse(localStorage.getItem("floorPlan"))):setCountArray(dataArr)
        // setCountArray(dataArr);
        console.log("countArray :",countArray,"dataArray",dataArr);
    },[countArray]);

    const setData = (val,type,index) => {
        if(type == "add"){
            val.count = parseInt(val.count) + 1;
            setCount(val.count)
            
            console.log("val in add:",val,"val.count:",val.count);
        }else if(type == "sub" & val.count > 0){
            val.count = val.count-1;
            setCount(val.count);
            console.log("val in sub:",val,"val.count:",val.count);
        }
        console.log('indexvaluesgal',index)
       countArray[index] = val;
       props.onGuestCount(countArray);
       console.log("countArray :",countArray);
 
    }
    console.log('(localStorage.getItem("floorPlan")) idiots',localStorage.getItem("floorPlan"))
    useEffect(()=>{
        (localStorage.getItem("floorPlan"))?props.onGuestCount(JSON.parse(localStorage.getItem("floorPlan"))):localStorage.removeItem("floorPlan")
    },[]);

    return(
        <div className='col-lg-12 col-xl-12 col-md-12'>
        <div className='rightcont placetype mb-5 '>
              <Scrollbars style={{ height: "calc(100vh - 320px)"}} className="scroll">
                <div className='row px-3 align-items-center height-100 pb-5' data-aos="fade-up">
                 
              
           <div className=''>
                    <div className='who_main'>
                    <div className='who_sub my-4'>
                        {countArray && countArray.map((val,index)=>
                        val?.status != "Inactive" && <>
                        <div className='row placecards'>
                        <div className='col-12 col-sm-6 guder_tpr_e'>
                            <h6 className='mb-0'>{val.type}</h6>
                        </div>
                        {console.log('val.count',val.count,val.type)}
                        <div className='col-12 col-sm-6'>
                            <div className='d-flex align-items-center justify-content-end'>
                                <div onClick={()=>{setData(val,"sub",index)}} className={(((val.type == "guests" ||val.type == "beds" ||val.type == "bathrooms" || val.type == "Wifi" || val.type == "Parking"  || val.type == "bedrooms" )&&val.count<1)||val.count<0)?'minus_div icon_div disabledbox':'minus_div icon_div'} ><i className="fa fa-minus" aria-hidden="true" disabled={(val.count) < 0} ></i></div>
                                {console.log('datascountArray?.[index]',index , countArray?.[index] ,'val.count',val.count)}
                                <div className='number_div mx-3' value={(countArray?.[index]) ? countArray[index]?.count : val?.count < 0 }> {(countArray?.[index]) ? countArray?.[index]?.count : val?.count < 0}</div>
                                <div onClick={()=>{setData(val,"add",index)}} className='plus_div icon_div'><i className="fa fa-plus" aria-hidden="true" ></i></div>

                            </div>
                        </div>
                    </div>
                    {/* <hr className='hr_who'></hr> */}
                    </>)}
                        

                       
                    </div>

                    </div>
                </div> 
                    
                   
                    </div> </Scrollbars>
                    </div>
                    </div>
    )
}
export default GuestCount