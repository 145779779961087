import React, { useState, useEffect } from "react";
import { Offcanvas, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import logo from "../../Assets/images/landingPage/flutterpadlogo.png";
import facebook from "../../Assets/images/landingPage/facebook.png";
import instagram from "../../Assets/images/landingPage/instagram.png";
import twitter from "../../Assets/images/landingPage/twitter.png";
import { GrInstagram } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import { getUserDatas, getUserEmail } from "../../Hooks/useUserHook";
import { toast, Toaster } from 'react-hot-toast';
const Footer = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [email, setEmail] = useState("");
  const [showmap, setShowmap] = useState(false);

  const handleClosemap = () => setShowmap(false);
  const handleShowmap = () => setShowmap(true);
  const [showlog, setShowLog] = useState(false);
  const [userDatas, setUserDatas] = useState({});

  useEffect (() => {
    const data = getUserEmail();
    setEmail(data)
    setUserDatas(getUserDatas())
    if (data) {
      setShowLog(true)
    }
  }, [showlog]);

  const checkUserLogin = async (type) => {
    try{
      console.log("checkuserlogin", type)
      if (email) {
        if(userDatas?.govtproof?.length > 0 && userDatas?.govtproof[0]?.verification == "accepted"){
          if(parseFloat(userDatas?.hostsubscribedaycount) > 0){
            if (type == "room") {
              window.location.href = window.location.origin + `/host/${email}`
            } else if (type == "experience") {
              window.location.href = window.location.origin + `/host-experience/${email}`
            }
          }
          else{
            toast.error("Need Host Subscription!..")
          }
      } 
      else{
        toast.error("Upload your government id!")
      }
    }else {
        setShowLog(true);
      }
    } catch (e) {
      console.log("checkUserLogin_err", e)
    }
  }

  return (
    // <div className="footer">
    //   {/* <div className='container container_custom'>
    //      <div className='row align-items-center'>
    //         <div className='col-md-3'>
    //             <ul>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Help center</a></li>
    //                 <li><a href="#">Aircover</a></li>
    //                 <li><a href="#">Safty Information</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>

    //             </ul>
    //         </div>
    //         <div className='col-md-3'>
    //         <ul>
    //                 <li><a href="#">Community</a></li>
    //                 <li><a href="#">Help center</a></li>
    //                 <li><a href="#">Aircover</a></li>
    //                 <li><a href="#">Safty Information</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>

    //             </ul>
    //         </div>
    //         <div className='col-md-3'>
    //         <ul>
    //                 <li><a href="#">Hosting</a></li>
    //                 <li><a href="#">Help center</a></li>
    //                 <li><a href="#">Aircover</a></li>
    //                 <li><a href="#">Safty Information</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>

    //             </ul>
    //         </div>
    //         <div className='col-md-3'>
    //         <ul>
    //                 <li><a href="#">About</a></li>
    //                 <li><a href="#">Help center</a></li>
    //                 <li><a href="#">Aircover</a></li>
    //                 <li><a href="#">Safty Information</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>
    //                 <li><a href="#">Support</a></li>

    //             </ul>
    //         </div>

    //      </div>
    //   </div> */}
    //   {/* <div className='showmap'>
    //      <button className='showmap' onClick={handleShowmap}><span className="fa fa-map"></span>Show map</button>
    //   </div> */}

    //   <Modal
    //     show={showmap}
    //     onHide={handleClosemap}
    //     centered
    //     scrollable
    //     className="modal_style"
    //   >
    //     <Modal.Header className="border-0 pb-0">
    //       <button
    //         type="button"
    //         className="btn close close_modal"
    //         onClick={() => handleClosemap()}
    //       >
    //         <span aria-hidden="true">×</span>
    //         <span class="sr-only">Close</span>
    //       </button>
    //     </Modal.Header>
    //     <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
    //     <Modal.Footer>
    //       <Button variant="secondary" onClick={handleClosemap}>
    //         Close
    //       </Button>
    //       <Button variant="primary" onClick={handleClosemap}>
    //         Save Changes
    //       </Button>
    //     </Modal.Footer>
    //   </Modal>
    //   <div className="fixedfooter">
    //     <div className="container container_custom copy">
    //       <div className="row align-items-center justify-content-between">
    //         <div className="col-md-6 my-md-3 my-2">
    //           <ul className="mb-0">
    //             <li>&copy; 2022 Lorem, Inc</li>
    //             <li>
    //               <span>.</span>
    //               <a href="#"> Privacy</a>
    //             </li>
    //             <li>
    //               <span>.</span>
    //               <a href="#">Terms</a>
    //             </li>
    //             <li>
    //               <span>.</span>
    //               <a href="#"> Sitemap</a>
    //             </li>
    //             <li>
    //               <span>.</span>
    //               <a href="#">Company Details</a>
    //             </li>
    //           </ul>
    //         </div>
    //         <div className="col-md-6 text-md-end my-md-3 my-2">
    //           <ul className="mb-0">
    //             <li>
    //               <span className="fa fa-globe me-2" />English(IN)
    //             </li>
    //             <li>INR</li>
    //             <li onClick={handleShow}>
    //               Support & Resource <i className="fa fa-chevron-up ms-1" />
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <Offcanvas
    //     show={show}
    //     onHide={handleClose}
    //     placement="bottom"
    //     className="footer"
    //   >
    //     <Offcanvas.Header closeButton className="pb-0" />
    //     <Offcanvas.Body>
    //       <div className="container container_custom">
    //         <div className="row">
    //           <div className="col-md-3 col-sm-6">
    //             <ul>
    //               <li>Support</li>
    //               <li>
    //                 <a href="#">Help center</a>
    //               </li>
    //               <li>
    //                 <a href="#">FlutterPads</a>
    //               </li>
    //               <li>
    //                 <a href="#">Safty Information</a>
    //               </li>
    //               <li>
    //                 <a href="#">Supporting people with disabilities</a>
    //               </li>
    //               <li>
    //                 <a href="#">Cancellation options</a>
    //               </li>
    //               <li>
    //                 <a href="#">Our COVID-19 Response</a>
    //               </li>
    //               <li>
    //                 <a href="#">Report a neighbourhood concern</a>
    //               </li>
    //             </ul>
    //           </div>
    //           <div className="col-md-3 col-sm-6">
    //             <ul>
    //               <li>Community</li>
    //               <li>
    //                 <a href="#">spareresort.org: disaster relief housing</a>
    //               </li>
    //               <li>
    //                 <a href="#">Support Afghan refugees</a>
    //               </li>
    //               <li>
    //                 <a href="#">Combating discrimination</a>
    //               </li>
    //             </ul>
    //           </div>
    //           <div className="col-md-3 col-sm-6">
    //             <ul>
    //               <li>Hosting</li>
    //               <li>
    //                 <a href="#">Try hosting</a>
    //               </li>
    //               <li>
    //                 <a href="#">AirCover for Hosts</a>
    //               </li>
    //               <li>
    //                 <a href="#">Explore hosting resources</a>
    //               </li>
    //               <li>
    //                 <a href="#">Visit our community forum</a>
    //               </li>
    //               <li>
    //                 <a href="#">How to host responsibly</a>
    //               </li>
    //             </ul>
    //           </div>
    //           <div className="col-md-3 col-sm-6">
    //             <ul>
    //               <li>About</li>
    //               <li>
    //                 <a href="#">Newsroom</a>
    //               </li>
    //               <li>
    //                 <a href="#">Learn about new features</a>
    //               </li>
    //               <li>
    //                 <a href="#">Letter from our founders</a>
    //               </li>
    //               <li>
    //                 <a href="#">Careers</a>
    //               </li>
    //               <li>
    //                 <a href="#">Investors</a>
    //               </li>
    //               <ul className="ul-inline-block">
    //                 <li>
    //                   <a href="#">
    //                     <span className="fa fa-facebook" />
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="#">
    //                     <span className="fa fa-twitter" />
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="#">
    //                     <span className="fa fa-instagram" />
    //                   </a>
    //                 </li>
    //               </ul>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </Offcanvas.Body>
    //   </Offcanvas>
    // </div>
    <>
      <div className="footer-section">
        <div className="container footer-custom-container footer-head">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
              <div className="d-flex block_mob_sel_new">
                <div className="logo-section">
                  <img src={logo} alt="logo" />
                </div>
                <div className="d-flex flex-column block_mob_sel_new_padin ps-3 ps-xl-5 ps-lg-5 ps-md-5 ps-sm-5 pt-3">
                  <div className="content-des">
                    Pool area has it's own bathroom. TVs in each room. Sound
                    system and fireplace in main living room. Secure 2-car
                    garage can squeeze.
                  </div>
                  <div className="d-flex pt-5 align-items-center">
                    <span className="social-net pe-4">Social</span>
                    {/* <img
                      src={instagram}
                      alt="instagram"
                      className="insta-img pe-2"
                    /> */}
                    {/*<img
                      src={facebook}
                      alt="facebook"
                      className="fb-img pe-2"
                    />
                    <img src={twitter} alt="twitter" className="twitter-img" /> */}
                    <span className="social-icon me-2">
                      <GrInstagram size={18} />
                    </span>
                    <span className="social-icon me-2">
                      <FaFacebookF size={18} />
                    </span>

                    <span className="social-icon">
                      <FiTwitter size={18} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
              <div className="pt-3">
                <p className="header-text pb-4">Flutterpads</p>


                <Link to="/" className="sub-content" onClick={() => checkUserLogin("room")}>
                  Host a Room
                </Link>
                <Link to="/" className="sub-content" onClick={() => checkUserLogin("experience")}>
                  Host an Experience
                </Link>
              </div>
            </div>
            {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
              <div className="pt-3">
                <p className="header-text pb-4">Support</p>
                <Link to="/faq" className="sub-content">
                  FAQ
                </Link>
               
                <Link to="/about-us" className="sub-content">
                  About
                </Link>
                <Link to="/contact-us" className="sub-content">
                  Contact Us
                </Link>
              </div>
            </div> */}
          </div>
        </div>
        <hr className="text-white" />
        <div className="bottom-footer py-4 container justify-content-between footer-custom-container">
          <div className="row">
            <dic className="col-xl-6 col-lg-6 col-md-7 col-sm-12 col-12">
              <div className="copyright-content">
                @2023 FlutterPads holding inc. All rights reserved
              </div>
            </dic>
            <div className="col-xl-6 col-lg-6 col-md-5 col-sm-12 col-12">
              <div className="terms-condition-section">
                <div className="d-flex">
                  <Link
                    to="/terms-of-service"
                    className="terms-privacy pe-2 pe-sm-5 pe-md-3 pe-lg-5"
                  >
                    Terms of Services
                  </Link>
                  <Link to="/privacy-policy" className="terms-privacy">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
