import React, { Component, useEffect, useState } from 'react';
import Toast,{toast, Toaster} from "react-hot-toast"
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { confirmRoom, createRazorPayment, getUserDatas, getusersdatahooks, reserveRoom, verifyRazorPayment } from '../../../Hooks/useUserHook';

import { toastOptions } from '../../../config/env';
import Confirmroombookingpay from './Roombookingpay';

const PaymentIntegrationModal = (props) =>{
    const[show , setShow] = useState(true)
    const [roomDetails,setRoomDetails] = useState({});
    const [bookingDetails,setBookingDetails] = useState({});
    const [paymentUrl, setPaymentUrl] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const [secret , setSecret] = useState("");
    const [pay , setPay] = useState(false);

    const [squaredata , setSquaredata] = useState({});
    const [userdata , setUserdata] = useState({});
    const [square , setSquare] = useState(true);
    

    const navigate = useNavigate();

    useEffect(()=>{
        setBookingDetails(props.bookingDetails);
        setRoomDetails(props.roomDetails);
        setUserdata(getUserDatas())
    },[isOpen]);

    const initPayment = (data) => {
        console.log("initpayment data:",data);
		const options = {
			key: "rzp_test_U5z5AHc3kI4WtX",
			amount: parseInt(data.data.amount),
			currency: data.data.currency,
			name: "FlutterPads",
			description: "Test Transaction",
			image: "https://images-na.ssl-images-amazon.com/images/I/817tHNcyAgL.jpg",
			order_id: data.data.id,
			handler: async (response) => {
				try {
                    let dataToVerify = response;
                    dataToVerify.bookingId = props.bookingDetails._id;
					const verifiedData = await verifyRazorPayment(dataToVerify);
					console.log("verifiedData",verifiedData);
                    if(verifiedData.status){
                        navigate(`/payment-success/${props.bookingDetails._id}`);
                    }
				} catch (error) {
					console.log(error);
				}
			},
			theme: {
				color: "#3399cc",
			},
		};
		const rzp1 = new window.Razorpay(options);
		rzp1.open();
	};

	const handlePayment = async () => {
		try {
			const data = {
                bookingId:props.bookingDetails._id,
                roomId:props.bookingDetails.roomId,
                amount: props.bookingDetails.totalAmount
             }
             console.log("payload datga" , data);
            const createData = await createRazorPayment(data);
			console.log("createData",createData);
			initPayment(createData);
		} catch (error) {
			console.log(error);
		}
	};

    const bookRoom = async (type) => {
        // bookingDetails.orderId = orderid;
        // bookingDetails.orderType = type;
        
        console.log('pdfasjdflajsdfkajsldkfasjkldf',props?.bookingDetails)
        const datas = {
            email:props.bookingDetails.email,
            bookingId:props.bookingDetails._id,
            // orderId:orderid,
            orderType:type,
            roomId:props.bookingDetails.roomId,
            totalAmount:props.bookingDetails.totalAmount
        }
        console.log("inside reserveroom bookingdetails :",bookingDetails);
        confirmRoom(datas).then((data)=>{
            if(type == "Stripe"){
                console.log("confirmroom res", data?.data?.paymentid);
                setSecret(data?.data?.paymentid?.client_secret);
                setPay(true);
            // setPaymentUrl(data.data);
            // setIsOpen(true);
            // toast.success("Room Booked successfully!",toastOptions);
            // window.location.href = window.location.origin+"/payment-success/"+bookingDetails._id;
            }
            if(type == "Square"){
                console.log("squarte payement integration" , data?.data?.paymentid?.url);
                setSquaredata(data?.data?.paymentid)
                window.open(data?.data?.paymentid?.url)
                getusersdatahooks(userdata?.email).then(() => {
                    // toast.success("Paid Successfully!");
                    // props.onDismiss();
                    // window.location.reload();
                    // setPay(true);
                 });
            }
        })
    }

    return(
        <Modal show={show} size="md"  centered scrollable className='modal_style'>
          <Modal.Header className='border-0 pb-0'>
      
      <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      
      </Modal.Header>
      <h5 className='modalhead text-center mb-4'>Select Payment Gateway</h5>
            <Modal.Body>
               {!pay && <div>
                {/* <div>
                <PayPalScriptProvider>
                    <PayPalButtons 
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: props.bookingDetails.totalAmount,
                                    },
                                    payee: {
                                        email_address: "sb-sun8j15395062@business.example.com"
                                    }
                                },
                            ],
                        });
                    }}
                    onApprove={(data, actions) => {
                        console.log("onApprove data:",data,"actions:",actions);
                        return actions.order.capture().then((details) => {
                            console.log("onApprove then actions.order.capture() details:",details);
                            const name = details.payer.name.given_name;
                            bookRoom(details.id,"paypal");
                            alert(`Transaction completed by ${name}`);
                        });
                    }}
                    onError = {(err) => {
                        alert('error in fund transfer');
                    }}
                    style={{ layout: "horizontal" }} />
                </PayPalScriptProvider>
                </div> */}

                {/* <Modal isOpen={isOpen} onDismiss={()=>{setIsOpen(false)}}>
                    <iframe src={paymentUrl} />
                </Modal> */}

                <button onClick={()=>{bookRoom("Stripe")}} className="w-full btn btn-theme mb-2 btn_them_grey">Stripe</button>
                <button onClick={()=>{bookRoom("Square")}} className="w-full btn btn-theme mb-4 btn_them_grey">Square</button>
               </div>}

               {secret && <div>
                <Elements
                            stripe={props.stripe}
                            options={{
                                clientSecret: secret,
                                appearance: {
                                    theme: "flat",
                                    variables: { colorPrimaryText: "#262626" },
                                },
                            }}
                        >
                           <Confirmroombookingpay />
                        </Elements>
                </div>}
            </Modal.Body>
            <Toaster/>
      </Modal>
    )
}

export default PaymentIntegrationModal