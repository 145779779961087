import React from "react";
import HostHeader from "../BecomeHost/AfterHost/HostHeader";
import Footer from "./Footer";

// Images imports

import contactBanner from "../../Assets/images/contact/R.png";
import { useState, useEffect } from "react";
import { getallcmsdatahook } from "../../Hooks/useUserHook";
import { API_URL } from "../../config/env";

const Contactus = () => {

  const [cms, setCms] = useState([]);
  useEffect(() => {
    async function fetchdata() {
      console.log("")
      await getallcmsdatahook().then((result) => {
        console.log("get all crm hook result", result?.data?.data);
        result?.data?.data?.map((data, i) => data.sno = i + 1);
        setCms(result?.data?.data);
      })
    }
    fetchdata();
  }, [])
  const cmsFilter = (data) => {
    try {

      let filterdata = cms?.find(el => (el.identifier == data && el?.status == "active"));
      console.log("filterdata_filterdata", filterdata, filterdata?.updatedAt);
      return filterdata;
    } catch (e) {
      console.log("cmsFilter_err", e)
    }
  }


  return (
    cms && cms != 0 ? 
    <>
      <HostHeader />

      {/* Contact-us */}

      <section className="pad_y">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              {cmsFilter("contactbanner_01")?.image && <div>
                {/* <img
                  src={contactBanner}
                  alt="contact banner"
                  className="img-fluid"
                /> */}

                <img src={cmsFilter("contactbanner_01")?.path ? API_URL + cmsFilter("contactbanner_01")?.path.split('/')[1] + "/" + cmsFilter("contactbanner_01")?.path?.split('/')[2] + "/" + cmsFilter("contactbanner_01")?.image : <></>} alt="contact banner" className="img-fluid" />
              </div>}
            </div>
            <div className="col-12 col-md-6 mt-4 mt-md-0 px-md-4 px-lg-5 mt-md-0">
              <div className="d-flex flex-column align-item-start">
                <h2>{cmsFilter("contactbanner_01")?.name != undefined ? cmsFilter("contactbanner_01")?.name : ""}</h2>
                <p className="mt-3 cont_subtle" dangerouslySetInnerHTML={{ __html: cmsFilter("contactbanner_01")?.content != undefined ? cmsFilter("contactbanner_01")?.content : "" }}>
                </p>
                <p className="mt-4 d-flex align-items-center">
                 {cmsFilter("contactbanner_01")?.phone != undefined ? <i class="fa-solid fa-phone ph_icon_clr" /> : <></>}{" "}
                  <a href="/" className="ms-3 ph_txt">
                    {cmsFilter("contactbanner_01")?.phone != undefined ? cmsFilter("contactbanner_01")?.phone : ""}
                  </a>
                </p>
                <div className="mt-3 d-flex align-items-start">
                  {cmsFilter("contactbanner_01")?.address != undefined ? <i class="fa-solid fa-location-dot ph_icon_clr" /> : <></>}
                  <p className="ms-3 strt_txt">
                    {cmsFilter("contactbanner_01")?.address != undefined ? cmsFilter("contactbanner_01")?.address : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </> : <></>
  );
};

export default Contactus;
